/** @format */

import Tabulation from "../../../components/Tabulation";
import { ColumnsType } from "antd/es/table";
import { Col } from "antd";
import EllipsisMiddle from "../../../components/EllipsisMiddle";
import "../../../components/componets.less";
import { useTranslation } from "react-i18next";
import { useNavigate, Link } from "react-router-dom";
import TitleCard from "../../../components/TitleCard";
import React, { useState, useEffect } from "react";
import { DatumType } from "../../../model";
import { getRecent } from "../../../servers/api";
import { TimeClock } from "../../../components/TimeClock";
import { transFil } from "../../../utils/convertUtils";
import "./blocklist.less";
import BlockList_mobile from "./mobile/BlockList_mobile";
import DateUtil from "../../../utils/formatData";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import { TextLengthElls } from "../../../utils/adaptiveTextLength";
const Config = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	return [
		{
			title: t("block_list.height"),
			dataIndex: "height",
			key: "height",
			width: 128,
			align: "center",
			render: (height: number) => (
				<div className="block-height">
					<Link to={`/blocks/${height}`}>
						{height}
					</Link>
				</div>
			),
		},
		{
			title: t("block_list.time"),
			dataIndex: "timestamp",
			width: 208,
			align: "center",
			key: "timestamp",
			render: (timestamp) => {
				// return <TimeClock timeStamp={Number(timestamp)} />;
				return <div className="block-time">
					{DateUtil.formatDate(timestamp, "yyyy/MM/dd HH:mm:ss")}
				</div>;
			},
		},
		{
			title: "ID",
			dataIndex: "Blocks",
			key: "id",
			align: "center",
			width: 256,
			render: (Blocks) =>
				Blocks ?
					Blocks.map((item: any, index: number) => {
						return (
							<div key={index} className='block-hash'>
								<Link to={`/block/${item.cid}`}>
									{/* {TextLengthElls(item.cid, 9)} */}
									<TextLengthElls content={item.cid} />
									{/* {item.cid} */}
								</Link>
							</div>
						);
					}) : null,
		},
		{
			title: t("block_list.storage_Provider"),
			key: "miner",
			width: "auto",
			dataIndex: "Blocks",
			align: "center",
			render: (Blocks) =>
				Blocks ?
					Blocks.map((item: any, index: number) => {
						return (
							<div key={index}>
								<Link to={`/address/${item.miner}`} state={{type: "storageminer"}}>{item.miner}</Link>
							</div>
						);
					}) : null,
		},
		// TODO 标签暂时无法获取
		// {
		// 	title: t("block_list.tags"),
		// 	key: "tag",
		// 	align: "center",
		// 	width: "auto",
		// 	dataIndex: "Blocks",
		// 	render: (tag) =>
		// 		tag ?
		// 			tag.map((item: any, index: number) => {
		// 				return <div key={index}>{item.tag ? item.tag : "--"}</div>;
		// 			}) : null,
		// },
		{
			title: t("block_list.message"),
			key: "message",
			align: "center",
			width: "auto",
			dataIndex: "Blocks",
			render: (Blocks) =>
				Blocks ?
					Blocks.map((item: any, index: number) => {
						return <div key={index}>{item.messageCount}</div>;
					}) : null,
		},
		// TODO  奖励暂时无法获取
		// {
		// 	title: t("block_list.rewards"),
		// 	align: "center",
		// 	key: "reward",
		// 	width: 128,
		// 	dataIndex: "Blocks",
		// 	render: (reward) =>
		// 		reward ?
		// 			reward.map((item: any, index: number) => {
		// 				return (
		// 					<div className="table-amount" key={index} >{transFil(item.reward, 2)}</div>
		// 				);
		// 			}) : null,
		// },
	] as ColumnsType;
};

const BlockList = () => {
	const { t } = useTranslation();
	const { sm } = useBreakpoint()
	const [table, setTable] = useState({
		count: 0,
		page: 1,
		pageSize: 50,
		data: [] as DatumType[],
	});
	const [loading, setLoading] = useState(false);

	function getBlockMessage(pageSize: number, page: number) {
		setLoading(true);
		getRecent({ pageSize, page: page - 1 }).then((r) => {
			const d = [] as DatumType[];
			(r.data as DatumType[]).forEach((item) => {
				if (item.Blocks) {
					d.push(item)
				}
			})
			setTable({
				page: page,
				pageSize: pageSize,
				data: d,
				count: r.count,
			});
			setLoading(false);
		}).catch((err) => setLoading(false))
	}
	useEffect(() => {
		getBlockMessage(table.pageSize, table.page);
	}, []);
	return (
		<>
			{/* <div className="page-title">{t('navbar.block')}</div> */}
			{document.body.clientWidth > 768 ?
				<div className="blockList">
					<TitleCard title={t("block_list.block_list")}>
						<Tabulation
							dataConf={table}
							onChange={(pageSize, page) => {
								if (page && pageSize) {
									getBlockMessage(pageSize, page);
								}
							}}
							loading={loading}
							rowKey={function (record, index): string {
								return record.height
							}}
							tableDataTypeConfig={Config}
						/>
					</TitleCard>
				</div>
				: <BlockList_mobile />}
		</>
	);
};

export default BlockList;
