export const Three=()=>{
    return(
        <p>
            <h1>三、开发篇</h1>
            <h2>账户类型</h2>
            <h3>商业背景</h3>
            <p>普通账户分为 secp256k1 和 bls 两种类型，前者地址以 t1 开头，后者以 t3 开头；两者都有一个对应的 t0 开头的 ID 地址。其余账户类型除系统账户外，默认地址都是以 t0 开头的 ID 地址，并且都有对应的 t2 开头的地址。</p>
            <p>普通账户分为 secp256k1 和 bls 两种类型，前者地址以 t1 开头，后者以 t3 开头；两者都有一个对应的 t0 开头的 ID 地址。其余账户类型除系统账户外，默认地址都是以 t0 开头的 ID 地址，并且都有对应的 t2 开头的地址。</p>
            <p>普通账户分为 secp256k1 和 bls 两种类型，前者地址以 t1 开头，后者以 t3 开头；两者都有一个对应的 t0 开头的 ID 地址。其余账户类型除系统账户外，默认地址都是以 t0 开头的 ID 地址，并且都有对应的 t2 开头的地址。</p>
            <p>普通账户分为 secp256k1 和 bls 两种类型，前者地址以 t1 开头，后者以 t3 开头；两者都有一个对应的 t0 开头的 ID 地址。其余账户类型除系统账户外，默认地址都是以 t0 开头的 ID 地址，并且都有对应的 t2 开头的地址。</p>
            <p>普通账户分为 secp256k1 和 bls 两种类型，前者地址以 t1 开头，后者以 t3 开头；两者都有一个对应的 t0 开头的 ID 地址。其余账户类型除系统账户外，默认地址都是以 t0 开头的 ID 地址，并且都有对应的 t2 开头的地址。</p>
            <p>普通账户分为 secp256k1 和 bls 两种类型，前者地址以 t1 开头，后者以 t3 开头；两者都有一个对应的 t0 开头的 ID 地址。其余账户类型除系统账户外，默认地址都是以 t0 开头的 ID 地址，并且都有对应的 t2 开头的地址。</p>
            <p>普通账户分为 secp256k1 和 bls 两种类型，前者地址以 t1 开头，后者以 t3 开头；两者都有一个对应的 t0 开头的 ID 地址。其余账户类型除系统账户外，默认地址都是以 t0 开头的 ID 地址，并且都有对应的 t2 开头的地址。</p>
            <p>普通账户分为 secp256k1 和 bls 两种类型，前者地址以 t1 开头，后者以 t3 开头；两者都有一个对应的 t0 开头的 ID 地址。其余账户类型除系统账户外，默认地址都是以 t0 开头的 ID 地址，并且都有对应的 t2 开头的地址。</p>
            <p>普通账户分为 secp256k1 和 bls 两种类型，前者地址以 t1 开头，后者以 t3 开头；两者都有一个对应的 t0 开头的 ID 地址。其余账户类型除系统账户外，默认地址都是以 t0 开头的 ID 地址，并且都有对应的 t2 开头的地址。</p>
            <p>普通账户分为 secp256k1 和 bls 两种类型，前者地址以 t1 开头，后者以 t3 开头；两者都有一个对应的 t0 开头的 ID 地址。其余账户类型除系统账户外，默认地址都是以 t0 开头的 ID 地址，并且都有对应的 t2 开头的地址。</p>
            <p>普通账户分为 secp256k1 和 bls 两种类型，前者地址以 t1 开头，后者以 t3 开头；两者都有一个对应的 t0 开头的 ID 地址。其余账户类型除系统账户外，默认地址都是以 t0 开头的 ID 地址，并且都有对应的 t2 开头的地址。</p>
            <p>普通账户分为 secp256k1 和 bls 两种类型，前者地址以 t1 开头，后者以 t3 开头；两者都有一个对应的 t0 开头的 ID 地址。其余账户类型除系统账户外，默认地址都是以 t0 开头的 ID 地址，并且都有对应的 t2 开头的地址。</p>
            <p>普通账户分为 secp256k1 和 bls 两种类型，前者地址以 t1 开头，后者以 t3 开头；两者都有一个对应的 t0 开头的 ID 地址。其余账户类型除系统账户外，默认地址都是以 t0 开头的 ID 地址，并且都有对应的 t2 开头的地址。</p>
            <p>普通账户分为 secp256k1 和 bls 两种类型，前者地址以 t1 开头，后者以 t3 开头；两者都有一个对应的 t0 开头的 ID 地址。其余账户类型除系统账户外，默认地址都是以 t0 开头的 ID 地址，并且都有对应的 t2 开头的地址。</p>
            <p>普通账户分为 secp256k1 和 bls 两种类型，前者地址以 t1 开头，后者以 t3 开头；两者都有一个对应的 t0 开头的 ID 地址。其余账户类型除系统账户外，默认地址都是以 t0 开头的 ID 地址，并且都有对应的 t2 开头的地址。</p>
            <p>普通账户分为 secp256k1 和 bls 两种类型，前者地址以 t1 开头，后者以 t3 开头；两者都有一个对应的 t0 开头的 ID 地址。其余账户类型除系统账户外，默认地址都是以 t0 开头的 ID 地址，并且都有对应的 t2 开头的地址。</p>
            <p>普通账户分为 secp256k1 和 bls 两种类型，前者地址以 t1 开头，后者以 t3 开头；两者都有一个对应的 t0 开头的 ID 地址。其余账户类型除系统账户外，默认地址都是以 t0 开头的 ID 地址，并且都有对应的 t2 开头的地址。</p>
            <p>普通账户分为 secp256k1 和 bls 两种类型，前者地址以 t1 开头，后者以 t3 开头；两者都有一个对应的 t0 开头的 ID 地址。其余账户类型除系统账户外，默认地址都是以 t0 开头的 ID 地址，并且都有对应的 t2 开头的地址。</p>
            <p>普通账户分为 secp256k1 和 bls 两种类型，前者地址以 t1 开头，后者以 t3 开头；两者都有一个对应的 t0 开头的 ID 地址。其余账户类型除系统账户外，默认地址都是以 t0 开头的 ID 地址，并且都有对应的 t2 开头的地址。</p>
            <p>普通账户分为 secp256k1 和 bls 两种类型，前者地址以 t1 开头，后者以 t3 开头；两者都有一个对应的 t0 开头的 ID 地址。其余账户类型除系统账户外，默认地址都是以 t0 开头的 ID 地址，并且都有对应的 t2 开头的地址。</p>
            <p>普通账户分为 secp256k1 和 bls 两种类型，前者地址以 t1 开头，后者以 t3 开头；两者都有一个对应的 t0 开头的 ID 地址。其余账户类型除系统账户外，默认地址都是以 t0 开头的 ID 地址，并且都有对应的 t2 开头的地址。</p>
            <p>普通账户分为 secp256k1 和 bls 两种类型，前者地址以 t1 开头，后者以 t3 开头；两者都有一个对应的 t0 开头的 ID 地址。其余账户类型除系统账户外，默认地址都是以 t0 开头的 ID 地址，并且都有对应的 t2 开头的地址。</p>
            <p>普通账户分为 secp256k1 和 bls 两种类型，前者地址以 t1 开头，后者以 t3 开头；两者都有一个对应的 t0 开头的 ID 地址。其余账户类型除系统账户外，默认地址都是以 t0 开头的 ID 地址，并且都有对应的 t2 开头的地址。</p>
            <p>普通账户分为 secp256k1 和 bls 两种类型，前者地址以 t1 开头，后者以 t3 开头；两者都有一个对应的 t0 开头的 ID 地址。其余账户类型除系统账户外，默认地址都是以 t0 开头的 ID 地址，并且都有对应的 t2 开头的地址。</p>
        </p>
    )
}