import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import TitleCard from "../../../components/TitleCard"
import ZoomChart from "../../../components/ZoomChart"
import { IncomeData } from "../../../model/address"
import { getIncomeData } from "../../../servers/api"
import DateUtil from "../../../utils/formatData"

const IncomeChart = () => {
    const [data, setData] = useState({
        data: [] as string[][],
        startAxis: 0,
    })
    const [loading, setLoading] = useState(false)
    const {t} = useTranslation()
    const getDailyIncomeData = () => {
        setLoading(true)
        try {
            getIncomeData().then((resp) => {
                if (resp.ok) {
                    const data = resp.data as IncomeData[]
                    const incomeData: string[][] = []
                    data && data.forEach((item, index) => {
                        incomeData.push([
                            DateUtil.formatDate(item.time, "yyyy-MM-dd"),
                            item.income,
                        ])
                    })

                    setData({
                        data: incomeData,
                        startAxis: incomeData.length > 180? incomeData.length - 180 : 0
                    })
                    setLoading(false)
                }
            })
        } catch (error) {
            setLoading(false)
        }
    }
    useEffect(() => {
        getDailyIncomeData()
    }, [])
    return (
        <TitleCard title={t("home_target.revenue_record")} style={{marginBottom:"16px"}}>
            <ZoomChart
                data={data.data}
                // atteData={echarts.atteData}
                zoomStart={data.startAxis}
                lineType={"line"}
                seriesName={t("home_target.revenue_record")}
                loading={loading}
                tooltipFormatData={(params: any) => {
                    let startHtml = params[0].axisValue + '<br/>'
                    let listArr = []
                    for (let i = 0; i < params.length; i++) {
                        const item = params[i]
                        let str = item.marker
                        str += (t("home_target.income") + ': ' + item.value[1]+" FIL/TiB")
                        listArr.push(str)
                    }
                    return startHtml + listArr.join('<br/>')
                }}


                yAxisAxisLabel={{
                    formatter: function (value: number) {
                        return value + " FIL/TiB";
                    }
                }} />
        </TitleCard>
    )
}

export default IncomeChart