/**
 * wancheng
 * 09-05
 * 存储者概览
 * @format
 */
import { Button, Card, Col, Popover, Row, Spin, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import ReactEcharts from "echarts-for-react";
import "./address-details.less";
import { InfoCircleOutlined, QuestionCircleTwoTone } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getAddressMessage, getMinerSectors } from "../../../servers/api";
import { changeSizeUnit, transFil, transformDecimal } from "../../../utils/convertUtils";
import { Data, MinerSectors } from "../../../servers/respType";
import List from "./List/List";
import Overview from "./Overview/Overview";
import { Notify } from "../../../components/Notify";
import MinerSectorsChart from "./component/MinerSectorsChart";
import TitleCard from "../../../components/TitleCard";


interface Props {
  accountType: string
}

export default function AddressDetails(props: Props) {
  const { accountType } = props
  const { t } = useTranslation();
  const [data, setData] = useState({} as Data);
  const [loadding, setLoadding] = useState(true);
  const [sectorData, setSectorData] = useState({ loading: false, data: [] as MinerSectors[] });
  const [refresh, setRefresh] = useState(false);
  const { minerID } = useParams();
  const navigate = useNavigate()


  useEffect(() => {

    minerID && accountType && getApiFunction(minerID, accountType)
    getMinerPowerFreed()

  }, [minerID, refresh]);

  function getMinerPowerFreed() {
    setSectorData({
      ...sectorData,
      loading: true,
    })
    getMinerSectors(minerID).then((resp) => {
      if (resp.ok) {
        setSectorData({
          loading: false,
          data: resp.data,
        });
      } else {
        setSectorData({
          ...sectorData,
          loading: false,
        })
      }
    })
  }
  function getApiFunction(minerIDL: string, accountType: string) {
    getAddressMessage(minerID, accountType).then((resp) => {
      setLoadding(true);
      console.log(resp.ok);
      
      if (resp.ok) {
        setData(resp.data as Data);
      } else {
        setLoadding(false)
      }
      setLoadding(false);
    }).catch((e) => {
      setLoadding(false);
    })
  };

  const barOption = {
    // echarts 数据
    tooltip: {
      trigger: "item",
    },

    series: [
      {
        type: "pie",
        radius: ["30%", "70%"],
        avoidLabelOverlap: false,
        label: {
          show: false,
          position: "center",
        },
        tooltip: {
          trigger: "item",
          formatter: "{b}&nbsp;&nbsp;&nbsp;({d}%)",
        },
        legend: {
          bottom: 10,
          left: "center",
          data: ["CityA", "CityB", "CityC"],
        },
        labelLine: {
          show: false,
        },

        // 扇区抵押 提供存储服务锁仓 可用余额

        data: [
          {
            value: proportion(data.minerbalance?.initialpledge, data.minerbalance?.balance),
            name: `${t("address_details.initial_pledge")}: ${transFil(Number(data.minerbalance?.initialpledge), 4)}  `,
          },
          {
            value: proportion(data.minerbalance?.lockedrewards, data.minerbalance?.balance),
            name: `${t("address_details.locked_rewards")}:  ${transFil(Number(data.minerbalance?.lockedrewards), 4)}`,
          },
          {
            value: proportion(data.minerbalance?.availablebalance, data.minerbalance?.balance),
            name: `${t("address_details.available_balance")}: ${transFil(data.minerbalance?.availablebalance), 4} `,
          },
        ],

        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
        },
      },
    ],
  };

  const content = (
    //可用余额上的划入提示
    <div style={{ width: "200px" }}>
      <p>{t("address_details.available_balance")} = {t("address_details.address_balance")} -{t("address_details.initial_pledge")} - {t("address_details.locked_rewards")}</p>
    </div>
  );

  const addUp = (
    //累计出块份数提示
    <div style={{ width: "200px" }}>
      <p>{t("address_details.in_filecoin_mining_model")}</p>
    </div>
  );

  /* b单位转换成为pib单位
   *  有效算力 / 全网算力 = 占比
   */

  function proportion(own: any, total: any, num: number = 2) {
    let ow = Number(own);
    let to = Number(total);
    let result = (ow / to) * 100;
    return result.toFixed(num);
  }


  return loadding ? (
    <div className="example">
      <Spin size="large" />
    </div>
  ) : (
    <>
      <div id="miner" style={{ display: "flex", padding: "20px 0", alignItems: "baseline" }}>
        <div className="offer" style={{ fontSize: "20px", marginRight: "10px", fontWeight: 500 }}>
          {t("address_details.address")} {data.address}
        </div>
        <div style={{ marginRight: "15px", fontSize: "14px" }}>
          {t("address_details.source_data_for_height")}:&nbsp;
          <Link to={`/blocks/${data.stateheight}`} style={{ color: "red", fontWeight: 600 }}>{data.stateheight}</Link>
        </div>

        <div style={{ display: "inline-block" }} onClick={() => {
          window.location.reload()
        }}>
          <Tooltip
            title={t("address_details.refresh_data")}
            color={"cyan"}
            overlayInnerStyle={{ borderRadius: "8px", padding: "8px 16px" }}
          >
            <img src="/assets/refresh.svg" width={24} alt="" />
          </Tooltip>
        </div>
      </div>
      <TitleCard title={t("address_details.miner_overview")} className="container ">
        <div className="account-view chart-box" >

          <Row className="chart-wrap"  >
            <Col className="box-left">
              <Card>
                <Row>
                  <div className="box-echarts" style={{ height: "250px", width: "200px" }}>
                    <ReactEcharts option={barOption} style={{ height: "100%", width: "100%" }} />
                  </div>

                  <div className="echarts-right" style={{ padding: "1rem 0" }}>

                    {/* 账户余额 */}
                    <h3 >{t("address_details.address_balance")}</h3>
                    <p className="h-p" style={{ fontSize: "24px" }}>{transFil(Number(data.minerbalance?.balance), 4)}</p>

                    {/* 可用余额 */}
                    <p>{t("address_details.available_balance")}
                      <Popover content={content}><InfoCircleOutlined style={{ margin: "0 3px", fontSize: "0.75rem" }} /></Popover>:{transFil(Number(data.minerbalance?.availablebalance), 4)}
                    </p>

                    {/* 扇区抵押 */}
                    <p>{t("address_details.initial_pledge")}:{transFil(Number(data.minerbalance?.initialpledge), 4)}</p>

                    {/*TODO 提供存储服务锁仓 */}
                    <p>{t("address_details.locked_rewards")}:{transFil(Number(data.minerbalance?.lockedrewards), 4)}</p>

                  </div>
                </Row>
              </Card>
            </Col>

            <Col className="box-right">
              <Card style={{ height: "100%" }}>
                <Row className="card-downward" style={{ padding: "1rem 0", width: "100%" }}>

                  <h3>{t("address_details.adjusted_power")}</h3>

                  <div style={{ display: "flex", width: "100%", justifyContent: "space-between", lineHeight: "36px", }}>

                    {/* 有效算力 */}
                    <p className="h-p" style={{ fontSize: "24px" }}>{changeSizeUnit(Number(data.minerpower?.minerpower.qualityadjpower))} </p>

                    {/* 占比 */}
                    <p>{t("address_details.rate")}:{proportion(Number(data.minerpower?.minerpower.qualityadjpower), Number(data.minerpower?.totalpower.qualityadjpower)) + "%"}</p>

                    {/* 排名暂无数据 */}
                    <p>{t("address_details.rank")}: {"N / A"}</p>

                  </div>


                  <Row justify="space-between" style={{ width: "100%" }}>
                    <Row>

                      {/* 原值算力 */}
                      <p>{t("address_details.raw_byte_rower")}:</p>
                      <p>{changeSizeUnit(Number(data.minerpower?.minerpower.rawbytepower))}</p>

                    </Row>
                    <Row>
                      {/*累计出块份数*/}
                      <p>{t("address_details.win_count")}<Popover content={addUp}><InfoCircleOutlined style={{ margin: "0 3px", fontSize: "0.75rem" }} /></Popover>: </p>
                      <p>{data.wincount?.toString()}</p>
                    </Row>
                  </Row>

                  <Row justify="space-between" style={{ width: "100%" }}>
                    <Row>

                      {/* 累计出块奖励 */}
                      <p>{t("address_details.total_reward")}: </p>
                      <p>{"N / A"}</p>

                    </Row>
                    <Row>

                      {/* 扇区大小 */}
                      <p>{t("address_details.sector_size")}:</p> <p>{changeSizeUnit(data.minerinfo?.sectorsize)}</p>

                    </Row>
                  </Row>

                  <Row justify="space-between" style={{ width: "100%" }}>
                    <p>{t("address_details.sector_status")}:</p>
                    <div className="div-disp">

                      {/* 全部 */}
                      <span>{data.minersectors?.live.toLocaleString()} {t("address_details.total")},</span>

                      {/* 有效 */}
                      <span className={"text-green-600"}>{data.minersectors?.active.toLocaleString()} {t("address_details.active")},</span>

                      {/* 错误 */}
                      <span className={" text-red-700"}> {data.minersectors?.faulty.toLocaleString()} {t("address_details.faults")},</span>

                      {/* 恢复中 */}
                      <span className={" text-yellow-500"}>{data.minersectors?.recoveries.toLocaleString()} {t("address_details.recoveries")}</span>
                    </div>
                  </Row>
                </Row>
              </Card>
            </Col>
          </Row>
          <Overview data={data} />

        </div>
        {
          sectorData.data && sectorData.data.length > 0 ?
            <div className="card-downward account-view">
              <MinerSectorsChart data={sectorData} minerId={minerID || ""} />
            </div> : null
        }
        <div className="account-view">
          <List />
        </div>
      </TitleCard>
    </>

  );
}
