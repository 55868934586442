/**
 * @刘泽明
 * 2022/09/13
 * */
import React, {CSSProperties, useEffect, useState} from "react";
import { useTranslation } from "react-i18next";

/**
* 计时器组件， 传入时间戳即可，目前只接受秒级时间戳，毫秒级后续需要时在扩展
* */
interface clockProps {
  timeStamp: number
  style?: CSSProperties | undefined
}

export const TimeClock = (props: clockProps) => {
  const { timeStamp, style } = props
  const currentTS = Math.floor(Date.now() / 1000)
  const [ts, setTS] = useState(currentTS - timeStamp)
  const { t } = useTranslation()
  const [value, setValue] = useState("")

  // 计时器方法
  function updateTime() {
    setTS((prev) => prev + 1)
  }
  
  useEffect(() => {
    // 每秒执行一次更新计时器函数
    const currentTS = Math.floor(Date.now() / 1000)
    setTS(currentTS - timeStamp)
    let timer = setInterval(updateTime, 1000)
    // 组件销毁时触发， 清理掉不用的计时器，释放系统资源
    return () => {
      clearInterval(timer)
    }
  }, [timeStamp])

  useEffect(() => {
    let minute = Math.trunc(ts / 60)
    let second = ts % 60
    setValue(`${minute} ${t("unit.min")} ${second} ${t("unit.sec")}`)
  }, [ts])


  return (
    <span style={{...style}}>
      {value}
    </span>
  )
}

