import { Card, Skeleton } from 'antd';
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';
import { ContractsOverviewData, SearchType } from '../../../model';
import { getContractData, getSearchIsExist } from '../../../servers/api';
import ContractsList from './ContractsCollection/ContractsCollection'
import ContractsOverview from './ContractsOverview/ContractsOverview'
import './index.less'


const Contract = () => {
    let flag = true;
    const [ContractOverViewData, setContractOverViewData] = useState({} as ContractsOverviewData);
    const [loading, setLoading] = useState(false);
    const { minerID, address } = useParams();
    const { state } = useLocation();
    const { t } = useTranslation();
    if (state) { flag = true; }

    const getFunctionApi = (evm?: string) => {
        setLoading(true)
        let id = ""
        if (evm) {
            id = evm
        } else if (minerID) {
            id = minerID
        } else {
            id = address || ""
        }
        getContractData(id).then((res) => {
            setLoading(true)
            if (res.ok && res.data) {
                setContractOverViewData(res.data);
            }
            setLoading(false)
        }).catch((err) => { setLoading(false) })
    }
    // TODO: 等后端增加地址转换后删除该接口请求
    const getEthAddr = () => {
        setLoading(true)
        let id = "";
        if (minerID) {
            id = minerID
        } else if (address) {
            id = address
        }
        getSearchIsExist(id).then((resp) => {
            let res = resp.data as SearchType
            if (res.Type == "evm") {
                getFunctionApi(res.Eth)
            }
        })
    }
    useEffect(() => {
        if (address?.startsWith("0x") || minerID?.startsWith("0x")) {
            getFunctionApi()
        } else {
            getEthAddr()
        }

    }, [minerID, address])

    return (
        <>
            {
                loading ? <Card title={t("Contract.overview")}>
                    <Skeleton active paragraph={{ rows: 11, width: ['100%', '100%'] }} />
                </Card> : <div className='ContractDiv'>
                    <div style={{marginBottom:'16px'}}>
                        <ContractsOverview loading={loading} ContractsList1={ContractOverViewData} />
                    </div>

                    <ContractsList loading={loading} flag={flag} address={minerID || address} overviewData={ContractOverViewData} />

                </div>
            }
        </>

    )
}

export default Contract