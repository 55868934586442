import { InputRef } from "antd";

import { useRef } from "react";
import { useNavigate } from "react-router-dom";
import Search from "antd/es/input/Search";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { getSearchIsExist } from "../servers/api";

import { SearchType } from "../model";
import { Notify } from "./Notify";


const Info = styled.div`
  background: #fff;
  position: absolute;
  width: 500px;
  height: 0;
  top: 3.5em;
  z-index: 100;
  right: 20px;
  transition: all 500ms;
  animation: textPopup 300ms both;
  overflow: hidden;
  box-shadow: 5px 5px 25px 5px grey;
  padding: 0 12px;
  @media (max-width: 500px) {
    max-width: 95%;

    right: 10px;
  }

  > a > div {
    &:hover {
      background: #f0f2f5;
      transition: all 500ms;
    }
  }
`;
const Wrapper = styled.div`
  background: rgba(0, 0, 0, 0.3);
  width: 100vw;
  height: 100%;
  position: absolute;
  z-index: 99;
  top: 0;
`;
interface Option {
  value: string | number;
  label: string;
  children?: Option[];
}


export const SearchBar = () => {
  const navigate = useNavigate();

  const Ref = useRef<InputRef>(null);
  const { t } = useTranslation();
  // 输入查询
  function onkeydown(e: string) {
    const searchValue = (Ref.current?.input?.value || "").trim();
    if (searchValue.trim() != "") {
      getSearchIsExist(searchValue).then((resp) => {
        if (resp.ok) {
          const result = resp.data as SearchType
          switch (result.Type) {
            case "blockId":
              navigate(`block/${searchValue}`)
              break;
            case "height":
              navigate(`blocks/${searchValue}`)
              break;
            case "messageId":
              navigate(`messages/${searchValue}`)
              break;
            case "unknown":
              if (result.Filecoin != "unknown" && result.Eth != "unknown") {
                navigate('/address/' + searchValue, { state: { data: result, type: result.Type} });
              }
              break;
            default:
              const address = /^f|t|0x|0X.*$/;
              if (address.test(searchValue) && result.Filecoin != "") {
                navigate('/address/' + searchValue, { state: { type: result.Type } });
                break;
              } else {
                Notify.error(t("tip.not_found"), t("tip.not_found_message"));
              }
          }
        }
      }).catch(e => {
        Notify.error(t("tip.not_found"), t("tip.not_found_message"));
      })
    }
  };

  return (
    <>
      <Search
        className='navbar-search-input'
        type="text"
        id={"asd"}
        ref={Ref}
        placeholder={t("navbar.placeholder")}
        defaultValue={""}
        onSearch={(value) => {
          onkeydown(value)
        }}
      />
    </>
  );
};
