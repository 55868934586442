import { useEffect, useState } from "react";
import { Button, Tabs, Row, Col, Card, Collapse, Spin } from "antd";
import { useNavigate } from "react-router-dom";
import "./ContractListData.less";
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-javascript";
import "ace-builds/src-noconflict/theme-github";
import "ace-builds/src-noconflict/ext-language_tools";
import { ContractData, ContractsOverviewData } from "../../../../../model";
import { useAccount, useContract, useProvider } from "wagmi";
import { Register_ABI, Register_address } from "../../../../../constant/constants";
import { analyzeABI, functionType } from "../../../../../functionality/analyzeABI";
import { Notify } from "../../../../../components/Notify";
import { useTranslation } from "react-i18next";
import ReturnedFunction from "./ReturnedFunction";

interface Props {
	ethAddress: string;
	address: string | undefined;
	contractData: ContractData | undefined;
}

const { Panel } = Collapse;

const ContractsListData = (props: Props) => {
	const { contractData, ethAddress, address } = props;
	const navigate = useNavigate();
	const editorChange = () => {};
	const [readFunctions, setReadFunctions] = useState<functionType[]>();
	const [writeFunctions, setWriteFunctions] = useState<functionType[]>();
	const [contractCode, setContractCode] = useState("");
  const [loading,setLoading] = useState(false);
	const { t } = useTranslation();
	useEffect(() => {
		if (contractData?.abi) {
			getData(contractData?.abi);
			setContractCode(contractData?.code);
		} else {
			setContractCode("");
		}
	}, [contractData, ethAddress]);

	const smartCont = () => {
		if (ethAddress) {
			navigate("/contractVerify/" + ethAddress, { state: address });
		} else {
			Notify.error(t("Contract.no_data"), t("Contract.no_data_tip"));
		}
	};

	// 获取合约数据
	async function getData(abi: any[]) {
		const data = await analyzeABI(abi);
		// TODO 读合约
		setReadFunctions(data?.read);

		// TODO 写合约
		setWriteFunctions(data?.write);
	}
	return (
		<Spin spinning={loading}>
			<div className="HeightLimit">
				{contractCode ? (
					// 已验证
					<div className="verified_container">
						<Tabs defaultActiveKey="1" type="card">
							<Tabs.TabPane tab="Code" key="1">
								{/*<div style={{ paddingLeft: "30px" }}>*/}
								{/*	<h3>Contract Source Code Verfied</h3>*/}
								{/*	<Row>*/}
								{/*		<Col span={5}>Contract Name</Col>*/}
								{/*		<Col>NAME</Col>*/}
								{/*	</Row>*/}
								{/*	<Row>*/}
								{/*		<Col span={5}>Compiler Version</Col>*/}
								{/*		<Col>V0.8.6</Col>*/}
								{/*	</Row>*/}
								{/*</div>*/}
								<div>
									<h2>Contract Source Code</h2>
									{/*//TODO 合约名称*/}
									{/*<p>File 1 of 3: {t("Contract.file_name")}</p>*/}
									<AceEditor
										value={contractCode}
										mode="javascript"
										theme="github"
										name="one"
										editorProps={{ $blockScrolling: true }}
										style={{ width: "100%", lineHeight: "15px", padding: "10px 0" }}
										maxLines={35}
										minLines={20}
										onChange={editorChange}
									/>
								</div>
							</Tabs.TabPane>
							<Tabs.TabPane tab="Read Contract" key="2">
								<h2>Read Contract information</h2>
								<Collapse accordion collapsible="header" defaultActiveKey={0}>
									{readFunctions?.map((value, index) => {
										return (
											<Panel header={`${index + 1}.${value.name}`} key={index}>
												<ReturnedFunction functionData={value} key={index} contractAddress={ethAddress} />
											</Panel>
										);
									})}
								</Collapse>
							</Tabs.TabPane>
							<Tabs.TabPane tab="Write Contract" key="3">
								<h2>Write Contract information</h2>
								<Collapse accordion collapsible="header" defaultActiveKey={0}>
									{writeFunctions?.map((value, index) => {
										return (
											<Panel header={`${index + 1}.${value.name}`} key={index}>
												<ReturnedFunction functionData={value} key={index} contractAddress={ethAddress} />
											</Panel>
										);
									})}
								</Collapse>
							</Tabs.TabPane>
						</Tabs>
					</div>
				) : (
					// 未验证
					<div
						style={{
							textAlign: "center",
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
							justifyContent: "center",
							minHeight: "14rem",
						}}>
						<div>
							<h3>{t("Contract.verify_contract")}</h3>
							<Button type="primary" onClick={smartCont}>
								{t("Contract.verify")}
							</Button>
						</div>
					</div>
				)}
			</div>
		</Spin>
	);
};

export default ContractsListData;
