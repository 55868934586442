import { Button, Col, Row } from "antd";
import ReactEcharts from "echarts-for-react";
import React from "react";
import TitleCard from "./TitleCard";

interface ZoomChartProps {
    seriesName: string,
    events?: Record<string, Function>,
    xAxisType?: string,
    data: string[][],
    lineType?: string,
    // atteData: string[][],
    zoomStart?: string[] | number | string,
    zoomEnd?: string[] | number | string,
    tooltipFormatData?: (params: any) => void
    loading?: boolean
    yAxisAxisLabel?: {}
}

export default function ZoomChart(this: any, props: ZoomChartProps) {
    const { data, zoomStart, zoomEnd, events, xAxisType, lineType, loading, seriesName, yAxisAxisLabel, tooltipFormatData } = props

    const option = {
        tooltip: tooltipFormatData ? {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow',
            },
            //自定义了formatter
            formatter: tooltipFormatData
        } : {
            trigger: 'axis'
        },
        // title: {
        //     left: 'center',
        //     text: 'Large Ara Chart'
        // },
        xAxis: {
            // type: 'category',
            // 如果想指定时间类型可以使用以下参数  这里使用数据驱动Y轴
            type: xAxisType ? xAxisType : 'category',   // x轴为 时间轴
            axisLabel: {
                color: '#5A6872',
                fontSize: 11,
                wordBreak: 'keep-all'
            },
            axisTick: {
                alignWithLabel: true,
            },
            boundaryGap: true,
        },
        yAxis: {
            type: 'value',
            axisLabel: yAxisAxisLabel ? yAxisAxisLabel : {},
            // boundaryGap: [0, '100%']
        },
        dataZoom: [

            {
                type: 'inside',
                filterMode: "filter",
                startValue: zoomStart,
                endValue: zoomEnd === 0 ? undefined : zoomEnd,
                // rangeMode: 
                // start: 0,
                // end:5
            },
            { backgroundColor: "rgba(201, 222, 38, 0.1)" },

        ],
        grid: { top: 48, right: 36, bottom: 78, left: 96 },
        series: [
            // {
            //     name: seriesName? seriesName:title,
            //     type: "bar",
            //     stack: 'Total',
            //     itemStyle: {
            //         borderColor: "#6d6d6e02",
            //         color: "#58a0d72c"
            //         // color: "transparent"
            //     },
            //     emhasis:{
            //         itemStyle: {
            //             borderColor: "transparent",
            //             color:"transparent"
            //         }
            //     },
            //     data: atteData,
            // },
            {
                name: seriesName,
                type: lineType ? lineType : 'bar',
                smooth: true,
                symbol: 'none',
                stack: 'Total',
                itemStyle: {
                    borderColor: "#6d6d6e02",
                    color: "rgba(69, 130, 223, 0.936)",
                    borerRadius: [10, 10, 0, 0]
                },

                areaStyle: {},
                data: data
            }
        ]
    }

    return (

        <ReactEcharts
            onEvents={events}
            showLoading={loading}
            option={option}
            lazyUpdate={true}
            style={{ width: '100%', maxHeight: '560px', display: 'flex', position: 'relative' }}
            className='react_for_echarts' />

    )
}