import { SearchBar } from "../../SearchBar";
import { Dropdown, Layout, Menu, Row, Space } from "antd";
import * as React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { LanguageBarMenu } from "../../Navbar/Menu/SubMenu/LanguagebarMenu";
import { MenuBean } from "../../../model";
import { LogoPic } from "../LogoPic";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import { ConnectWeb3Button } from "../Menu/ConnectWeb3Button";
import i18n from "../../../i18n/config";

interface CustomMenu {
    menus: MenuBean[]
    className?: string
}
export const MobileNavbar = (props: CustomMenu) => {
    const { menus } = props
    const { t } = useTranslation()
    const { Header } = Layout;
    const pathList = (x: any, n: number) => {
        if (x.length === 1) { return }
        return x.map((i: any, index: number) => {


            if (i == "zh") {
                return {
                    key: `${n - index}`,
                    label: <span onClick={() => {
                        i18n.changeLanguage("zh");
                    }}>{t("navbar.zh")}</span>
                }
            } else if (i == "en") {
                return {
                    key: `${n - index}`,
                    label: <span onClick={() => {
                        i18n.changeLanguage("en");
                    }}>{t("navbar.en")}</span>
                }
            } else if (i == "mainnet") {
                return {
                    key: `${n - index}`,
                    label: <span onClick={() => {
                        sessionStorage.setItem("net", "mainnet");
                        window.location.replace("/")
                    }}>{t("navbar.mainnet")}</span>
                }
            } else if (i == "hyperspace") {
                return {
                    key: `${n - index}`,
                    label: <span onClick={() => {
                        sessionStorage.setItem("net", "hyperspace");
                        window.location.replace("/")
                    }}>{t("navbar.hyperspace")}</span>
                }
            }
            return {
                key: `${n - index}`,
                label: <Link to={i} >{t(`navbar.${i.substring(1)}`)}</Link>
            }
        })
    }
    const menuList = menus && menus.map((item: any, index: number) => {
        return {
            key: `${item.id}`,
            label: <Link to={item.pathList[0]} style={item.pathList.length > 1 ? {} : { color: "#4a8af7" }}>{item.element.props.children}</Link>,
            type: `group`,
            children: pathList(item.pathList, index)
        }
    })
    const menu = (
        <Menu
            items={menuList}
        />
    );
    return (
        <div className="mobile-content">
            <LogoPic />
            <Row className="mobile-content-right">
                <SearchBar />
                {/* <Dropdown overlay={<LanguageBarMenu />} arrow autoFocus={true} >
                    <a onClick={e => e.preventDefault()} >
                        <Space>
                            <img style={{ margin: '0 12px' }} src={'/assets/translate.png'} width={20} height={20} alt={'language'} />
                        </Space>
                    </a>
                </Dropdown> */}
                <Dropdown overlay={menu} arrow autoFocus={true} overlayStyle={{ minWidth: "180px" }} >
                    <a onClick={e => e.preventDefault()} >
                        <Space>
                            <img style={{ margin: '0 6px' }} src={'/assets/menu.svg'} width={20} height={20} alt={'language'} />
                        </Space>
                    </a>
                </Dropdown>
                {/* <ConnectWeb3Button /> */}
            </Row>
        </div>
    )
}