import { Mobile, PC } from "../../../shared/pc";
import TitleCard from "../../../components/TitleCard";
import { Button, Col, Pagination, Row, Space } from "antd";
import LatestBlockList from "./LatestBlockList";
import RichPage from "../../BlockChain/Rich/RichPage";
import * as React from "react";
import { CusTable } from "../../../components/CustomHome/CusTable";
import { LatestBlocks, RichList, RichPageType } from "../../../model";
import { ReactNode, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { getFilrich, getHeightList } from "../../../servers/api";
import { HeightDetailData } from "../../../servers/respType";
import { Link } from "react-router-dom";
import { TimeClock } from "../../../components/TimeClock";
import Mob_LastBlockAndRich from "../../Mobile/Mob_LastBlockAndRich";


/**
 * @宋恩 9/8
 * */

export const LastBlockAndRich = () => {
  const { t } = useTranslation()
  const [blockData, setBlockData] = useState([] as LatestBlocks[])
  const [richData, setRichData] = useState([] as ReactNode[][])
  const [loading, setLoading] = useState(false)
  function fetchBlock() {
    setLoading(true)
    getHeightList({ count: 10 }).then((value) => {
      const d = value.data as HeightDetailData[]
      const newData = [] as LatestBlocks[]
      if (d) {
        d.forEach((item) => {
          if (item.Blocks)  {
            newData.push({
              blocks: item.Blocks,
              heightInfo: { height: item.height, timestamp: item.timestamp },
              messageCount: item.messageCount
            })
          } 
        })
        setBlockData(newData)
        setLoading(false)
      }
    })
    setTimeout(() => {
      setLoading(false)
    }, 15000)
  }

  function fetchRich() {
    getFilrich().then((res) => {
      if (res.data) {
        const data = res.data as RichPageType
        const richList = data.richList as RichList[]
        const newSecond = [
          [<div style={{ display: 'flex', width: '40%', color: "#718096" }}>
            <div style={{ flex: '20%' }}>Rank</div>
            <div style={{ flex: '30%' }}>Address</div>
          </div>,
          <div style={{ width: '50%', textAlign: 'right', color: "#718096" }}>
            Balance / Rate
          </div>
          ]
        ] as ReactNode[][]
        richList.forEach((item: RichList, index: number) => {
          newSecond.push
            (
              [<div style={{ display: 'flex', width: '40%' }}>
                <div style={{ flex: '20%' }}> {index + 1}</div>
                <div style={{ flex: '30%' }}>{item.address}</div>
              </div>,
              <div style={{ width: '50%', textAlign: 'right' }}>
                {item.balance}
              </div>
              ]
            )
        });
        setRichData(newSecond)
      }
    })
  }

  useEffect(() => {
    // fetchRich()
    fetchBlock()
  }, [])

  return (
    <>
      <div className="ck" style={{
        justifyContent: 'space-between',
        display: 'flex',
        flexDirection: "row",
        maxWidth: "100%"
      }}>

        {/**@特定场合有bug 暂时加上max-width50%限制 */}
        <TitleCard style={{ flex: '1 1 100%', maxWidth: "100%" }} title={
          <Col>
            {t("title.latst_block")}
          </Col>
        }>
          {/*TODO 占位*/}
          <LatestBlockList data={blockData} showMore loading={loading} />
        </TitleCard>
        {/*<TitleCard style={{flex: '1 1 50%', marginLeft: '16px'}} title={*/}
        {/*  <>*/}
        {/*    <Col>*/}
        {/*      {t("title.rich_ranking")}*/}
        {/*    </Col>*/}
        {/*    <Col className={'css-overview-button'}>*/}
        {/*      <Button shape={"round"} href={'rich-list'}>*/}
        {/*        {t("title.more")}*/}
        {/*      </Button>*/}
        {/*    </Col>*/}
        {/*  </>*/}
        {/*}>*/}
        {/*  /!*TODO 占位*!/*/}
        {/*  <RichPage isHome/>*/}
        {/*</TitleCard>*/}
      </div>
      <div className="hi">
        <Mob_LastBlockAndRich showMore blockData={blockData} loading={loading}/>
      </div>
      {/*<Mobile>*/}
      {/*  <div style={{*/}
      {/*    justifyContent: 'space-between',*/}
      {/*    display: 'flex',*/}
      {/*    flexDirection: "column"*/}
      {/*  }}>*/}
      {/*    <TitleCard style={{flex: '1 1 50%', margin: "0 0 16px"}}*/}
      {/*               title={t("title.latst_block")}>*/}
      {/*      {blockData.length > 0 ?*/}
      {/*        blockData.map((item) => {*/}
      {/*          return (*/}
      {/*            <TitleCard style={{marginBottom: '12px'}} key={item.heightInfo.height} title={*/}
      {/*              <>*/}
      {/*                <Row>*/}
      {/*                  <span style={{paddingRight: '16px'}}>Height</span>*/}
      {/*                  <Link style={{color: "#108cee"}} to={`/blocks/`}>{item.heightInfo.height}</Link>*/}
      {/*                </Row>*/}
      {/*                <Col>*/}
      {/*                  <TimeClock timeStamp={item.heightInfo.timestamp}/>*/}
      {/*                </Col>*/}
      {/*              </>*/}
      {/*            }>*/}
      {/*              {item.blocks && item.blocks.map((block, index) => {*/}
      {/*                return (*/}
      {/*                  <Row justify={"space-between"} style={{padding: '8px 16px'}} key={index}>*/}
      {/*                    <Col>{block.miner}</Col>*/}
      {/*                    <Col>Reward:{block.reward}FIL</Col>*/}
      {/*                  </Row>*/}
      {/*                )*/}
      {/*              })}*/}
      {/*            </TitleCard>*/}
      {/*          )*/}
      {/*        })*/}
      {/*        : null}*/}

      {/*    </TitleCard>*/}
      {/*    <TitleCard title={t("title.rich_ranking")} style={{marginBottom:'16px'}}>*/}
      {/*      /!*<CusTable data={richData}/>*!/*/}
      {/*      <RichPage isHome/>*/}
      {/*    </TitleCard>*/}
      {/*  </div>*/}
      {/*</Mobile>*/}
    </>
  )
}