import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { DetailsCard } from "../../../components/DetailsCard";
import { getDealDetail } from "../../../servers/api";
import { Row, Col, Grid } from "antd";
import { useTranslation } from "react-i18next";
import DateUtil from "../../../utils/formatData";
import EllipsisMiddle from "../../../components/EllipsisMiddle";
import { changeSizeUnit, transFil } from "../../../utils/convertUtils";
import formatData from "../../../utils/formatData";
import "./index.less";

const { useBreakpoint } = Grid;
const { formatDate } = formatData;

interface resp {
  storage_price_per_epoch: string;
  status: string;
  deal_id: string;
  height: string;
  create_time: string;
  state_root: string;
  piece_cid: string;
  is_verified: string;
  client_id: string;
  client_collateral: string;
  provider_collateral: string;
  provider_id: string;
  start_epoch: number;
  end_epoch: number;
  padded_piece_size: number;
}
export default function DealDetail() {
  // 定义hook
  const [detailData, setdetailData] = useState({} as resp);
  const { t } = useTranslation();
  const { lg } = useBreakpoint();
  // 接收id
  const { id } = useParams();
  // 请求当前id数据
  async function fetchDealDetail() {
    const data = await getDealDetail({ id: id });
    // 输出当前数据
    console.log(data);
    if(data){
      setdetailData(data.data);
    }
  }
  useEffect(() => {
    fetchDealDetail();
  }, []);
  // 兼容 >= 992 和 992 以下
  return (
    <div>
      {/* pc端 */}
      <div
        className="detail-container-lg"
        style={{ display: lg ? "block" : "none" }}
      >
        <h3>{t("dealDetail.detail") + " #" + id}</h3>
        <DetailsCard
          boxStyle={{ borderRadius: "8px 8px 0 0" }}
          data={[
            [t("dealDetail.id"), detailData.deal_id],
            [t("dealDetail.time"), formatDate(detailData.create_time, "yyyy-MM-dd HH:mm:ss")],
            [t("dealDetail.block"), detailData.height],
            [t("dealDetail.message"), detailData.state_root],
            [t("dealDetail.cid"), detailData.piece_cid],
            [
              t("dealDetail.verified"),
              detailData.is_verified ? "true" : "false",
            ],
            [t("dealDetail.status"), detailData.status ? detailData.status : 'N/A'],
          ]}
        />
        <div className="controDetail">
          <p className="tg">{t("dealDetail.storage_detail")}</p>
          <Row align="middle">
            <Col span={6}>
              <p>{t("dealDetail.client")}</p>
              <img
                src="https://filfox.info/dist/img/client.50d1048.svg"
                alt=""
              />
              <p style={{color:'#1a4fc9',fontSize:'.875rem'}}>
                <EllipsisMiddle suffixCount={8} children={detailData.client_id} />
              </p>
              <p>
                {t("dealDetail.collateral")}:{detailData.client_collateral} FIL
              </p>
            </Col>
            <Col span={12} className="col12">
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src="https://filfox.info/dist/img/file.430b535.svg"
                  alt=""
                />
                <p>{changeSizeUnit(detailData.padded_piece_size, "GiB")}</p>
              </div>
              <p>
                {formatDate(detailData.start_epoch, "yyyy-MM-dd HH:mm:ss")}{" "}
                至 {formatDate(detailData.end_epoch, "yyyy-MM-dd HH:mm:ss")}
              </p>
              <p>{t("dealDetail.storage_fee")}:{detailData.storage_price_per_epoch}</p>
            </Col>
            <Col span={6}>
              <p>{t("dealDetail.provider")}</p>
              <img
                src="https://filfox.info/dist/img/provider.448d989.svg"
                alt=""
              />
              <p style={{color:'#1a4fc9',fontSize:'.875rem'}}>{detailData.provider_id}</p>
              <p>
                {t("dealDetail.collateral")}:
                {transFil(detailData.provider_collateral, 4)} 
              </p>
            </Col>
          </Row>
        </div>
      </div>
      {/* 移动端 */}
      <div
        className="detail-container-md"
        style={{ display: lg ? "none" : "block" }}
      >
        <div>
          <p className="title">订单详情</p>
        </div>
        <div className="main">
          <DetailsCard
            boxStyle={{ borderRadius: "0.5rem 0.5rem 0 0" }}
            bodyStyle={{
              justifyContent: "space-between",
              wordBreak: "break-all",
              // whiteSpace:"nowrap",
              textAlign: "right",
              fontSize: "12px",
            }}
            data={[
              [
                t("dealDetail.id"),
                <div>
                  {detailData.deal_id}
                  <i
                    style={{ color: "gold", cursor: "pointer" }}
                    className="iconfont icon-verified verified"
                  ></i>
                </div>,
              ],
              [
                t("dealDetail.time"),
                formatDate(detailData.create_time, "yyyy-MM-dd HH:mm:ss"),
              ],
              [
                t("dealDetail.block"),
                <span className="color">{detailData.height}</span>,
              ],
              [
                t("dealDetail.message"),
                <span className="color">{detailData.state_root}</span>,
              ],
              [t("dealDetail.cid"), detailData.piece_cid],
              [
                t("dealDetail.verified"),
                detailData.is_verified ? "true" : "false",
              ],
              [t("dealDetail.status"), "N/A"],
            ]}
          />
          <div className="trad">
            <Row justify="center">
              <Col span={24}>
                <p>{t("dealDetail.client")}</p>

                <img
                  src="https://filfox.info/dist/img/client.50d1048.svg"
                  alt=""
                />
                <p>
                  <EllipsisMiddle
                    suffixCount={8}
                    children={detailData.client_id}
                  />
                </p>
                <p>
                  {t("dealDetail.collateral")}:{detailData.client_collateral} FIL
                </p>
              </Col>
            </Row>
            <Row justify="center">
              <Col span={24} style={{backgroundColor:'#f0f6fb',padding:'16px 32px'}}>
                <div style={{display:'flex',marginBottom:'10px'}}>
                  <img width="20px"
                    src="https://filfox.info/dist/img/file.430b535.svg"
                    alt=""
                  />
                  <span>{changeSizeUnit(detailData.padded_piece_size, "GiB")}</span>
                </div>
                <p>
                  {formatDate(detailData.start_epoch, "yyyy-MM-dd HH:mm:ss")}{" "}
                  至{" "}
                  {formatDate(detailData.end_epoch, "yyyy-MM-dd HH:mm:ss")}
                </p>
                <p>{t("dealDetail.storage_fee")}:N/A</p>
              </Col>
            </Row>
            <Row justify="center">
              <Col>
                <p>{t("dealDetail.provider")}</p>
                <img
                  src="https://filfox.info/dist/img/provider.448d989.svg"
                  alt=""
                />
                <p>{detailData.provider_id}</p>
                <p>
                  {t("dealDetail.collateral")}:
                  {transFil(detailData.provider_collateral,4)}
                </p>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </div>
  );
}
