
import {SmallCardProps, WebSocketData} from "../../../model";
import {TFunction, useTranslation, } from "react-i18next";
import {TimeClock} from "../../../components/TimeClock";
import { changeSizeUnit } from "../../../utils/convertUtils";
import { log } from "console";



export function InfoCardData  (data: WebSocketData, t: TFunction)  {
 
  return  [
    {
      title: t("home_target.block_height"),
      content: data.height || "--",
      tip: "",
    },
    {
      title: t("home_target.latest_block_time"),
      content: data.timestamp ? <TimeClock style={{fontWeight: 600}} timeStamp={data.timestamp}/> :  "--",
      tip: "",
    },
    {
      title:t("home_target.network_storage_power"),
      content: data.quality_adjPower ?  changeSizeUnit(Number(data.quality_adjPower)) : "--",
      // tip: t("tip.network_storage_power"),
      tip:""
    },
    {
      title: t("home_target.current_blocks_win"),
      content: data.blocks  || "--",
      // tip: t("tip.network_storage_power"),
      tip:""
    },
    {
      title: t("home_target.current_blocks_msg"),
      content: data.messages  || "--",
      tip: "",
    },
    {
      title: t("home_target.average_number_of_blocks"),
      content: data.height_blocks || "--",
      // tip: t("tip.block_reward"),
      tip:""
    },
    {
      title: t("home_target.24h_blocks"),
      content: data.hours_blocks || "--",
      // tip: t("tip.24h_AMR"),
      tip:""
    },
    {
      title: t("home_target.average_block_interval"),
      content: data.average_blocks || "--",
      // tip: t("tip.24h_fil_prod"),
      tip:""
    },
    //TODO 值不正确
    {
      title: t("home_target.24h_message_count"),
      content: data.hours_messages || "--",
      tip: "",
    },
  ] as SmallCardProps[];
}