/** @format */

import { useLocation, useNavigate, useParams } from "react-router-dom";
import AddressDetails from "../pages/Details/AddressDetails/AddressDetails";

import { getSearchIsExist } from "../servers/api";
import { useEffect, useState } from "react";
import OtherAccount from "../pages/Details/OtherAccount/OtherAccount";
import { isNormalDetail } from "../utils/AccountType";
import { Notify } from "../components/Notify";
import { SearchType } from "../model";
import Contract from "../pages/Details/Contract";
import { Spin } from "antd";
import { useTranslation } from "react-i18next";

export const Mix = () => {
	const navigate = useNavigate();

	const params = useParams();
	const { state } = useLocation();

	const [loading, setLoading] = useState(false);
	const [accountType, setAccountType] = useState("" as string);
	const [data, setData] = useState({} as SearchType);
	const { t } = useTranslation();
	useEffect(() => {
		if (state) {
			setAccountType(state.type);
		} else {
			params.minerID && getFunctionApi(params.minerID);
		}
	}, [params.minerID, state]);

	const getFunctionApi = (minerID: string) => {
		setLoading(true);
		minerID &&
			getSearchIsExist(minerID)
				.then(res => {
					setLoading(false);
					const result = res.data as SearchType;
					if (result) {
						setAccountType(result.Type);
						setData(result);
					}
				})
				.catch(err => {
					setLoading(false);
				});
	};
	function DetailsPage() {
		let detailType;

		if (accountType !== "") {
			detailType = isNormalDetail(accountType);

			switch (detailType) {
				case 1:
					return (
						<OtherAccount
							accountType={accountType}
							addrData={accountType === "unknown" ? (state ? state.data : data) : undefined}
						/>
					);
				case 2:
					return <AddressDetails accountType={accountType} />;
				case 3:
					return <Contract />;

				default:
					Notify.error(t("tip.not_found"), t("tip.not_found_message"));
					setTimeout(() => {
						navigate("/");
					}, 1000);
					break;
			}
		}
	}

	return (
		<>
			{loading ? (
				<div className="example">
					<Spin size="large" />
				</div>
			) : (
				<div>{accountType && DetailsPage()}</div>
			)}
		</>
	);
};
