import TitleCard from "../../../components/TitleCard";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import Tabulation from "../../../components/Tabulation";
import { ColumnsType } from "antd/lib/table";
import { getContractList } from "../../../servers/api";
import EllipsisMiddle from "../../../components/EllipsisMiddle";
import { Col, Row, Select } from "antd";
import { MemPool, MessageData } from "../../../servers/respType";
import { Link } from "react-router-dom";
import { transFil } from "../../../utils/convertUtils";
import { DetailsCard } from "../../../components/DetailsCard";
import { ContractMobile } from "./components/Contract_MobileData";
import './index.less'
/**
 * @万诚 9/8
 * */
const { Option } = Select;

const TableConf = () => {
  const { t } = useTranslation()
  return [
    {
      title: t("Contract.address"),
      key: 'EthAddress',
      dataIndex: 'EthAddress',
      width: 320,
      align: "center",
      render: (EthAddress) => <Link to={`/contract/${EthAddress}`}>{EthAddress}</Link>
    },
    {
      title: t("Contract.nickname"),
      key: 'from',
      dataIndex: 'Message',
      align: "center",
      render: (text) => 'N / A'
    },
    {
      title: t("Contract.create_height"),
      key: 'Height',
      dataIndex: 'Height',
      align: 'center',

      render: (height: number) => (
        <Row justify="center">
          <div className="block-height">
            <Link to={`/blocks/${height}`}>
              {height}
            </Link>
          </div>
        </Row>
      ),
    },
    {
      title: t("Contract.message_count"),
      key: 'method',
      dataIndex: 'Message',
      align: 'center',
      render: (text) => 'N / A'
    },
    {
      title: t("Contract.is_check"),
      key: 'value',
      dataIndex: 'Message',
      align: 'center',
      render: (text) => 'N / A'
    },
  ] as ColumnsType
}


const Contract_list = () => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [memdata, setData] = useState({ page: 1, pageSize: 20, count: 0, data: [] as MemPool[] })

  const getFunctionApi = (page: number, pageSize: number,) => {

    setLoading(true)

    getContractList({ page: page - 1, pageSize }).then((res) => {

      setLoading(false)
      if (res.ok && res.data) {
        setData({ page: page, pageSize: pageSize, data: res.data, count: res.count })
      }

    }).catch((err) => { })
  }

  useEffect(() => { getFunctionApi(memdata.page, memdata.pageSize) }, [])


  return (
    <div>
      {/** @合约列表 */}
      <div className="page-title">{t('navbar.contract')}</div>
      <TitleCard title={t("navbar.contract_list")} className="contract-pc" >
        <Tabulation
          tableDataTypeConfig={TableConf}
          rowKey={function (record, index): string {
            return record.height + index;
          }}
          onChange={(pageSize, page) => {
            if (page && pageSize) {
              getFunctionApi(page, pageSize)
            }
          }}
          dataConf={memdata} loading={loading} />
      </TitleCard>

      
      <TitleCard className="contract-mobile">
      <Col className="msg-top-card msg-count-top" > { t('navbar.contract_list')}</Col>
         <ContractMobile data={memdata.data as MemPool[]} /> 
      </TitleCard>
      
    </div>
  )
}

export default Contract_list