/*
 * @Author: liuzeming1 liuzeming@storswift.com
 * @Date: 2022-10-16 09:36:08
 * @LastEditors: liuzeming1 liuzeming@storswift.com
 * @LastEditTime: 2022-10-19 22:06:42
 * @FilePath: /filecoin/src/utils/request.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
/** @format */

// API 更新时间：
// API 唯一标识：
// @ts-ignore
// import * as api from './api';
// export default {
//     api,
// };

/**
 *  TODO 封装接口调用方法
 */
import axios from "axios";
import { BASE_URL } from "../constant/Global";

const serverConfig = {
	baseURL: BASE_URL,
	useTokenAuthorization: false, //是否开启 token 验证
}
const serviceAxios = axios.create({
	baseURL: serverConfig.baseURL,
	timeout: 25000,
	headers: {
		"Content-Type": "application/json",
	},
});

/**
 * 请求拦截
 * */
serviceAxios.interceptors.request.use(
	(config) => {

		// console.log("请求配置", config);
		// 是否使用 Token
		if (serverConfig.useTokenAuthorization) {
			// @ts-ignore
			config.headers["Authorization"] = localStorage.getItem("token");
		}
		if (/get/i.test(config.method ||"")) {
			let t = new Date()
			config.params = config.params || {};
			config.params.t = t.getTime() /1000
		}
		// 设置请求头
		if (config.method === "post") {
			// @ts-ignore
			config.headers["content-type"] = "application/x-ww-form-urlencoded";
			// config.data = qs.stringify(config.data); //序列化  效果等同于下行代码
			// @ts-ignore
			config.requestType = "form";
		} else {
			// @ts-ignore
			config.headers["content-type"] = "application/json";
		}
		// 返回
		// @ts-ignore
		config.headers["net"] = sessionStorage.getItem("net");
		return config;
	},
	(error) => {
		Promise.reject(error);
	}
);


export async function request(url:string, method: "GET" | "POST" | "PATCH",  options?: Object) {
  if(method == 'GET'){
    return serviceAxios({
      url,
      method,
      params:options

    })
  }else{
    return serviceAxios({
      url,
      method,
      data:options

    })
  }
}
