import {TopicAddBlock, Mainnet_WS_URL, Hyper_Space_WS_URL} from "../constant/Global";

type WSListeners = (ev: MessageEvent) => any
const heartBeatTime = 30000 //  心跳检测时长
const heartBeatSendTime = 20000 //  心跳检测时长
const maxReconnectTimes = 15;


let URL = Mainnet_WS_URL

let wsTopic = "filblockmainnet"
if (sessionStorage.getItem("net") == "hyperspace") {
    URL = Hyper_Space_WS_URL
    wsTopic = "filblock"
}

// URL = "wss://127.0.0.1:3000/api/v1/fil/websocket/mainnet"
class WSClient {

    wsClient: WebSocket
    listeners: Map<string, WSListeners> = new Map<string, WSListeners>()
    intervalId?: NodeJS.Timer
    timeoutObj?: number // 定时变量 =

    reconnectTime = 0
    reconnecting = false;

    constructor() {
        this.wsClient = new WebSocket(URL)
        this.initEventHandle()
        this.intervalId = setInterval(() => {
            this.wsClient.send("ping")
        }, heartBeatSendTime)
    }

    public addEventListener(key: string, value: WSListeners) {
        if (this.wsClient.CLOSED && this.wsClient) {
            this.createWebSocket();
        }
        this.listeners.set(key, value)
    }

    public removeEventListener(key: string) {
        this.listeners.delete(key)
    }

    handleMessage(ev: MessageEvent) {
        for (const listener of this.listeners.values()) {
            listener(ev)
        }
    }

    heartCheck = {

        reset: () => { // 重置定时

            clearTimeout(this.timeoutObj)
            return this.heartCheck;
        },
        start: () => { // 开启定时
            // @ts-ignore
            this.timeoutObj = setTimeout(() => {
                // 心跳时间内收不到消息，主动触发连接关闭，开始重连
                this.wsClient.close();
            }, heartBeatTime)
        }
    }

    // 初始化事件函数
    initEventHandle() {
        this.wsClient.addEventListener("open", () => {
            this.reconnectTime = 0
            if (this.wsClient.readyState === 1) {
                this.wsClient.send(JSON.stringify({
                    action: "subscribe",
                    topic: wsTopic,
                }))
            }
            this.heartCheck.reset().start();      //心跳检测重置
        })

        this.wsClient.addEventListener("close", () => {

            this.reconnect();
        })

        this.wsClient.addEventListener("error", (evt) => {

            this.reconnect();
        })

        this.wsClient.addEventListener("message", (e) => {
            this.heartCheck.reset().start();

            this.handleMessage(e)
        })
    }

    // 实例websocket
    createWebSocket() {
        try {
            this.reconnecting = false
            this.wsClient = new WebSocket(URL);
            this.initEventHandle();
        } catch (e) {
            this.reconnect();
        }
    }

    closeWebSocket() {
        try {
            clearInterval(this.intervalId)
            this.intervalId = undefined
            this.wsClient.close()
        } catch (e) {

        }

    }

    // 重连
    reconnect() {
        if (!this.reconnecting) {
            this.reconnectTime++
            if (this.reconnectTime <= maxReconnectTimes) {
                this.reconnecting = true
                setTimeout(() => {     //没连接上会一直重连，设置延迟避免请求过多
                    this.createWebSocket();

                }, 3000 * this.reconnectTime);
            }
        }

    }

}


const wsClient = new WSClient();

export default wsClient;

