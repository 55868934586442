/** @format */
/* 区块详情 */
import { Card, Col, List, Skeleton } from "antd";
import React, { useEffect, useState } from "react";
import "./index.less";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams, Link } from "react-router-dom";
import { getBlocks } from "../../../servers/api";
import { UiData } from "../../../model";

import { DetailsCard } from "../../../components/DetailsCard";
import DateUtil from "../../../utils/formatData";

import { MessageList } from "../../BlockChain/Message/components/MessageList";
import { TextLengthElls } from "../../../utils/adaptiveTextLength";
import TitleCard from "../../../components/TitleCard";

const DetaList = (props: any) => {
  const { t, item } = props;
  // console.log(item); 

  return [
    [t("block.block_ID"), <TextLengthElls content={item.id} />],
    [t("block.height"), <Link to={{ pathname: `/blocks/${item.height}` }}>{item.height}</Link>],
    [
      t("block.storage_Provider"),
      <Link to={{ pathname: `/address/${item.Storage_Providers}` }}>{item.Storage_Providers}</Link>,
    ],
    [t("block.time"), DateUtil.formatDate(Number(item.times), "yyyy-MM-dd HH:mm:ss")],
    // [t("block.size"), item.Size],
    [t("block.message"), item.message],
    // [t("block.rewards"), item.Rewards],
    [t("block.number_of_bonus_copies"), item.Storage_Providerss],
    [t("block.parent_block"), item.parents ? item.parents.map((item: any) => <div><Link to={`/block/${item['/']}`}><TextLengthElls content={item['/']} /></Link></div>) : "nill"],
    [t("block.parent_block_weight"), Number(item.Parent_block_weights).toLocaleString()],
    // [t("block.fines"), item.penalty],
    [t("block.Parent Base Fee"), item.Parent_Base_Fee + " attoFIL"],
  ];
};

const BlockDetails = (props: any) => {
  const { t } = useTranslation();
  const [dataList, setDataList] = useState([] as UiData[]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const params = useParams();
  let id = params.id;

  useEffect(() => {
    getBlockDetail();
  }, [id]); //id改变重新发送接口

  function getBlockDetail() {// 获取id

    setLoading(true)

    getBlocks(id).then((res) => {

      if (res.ok) {

        const Blocks = res.data.Blocks[0]
        const block = res.data as any;
        const list = [] as any;

        list.push({
          id: Blocks.cid,
          height: block.height,
          Storage_Providers: Blocks.miner,
          times: block.timestamp,
          Size: "N / A", // TODO 没有数据
          message: Blocks.messageCount.toString(),
          Rewards: "N / A", // TODO 没有数据
          Storage_Providerss: Blocks.winCount.toString(),
          Parent_Block: "N / A", // TODO 没有数据
          Parent_block_weights: Blocks.parentWeight,
          Fines: "N / A", // TODO 没有数据
          Parent_Base_Fee: Blocks.parentParentBaseFee,
          parents: Blocks.parents

        } as any);

        setDataList(list);

      }

      setLoading(false)

    }).catch(error => setLoading(false));
  }
  return (
    <>
      {
        loading ? <>
          <Col className="page-title"> {t("block.block_details")} </Col> <Card title={t("block.block_Overview")}>
            <Skeleton active paragraph={{ rows: 11, width: ['100%', '100%'] }} />
          </Card>
        </> :
          <>
            <Col className="page-title"> {t("block.block_details")} </Col>
            <TitleCard title={t("block.block_Overview")}>
              <List size="large" split={false} dataSource={dataList}
                renderItem={(item) => (
                  <> <DetailsCard bodyStyle={{ padding: ".6rem 2.5rem", }} data={DetaList({ t, item })} /> </>
                )}
              />
            </TitleCard>
          </>
      }


      <div style={{ marginTop: "1.5rem" }}>
        <MessageList cid={id} />
      </div>


    </>
  );
};

export default BlockDetails;
