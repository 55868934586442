/** @format */

import { Divider } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { DetailsCard } from "../../../../../components/DetailsCard";
import TitleCard from "../../../../../components/TitleCard";
import { CustomSpace } from "../../../../../shared/customSpace";
// 普通账户

const AccountA = (props: any) => {
  const { datae } = props;
  const { t } = useTranslation();
  return (
    <TitleCard className="item-mb" title={
      t("RichPage.Account_Overview")
    }>
      <DetailsCard
        bodyStyle={{ padding: ".5rem 2rem", alignItems: "center" }}
        data={datae}
      />
      <CustomSpace />
    </TitleCard>
  );
};

export default AccountA;
