/// 部署合约的地址  该合约用来存储ipfs地址和合约地址
export const Register_address = "0x82cb6f536e0883585B5fd5D2904Af6231C4b15A1";

export const PRIVATE_KEY="ab99d188217a7c70d9c0555e680d8372373eecb4de0b62af14859a5153c58265"

export const explorerLink = "https://hyperspace.filfox.info/en";


export const EB3STORAGE_TOKEN="eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJkaWQ6ZXRocjoweEY2NTI4OUY1MjdhNzRCQzc0MDc4ZUQ3NjQ0NjQ2NzZDOTVEYkFCNjgiLCJpc3MiOiJ3ZWIzLXN0b3JhZ2UiLCJpYXQiOjE2NzYyNzM0NTU4MDMsIm5hbWUiOiJ0ZXN0In0.VsfegLWGopjHCN-Qqj_t_vw7msnt69df6-vkXoVrBGs"
export const IPFS_URL  = `https://w3s.link/ipfs/`;

export const Register_ABI =[
      {
        "inputs": [
          {
            "internalType": "address",
            "name": "_contractAddress",
            "type": "address"
          },
          {
            "internalType": "string",
            "name": "_ipfsURI",
            "type": "string"
          }
        ],
        "name": "addContractRecord",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "address",
            "name": "",
            "type": "address"
          }
        ],
        "name": "contractRecord",
        "outputs": [
          {
            "internalType": "string",
            "name": "",
            "type": "string"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "address",
            "name": "_contractAddress",
            "type": "address"
          }
        ],
        "name": "getContractRecord",
        "outputs": [
          {
            "internalType": "string",
            "name": "_ipfsURI",
            "type": "string"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      }
    ];
