import { ConnectButton } from "@rainbow-me/rainbowkit"

export const ConnectWeb3Button = () => {
    return (
        <div style={{
            // width: isConnected ? "31rem" : "20rem",
            color: "#fff",
            padding: "8px 8px",
            textAlign: "center",
            borderRadius: "8px"
        }} className="connect flex items-center sm:mx-4 ">
            <ConnectButton.Custom >
                {({
                    account,
                    chain,
                    openAccountModal,
                    openChainModal,
                    openConnectModal,
                    authenticationStatus,
                    mounted,
                }) => {
                    // Note: If your app doesn't use authentication, you
                    // can remove all 'authenticationStatus' checks
                    const ready = mounted && authenticationStatus !== 'loading';
                    const connected =
                        ready &&
                        account &&
                        chain &&
                        (!authenticationStatus ||
                            authenticationStatus === 'authenticated');
                    return (
                        <div
                            {...(!ready && {
                                'aria-hidden': true,
                                'style': {
                                    opacity: 0,
                                    pointerEvents: 'none',
                                    userSelect: 'none',
                                },
                            })}
                        >
                            {(() => {
                                if (!connected) {
                                    return (
                                        <div>
                                            <button className="connect-pc connect connect-wallet" onClick={openConnectModal} type="button">
                                                Connect Wallet
                                            </button>
                                            <img className="connect-mob" onClick={openConnectModal} src="/assets/wallet.svg" width={20}/>
                                        </div>

                                    );
                                }
                                if (chain.unsupported) {
                                    return (
                                        <div>
                                            <button className="connect-pc connect connect-warn" onClick={openChainModal} type="button">
                                                Wrong network
                                            </button>
                                            <img className="connect-mob" onClick={openChainModal} src="/assets/wallet.svg" width={20}/>
                                        </div>
                                    );
                                }
                                return (
                                    <div style={{ display: 'flex', gap: 12 }}>
                                        {/* <button
                                            onClick={openChainModal}
                                            style={{ display: 'flex', alignItems: 'center' }}
                                            type="button"
                                        >
                                            {chain.hasIcon && (
                                                <div
                                                    style={{
                                                        background: chain.iconBackground,
                                                        width: 12,
                                                        height: 12,
                                                        borderRadius: 999,
                                                        overflow: 'hidden',
                                                        marginRight: 4,
                                                    }}
                                                >
                                                    {chain.iconUrl && (
                                                        <img
                                                            alt={chain.name ?? 'Chain icon'}
                                                            src={chain.iconUrl}
                                                            style={{ width: 12, height: 12 }}
                                                        />
                                                    )}
                                                </div>
                                            )}
                                            {chain.name}
                                        </button> */}
                                        <div>
                                            <button className="connect-pc connect connect-sucess" onClick={openAccountModal} type="button">
                                
                                                {account.displayName}
                                                {account.displayBalance
                                                    ? ` (${account.displayBalance})`
                                                    : ''}
                                            </button>
                                            <img className="connect-mob" onClick={openAccountModal} src="/assets/wallet.svg" width={20}/>
                                        </div>

                                    </div>
                                );
                            })()}
                        </div>
                    );
                }}
            </ConnectButton.Custom>
        </div>
    )
}

