/**
 * @刘泽明
 * 2022/09/06
 * */
import React, {CSSProperties} from "react";
import {Card, Row} from "antd";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";

/**
 * @description 带标题的公用盒子，避免多人协作开发时padding，border等属性不一致的问题，如需修改只需调整此处组件。如需取消盒子的hover属性，在跳用组件时style传入： boxShadow:'none', border:'none'
 * @param style
 * @param title 盒子标题， 因可能传入标题以外的其他标签，比如选择框等，呈现左右对称布局，所以标题盒子的默认布局是justify-content: "space-between"
 * @param children: 盒子主要内容内容
 * */
const TitleCard: React.FC<{ style?: CSSProperties, className?:string, title?: React.ReactNode, children: React.ReactNode }> =
  ({
     style,
     title,
     className,
     children
   }) => {
    const {lg} = useBreakpoint()
    return (
      <Card className={`${className ||""}`} style={{width: '100%', borderRadius: '8px', fontWeight: 500, ...style}} bodyStyle={{padding: 0,}}>
        {title &&<Row className="title-card" style={{
          padding: lg ? '8px 16px' : '8px',
        }}
             justify={"space-between"} align={"middle"}>
          {title}
        </Row>}
        {children}
      </Card>
    )
  }

export default TitleCard