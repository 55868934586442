/** @format */

import { Pagination } from "antd";
import { TFunction } from "i18next";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { DetailsCard } from "../../../../components/DetailsCard";
import { TimeClock } from "../../../../components/TimeClock";
import { DatumType } from "../../../../model";
import { getRecent } from "../../../../servers/api";
import { transFil } from "../../../../utils/convertUtils";
import DateUtil from "../../../../utils/formatData";

const MobileData = (item: DatumType, t: TFunction) => {
 
  return (
    item.Blocks.map((blocks, index) => {
      return [
        <Link to={`/address/${blocks.miner}`}>
          {blocks.miner}
        </Link>,
        // <div className="table-amount" key={index} >{`${t("block.rewards")}: ${transFil(blocks.reward,2)}`}</div>];
        `${t("last_block.message")}: ${blocks.messageCount}`
      ]
    })
  );
};

const title = (props: any) => {
  const { height, time, t } = props;
  return (
    <div style={{ padding: " 0 16px" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          paddingBottom: "5px",
          borderBottom: "1px solid #e2e8f0",
        }}>
        <div>
          <span style={{ marginRight: "16px" }}>{t("block_list.height")}</span>
          <Link to={`/blocks/${height}`} style={{ color: "#f3921b" }}>
            {height}
          </Link>
        </div>
        <div>
          {time}

        </div>
      </div>
    </div>
  );
};

const BlockList_mobile = () => {
  const { t } = useTranslation();

  const [table, setTable] = useState({
    count: 0,
    page: 1,
    pageSize: 5,
    data: [] as DatumType[],
  });
  const [loading, setLoading] = useState(false);

  function getBlockMessage(pageSize: number, page: number) {
    setLoading(true);
    getRecent({ pageSize, page: page - 1 }).then((r) => {
      setTable({
        page: page,
        pageSize: pageSize,
        data: r.data as DatumType[],
        count: r.count,
      });
      setLoading(false);
    });
  }
  useEffect(() => {
    getBlockMessage(table.pageSize, table.page);
  }, []);
  return (
    <div
      className="BlockList_mobile">
      {table.data && table.data.map((item) => {
        if (item.Blocks) {
          return (
            <DetailsCard
              key={item.height}
              className='item-mb '
              title={title({
                height: item.height,
                // time: <TimeClock timeStamp={item.timestamp} />,
                time: DateUtil.formatDate(item.timestamp, 'yyyy-MM-dd HH:mm:ss'),
                t,
              })}
              data={MobileData(item, t)}
              boxStyle={{
                borderRadius: "10px",
                boxShadow: "0 1px 3px 0 rgb(0 0 0 / 10%)",
              }}
              bodyStyle={{
                display: "flex",
                justifyContent: "space-between",
                margin: 0
              }}
            />
          );
        }
      })}
      <div style={{ marginTop: "10px", display: "flex", justifyContent: "center" }}>
        <Pagination
          size="small"
          total={table.count}
          current={table.page}
          pageSize={table.pageSize}
          showSizeChanger
          showQuickJumper
          onChange={(page, pageSize) => {
            if (page && pageSize) {
              getBlockMessage(pageSize, page);
            }
          }}
        />
      </div>
    </div>
  );
};

export default BlockList_mobile;
