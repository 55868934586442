import {useTranslation} from "react-i18next";
import {useLocation, useNavigate} from "react-router-dom";
import {Menu} from "antd";
import * as React from "react";

export const BlockChainBarMenu =  () =>  {
  const {t} = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()

  return (
    <Menu
      items={[
        {
          label: <div
            className={location.pathname == "/blocks" ? 'css-navbar-drop_down-select ' : 'css-navbar-drop_down'}
            onClick={() => navigate("/blocks")}>{t("navbar.block")}</div>,
          key: "0"
        },
        {
          type: 'divider',
        },
        {
          label: <div
            className={location.pathname == "/messages" ? 'css-navbar-drop_down-select ' : 'css-navbar-drop_down'}
            onClick={() => navigate("/messages")}> {t("navbar.message")}</div>,
          key: "1"
        },
        {
          type: 'divider',
        },
        // {
        //   label: <div
        //     className={location.pathname == "/rich-list" ? 'css-navbar-drop_down-select ' : 'css-navbar-drop_down'}
        //     onClick={() => navigate("/rich-list")}> {t("navbar.rich_list")}</div>,
        //   key: "2"
        // },
        // {
        //   type: 'divider',
        // },
        // {
        //   label: <div
        //     className={location.pathname == "/deal" ? 'css-navbar-drop_down-select ' : 'css-navbar-drop_down'}
        //     onClick={() => navigate("/deal")}> {t("navbar.deal")}</div>,
        //   key: "3"
        // },
        // {
        //   type: 'divider',
        // },
        {
          label: <div
            className={location.pathname == "/contract-list" ? 'css-navbar-drop_down-select ' : 'css-navbar-drop_down'}
            onClick={() => navigate("/contract-list")}> {t("navbar.contract-list")}</div>,
          key: "4"
        },
      ]}
    />
  )
}

