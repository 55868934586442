import {Contract, ethers, Wallet} from "ethers";
import {IPFS_URL, PRIVATE_KEY, Register_ABI, Register_address} from "../../constant/constants";
import {ContractData} from "../../model";
import {storeContract} from "../../functionality/storeData";
import {message} from "antd";
import {Provider} from "@ethersproject/abstract-provider";
import {Notify} from "../../components/Notify";

const ContractMethods = {
    /**
     * 添加上链
     * @param contractAddress
     * @param ipfsUrl
     */
    async addContractRecord(contractAddress: string, ipfsUrl: string, provider: Provider) {
        try {
            console.log("addContractRecord")
            const manager_wallet = new Wallet(PRIVATE_KEY, provider); // 管理钱包（验证消息的钱包）
            const register_contract = new Contract(
                Register_address,
                Register_ABI,
                manager_wallet
            );
            const tx = await register_contract.addContractRecord(
                contractAddress,
                ipfsUrl
            );
            await tx.wait();
            return true
        } catch (e) {
            return false
        }

    },
    /**
     * 上传到Ipfs保存合约信息
     */
    async uploadDataToIpfs(data: ContractData) {
        try {
            const CID = await storeContract(data);
            return IPFS_URL + CID
        } catch (e) {
            return null
        }
    }
}
export default ContractMethods