import TitleCard from "../../../../components/TitleCard";
import {Route, Routes} from "react-router-dom";
import {First} from "../Docs/First";
import {Second} from "../Docs/Second";
import {Three} from "../Docs/Three";
import React from "react";
import styled from "styled-components";

const Main=styled.main` a {color:#ccc; &:hover{color:#57949c}} `

export const View:React.FC = () => {
    return (
        <TitleCard title={""}>
            {/**@显示文章内容*/}
            <Main style={{padding: "24px"}}>
                <Routes>
                    <Route index element={<First/>}/>
                    <Route path="1" element={<First/>}/>
                    <Route path='2' element={<Second/>}/>
                    <Route path='3' element={<Three/>}/>
                </Routes>
            </Main>
        </TitleCard>
    )
}