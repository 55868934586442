

import { MenuBean } from "../../../model";
import { Dropdown, Menu, Space } from "antd";
import { useTranslation } from "react-i18next";

import { BlockChainBarMenu } from "./SubMenu/BlockChainBarMenu";

import { DownOutlined } from "@ant-design/icons";


export const MenuList = function () {
  const { t } = useTranslation()

  return (
    [
      {
        id: 0,
        element: <span>
          {t("navbar.home")}
        </span>,
        pathList: ["/"],
      },
      {
        id: 1,
        element: <Dropdown className={'css-navbar-drop-down'}
          overlay={<BlockChainBarMenu />} arrow autoFocus={true}>
          <span onClick={e => e.preventDefault()}>
            <Space>
              {t("navbar.block_chain")}
              <DownOutlined width={16} style={{ fontSize: '12px', }} />
            </Space>
          </span>
        </Dropdown>,
        pathList: [
          "/blocks",
          "/messages",
          // "/rich-list",
          // "/deal",
          "/contract-list"]
      },
      {
        id: 2,
        element: <span>
          {t("navbar.top_miner")}
        </span>,
        pathList: ["/ranks/power"],
      },
      {
        id: 3,
        element: <span>
          Language
        </span>,
        pathList: ["zh", "en"],
      },
      {
        id: 4,
        element: <span>
          NetWork
        </span>,
        pathList: ["mainnet", "hyperspace"],
      }
      // {
      //   id: 3,
      //   element: <Dropdown className={'css-navbar-drop-down'}
      //                      overlay={<StatsBarMenu/>} arrow autoFocus={true}>
      //     <span onClick={e => e.preventDefault()}>
      //       <Space>
      //         {t("navbar.stats")}
      //         <DownOutlined width={16} style={{fontSize: '12px', }}/>
      //       </Space>
      //     </span>
      //   </Dropdown>,
      //   pathList: ["/miner", "/gas"]
      // },
      // TODO: 暂时隐藏
      // {
      //   id: 4,
      //   element:
      //     <Dropdown className={'css-navbar-drop-down'}
      //               overlay={<ResourceBarMenu/>} arrow autoFocus={true}>
      //     <span onClick={e => e.preventDefault()}>
      //       <Space>
      //         {t("navbar.resource")}
      //         <DownOutlined width={16} style={{fontSize: '12px',}}/>
      //       </Space>
      //     </span>
      //     </Dropdown>,

      //   pathList: ["/tools", "/docs"]
      // },
    ] as MenuBean[]
  )
}
