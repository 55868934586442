import {Link} from "react-router-dom";
import {clientWidth} from "../shared/clientWidth";
import {px} from "../shared/px";

export default function NoMatch() {
    return (
      <div style={{textAlign: "center", marginTop: '156px'}}>
          {clientWidth() ? <img src={'/assets/404.svg'} width={600} height={218} alt={"Page Not Found"}/>:
              <img src={'/assets/404.svg'} width={px(1800)} height={218} alt={"Page Not Found"}/>}
        <div style={{padding:'12px'}}>
          页面显示出现错误， 请<span style={{color:'#108cee'}} onClick={() => {
          window.location.reload()
        }}>刷新</span>当前页面
        </div>
        <Link to={'/'}>返回首页</Link>
      </div>
    );
}