/** @format */


import { SearchType } from "../../../model";
import AccountList from "./AccountList/AccountList";
import Froma from "./Froma/Froma";

interface Props {
  accountType?: string
  addrData?: SearchType
}

const OtherAccount = (props: Props) => {
  const { accountType, addrData } = props

  return (
    <div>

      <Froma propsType={accountType} addrData={addrData}/>
      {/* <TitleCarda /> */}
      <AccountList />
    </div>
  );
};

export default OtherAccount;
