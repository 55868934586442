/**
 * TODO 封装接口调用方法
 *
 * @format
 */

import {
  AddressMessage,
  BASEFEE,
  BLOCKS,
  COMPILE,
  CONTRACT_LIST,
  CONTRACT_UPDATE,
  ContractAddr,
  DEALDETAIL,
  DEALLIST,
  ETHCODE,
  FILRICHLIST,
  FILT,
  HEIGHTDETAIL,
  LAST_BLOCK,
  LIST,
  MEMPOOL,
  MESSAGEFEE,
  MESSAGES,
  SEARCH_BAR,
  STATSPLEDGE,
  STATSPOWERALLNETWORK,
  STATSPROVIDERS,
  TOPMINERBLOCKS,
  TOPMINERGROWTH,
  TOPMINERPOWER,
  TOPMINERPOWERLIST,
  MINERSECTOR,
  INCOME_DELAY,
  ALLMINERSECTOR,
  ALLMINERSECTORONEDAY,
} from "../constant/Global";
import { request } from "../utils/request";
import { Api } from "./typing";

export async function getRecent(params: {}): Promise<Api.TipSetResp> {
  const res = await request(LIST, "GET", params);
  return res.data;
}

export async function getBlocks(params: any): Promise<any> {
  const res = await request(BLOCKS + `/${params}`, "GET", {});
  return res.data;
}

// 查询区块详情消息
export async function getBlocksMsg(cid: string, options?: { [key: string]: any }): Promise<Api.BlocksMsgResp> {
  const res = await request(`${BLOCKS}/${cid}/messages`, "GET", options);
  return res.data;
}

// url中间字段需要拼接,直接传入
export async function getStats(url: any, params?: {}): Promise<Api.PublicResp> {
  const res = await request(url, "GET", params);
  return res.data;
}

export async function getTopMiners(
  data: { page?: number; pageSize?: number; count?: number; duration?: string },
  rinkType: "power" | "block" | "growth"
): Promise<Api.BaseRespByObj> {
  let urlSuffix = TOPMINERPOWER;
  if (data.pageSize !== undefined) {
    urlSuffix = TOPMINERPOWERLIST;
  }
  switch (rinkType) {
    case "block":
      urlSuffix = TOPMINERBLOCKS;
      break;
    case "growth":
      urlSuffix = TOPMINERGROWTH;
      break;
  }

  const res = await request(urlSuffix, "GET", data);
  return res.data;
}

export async function getMessages(options?: { [key: string]: any }): Promise<Api.PublicResp> {
  const res = await request(MESSAGES, "GET", options);
  return res.data;
}

export async function getMessagesInfo(cid: string, options?: { [key: string]: any }): Promise<Api.PublicResp> {
  const res = await request(MESSAGES + "/" + cid, "GET", options);
  return res.data;
}

// Gas 统计页面图表
export async function getEchartSatsFee(options?: { [key: string]: any }) {
  const res = await request(BASEFEE, "GET");
  return res.data;
}

// Gas 统计页面表格
export async function getMessageFee(options?: { [key: string]: any }) {
  const res = await request(MESSAGEFEE, "GET", options);
  return res.data;
}

// 全网算力走势
export async function getAllWorldComputedTrend(options?: { [key: string]: any }) {
  const res = await request(STATSPOWERALLNETWORK, "GET");
  return res.data;
}

// 全网算力走势
export async function getStorageProvider(options?: { [key: string]: any }) {
  const res = await request(STATSPROVIDERS, "GET");
  return res.data;
}

// FIL/32G
export async function getStoragePledge(options?: { [key: string]: any }) {
  const res = await request(STATSPLEDGE, "GET");
  return res.data;
}

// FIL/T
export async function getFILT(options?: { [key: string]: any }) {
  const res = await request(FILT, "GET");
  return res.data;
}

// 富豪榜
export async function getFilrich(params?: {}): Promise<Api.BaseRespByObj> {
  const res = await request(FILRICHLIST, "GET", params);
  return res.data;
}

// 订单列表
export async function getDealList(options?: { [key: string]: any }) {
  const { data } = await request(DEALLIST, "GET", options);
  return data;
}

// 内存池
export async function getMempoolList(options?: { [key: string]: any }) {
  const { data } = await request(MEMPOOL, "GET", options);
  return data;
}

// 订单详情
export async function getDealDetail(options: { [key: string]: any }) {
  const { data } = await request(DEALDETAIL + options.id, "GET");
  return data;
}

//高度详情
export async function getHeightDetail(options?: { [key: string]: any }): Promise<Api.PublicResp> {
  const res = await request(HEIGHTDETAIL + options?.["height"], "GET", {});
  return res.data;
}

//最新高度列表
export async function getHeightList(options?: { [key: string]: any }): Promise<Api.PublicResp> {
  const res = await request(LAST_BLOCK, "GET", options);
  return res.data;
}
export async function getIncomeData(options?: { [key: string]: any }): Promise<Api.BaseRespByArray> {
  const res = await request(INCOME_DELAY, "GET", options);
  return res.data;
}
//存储者概览中消息列表
export async function getAddressList(address: any, options?: {}): Promise<Api.TipSetRespAddressList> {
  const res = await request(`${AddressMessage}${address}/messages`, "GET", options);
  return res.data;
}

// 查询账户接口
export async function getAddressMessage(address: any, type: string): Promise<Api.BaseRespByObj> {
  const res = await request(`${AddressMessage}${address}?type=${type}`, "GET");
  return res.data;
}
// 存储提供者
export async function getMinerSectors(minerId?: string): Promise<Api.ResponseMinerSector> {
  const res = await request(MINERSECTOR + minerId, "GET");
  return res.data;
}
// 所有矿工 算力释放图谱
export async function getAllMinersSector(options?: { [key: string]: any }): Promise<Api.ResponseMinerSector> {
  const res = await request(ALLMINERSECTOR, "GET", options);
  return res.data;
}

export async function getAllMinersOneDaySector(params: { date: number }): Promise<Api.ResponseMinerSector> {
  const res = await request(ALLMINERSECTORONEDAY, "GET", params);
  return res.data;
}

export async function getSearchIsExist(content: string): Promise<Api.SearchResp> {
  const res = await request(SEARCH_BAR + content, "GET");
  return res.data;
}

/**
 * 根据源码编译bytecode
 * @param sourceCode 编译源码
 * @param buildVersion 编译版本
 */
export async function fetchCompile(sourceCode: string, buildVersion: string): Promise<Api.CompileResp> {
  const req = await fetch(COMPILE, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ sourceCode, buildVersion }),
  });
  return req.json();
}

/**
 * 查询合约的byteCode
 * @param address 合约地址   latest 最新状态
 * return {
 *     "jsonrpc": "2.0",
 *     "result": "0x6080604052348015600f57600080fd5b506004361060325760003560e01c806329e99f071460375780638381f58a14604f575b600080fd5b604d60048036038101906049919060af565b6069565b005b60556073565b6040516060919060e4565b60405180910390f35b8060008190555050565b60005481565b600080fd5b6000819050919050565b608f81607e565b8114609957600080fd5b50565b60008135905060a9816088565b92915050565b60006020828403121560c25760c16079565b5b600060ce84828501609c565b91505092915050565b60de81607e565b82525050565b600060208201905060f7600083018460d7565b9291505056fea26469706673582212203bf7d47d7d17b394186dc397792361dfe8c4a4acfad8c2f7ffbf910d71b090cb64736f6c63430008120033",
 *     "id": 42
 * }
 * 如果返回的result 只有0x 或者接口异常则是没有查到
 * 参考：https://docs.infura.io/infura/networks/ethereum/json-rpc-methods/eth_getcode
 */
export async function getContractCode(address: string) {
  const res = await request(ETHCODE + address + "?value=latest", "GET");
  return res.data;
}

/**
 * 保存合约信息
 * @param contractAddress 合约地址
 * @param ipfsUrl ipfs地址
 */
export async function saveContractData(contractAddress: string, ipfsUrl: string) {
  const res = await request(CONTRACT_UPDATE + `?address=${contractAddress}&ipfs=${ipfsUrl}`, "PATCH");
  return res.data;
}

// 合约地址详情数据查询
export async function getContractData(address: string | undefined): Promise<Api.TipSetResp> {
  const res = await request(ContractAddr + address, "GET");
  return res.data;
}

// 合约地址详情数据查询
export async function getContractList(options: {}): Promise<Api.TipSetResp> {
  const res = await request(CONTRACT_LIST, "GET", options);
  return res.data;
}

export const TiosetsList = async (address: string | undefined, options: {}) => {
  const res = await request(`/api/v1/fil/address/${address}/blocks`, "GET", options);
  return res.data;
};
