/**
 * @format
 * @Author: liuzeming1 liuzeming@storswift.com
 * @Date: 2022-10-08 12:05:52
 * @LastEditors: liuzeming1 liuzeming@storswift.com
 * @LastEditTime: 2022-10-19 21:57:17
 * @FilePath: /filecoin/src/constant/Global.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */

// export const BASE_URL = `${process.env.REACT_APP_BASE_URL}`;
// export const Hyper_Space_WS_URL = `${process.env.REACT_APP_WS_URL}`;
// export const Mainnet_WS_URL = `${process.env.REACT_APP_Mainnet_WS_URL}`

const isTest = process.env.REACT_APP_ENV === "test"

export const BASE_URL = ``;
export const Hyper_Space_WS_URL = isTest ? `${process.env.REACT_APP_WS_URL}` : `wss://${window.location.host}/api/v1/fil/websocket`;
export const Mainnet_WS_URL = isTest ? `${process.env.REACT_APP_Mainnet_WS_URL}` : `wss://${window.location.host}/api/v1/fil/websocket/mainnet`

/**
 * 订阅出块
 */
export const TopicAddBlock = "/api/v1/fil/websocket";

// 区块列表
export const LIST = "/api/v1/fil/tipset/list";
// 搜索区块详情
export const BLOCKS = "/api/v1/fil/blocks";
// 每日T收益数据
export const INCOME_DELAY = "/api/v1/fil/income/list"
// 有效算力排行榜排行榜
export const TOPMINERPOWER = "/api/v1/fil/miner/top-miners/power";
// 有效算力排行榜排行榜列表
export const TOPMINERPOWERLIST = "/api/v1/fil/miner/list/power";
// 出块数排行榜
export const TOPMINERBLOCKS = "/api/v1/fil/miner/top-miners/blocks";
// 算力增数排行榜
export const TOPMINERGROWTH = "/api/v1/fil/miner/top-miners/power-growth";
// 富豪榜
export const FILRICHLIST = "/api/v1/fil/rich-list";
// 消息
export const MESSAGES = "/api/v1/fil/messages";
export const MEMPOOL = "/api/v1/fil/message/mempool/list";

// Echarts Data Gas
export const BASEFEE = "stats/base-fee";
export const MESSAGEFEE = "message/fee";

// 存储服务图表
export const STATSPOWERALLNETWORK = "api/v1/stats/power";
export const STATSPROVIDERS = "api/v1/stats/miner/power-growth";
export const STATSPLEDGE = "api/v1/stats/miner/sector-initial-pledge";

// FIL/T
export const FILT = "api/v1/stats/miner/reward-per-byte";
// 订单
export const DEALLIST = "/api/v1/fil/deals/list";
export const DEALDETAIL = "/api/v1/fil/deals/";

//高度
export const HEIGHTDETAIL = "/api/v1/fil/tipset/";

//最新区块列表
export const LAST_BLOCK = "/api/v1/fil/tipset/recent";

//查询单个合约
export const ContractAddr = "/api/v1/fil/contracts/";
//查询账户
export const AddressMessage = "/api/v1/fil/address/";

//查询搜索栏输入内容是否存在
export const SEARCH_BAR = "/api/v1/fil/address/search/type/";

// 合约列表

export const CONTRACT_LIST = "/api/v1/fil/contracts/list";

export const COMPILE = BASE_URL + "/solc/compile";

export const ETHCODE = BASE_URL + "/api/v1/fil/contracts/ethcode/";

/**
 * 存储合约信息
 */
export const CONTRACT_UPDATE = BASE_URL + "/api/v1/fil/contracts/update";


// 存储提供者算力释放数据
export const MINERSECTOR = "/api/v1/fil/miner/minersector/"

export const ALLMINERSECTOR = "/api/v1/fil/miner/minersector"

export const ALLMINERSECTORONEDAY = "/api/v1/fil/miner/minersector/date"