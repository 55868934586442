import * as React from "react";
import { Alert, Layout } from 'antd';
import "./navbar.less"

import { MenuList } from "./Menu/MenuList";
import { MobileNavbar } from "./Implement/MobileNavbar";
import { PcNavbar } from "./Implement/PcNavbar";

const { Header } = Layout;
const Navbar = () => {

    return (
        <>
            {/* 判断显示器像素宽度大小，小于500时呈现移动端布局方案*/}
            <Header className="navbar">

                <PcNavbar />
                <MobileNavbar menus={MenuList()} />
         
            </Header>
            
        </>
    )
}
export default Navbar