/** @format */

import { CSSProperties, useEffect, useState } from "react";
import "../../../../components/BlockList/index.less";
import "../index.less";
import { Col, Row, Select } from "antd";

import { getBlocksMsg, getMessages } from "../../../../servers/api";

import { DetailsCard } from "../../../../components/DetailsCard";
import EllipsisMiddle from "../../../../components/EllipsisMiddle";
import Tabulation from "../../../../components/Tabulation";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import DateUtil from "../../../../utils/formatData";
import { ColumnsType } from "antd/es/table";
import { DecimalProcessing, transFil } from "../../../../utils/convertUtils";
import { BlockMessage, BlockMessageData, MessageData } from "../../../../servers/respType";
import styled from "styled-components";
import { Mobile, PC } from "../../../../shared/pc";
import TitleCard from "../../../../components/TitleCard";
import { MsgMobile } from "./Msg_MobileData";
import { log } from "console";


const MsgConf = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  return [


    {
      title: `${t('navbar.message_id')}`,
      key: 'messageId',
      dataIndex: 'messageId',
      align: 'center',
      render: (messageId) => {
        return (
          <Link to={`/messages/${messageId}`}  >
            <EllipsisMiddle suffixCount={7}>{messageId}</EllipsisMiddle>
          </Link>
        );
      },
    },
    {
      title: `${t('home_target.block_height')}`,
      key: 'height',
      dataIndex: 'Height',
      align: 'center',
      render: (height: number) => {
        return <Link style={{ color: "#108cee" }} to={`/blocks/${height}`}>{height}</Link>
      }
    },
    {
      title: `${t('block_list.time')}`,
      key: 'Timestamp',
      dataIndex: 'TimeStamp',
      width: 208,
      align: 'center',
      render: (timestamp: number) => {
        return DateUtil.formatDate(timestamp, "yyyy-MM-dd HH:mm:ss")
      }
    },
    {
      title: `${t('block_list.from')}`,
      key: 'From',
      dataIndex: 'From',
      align: 'center',
      render: (from: string) => {
        return <Link to={`/address/${from}`}>
          {from.length > 10 ? <EllipsisMiddle suffixCount={7}>{from}</EllipsisMiddle> : from}
        </Link>
      }
    },
    {
      title: `${t('block_list.to')}`,
      key: 'To',
      dataIndex: 'To',
      align: 'center',
      render: (to: string) => {
        return <Link to={`/address/${to}`}>
          {to.length > 10 ? <EllipsisMiddle suffixCount={7}>{to}</EllipsisMiddle> : to}
        </Link>
      }
    },
    {
      title: `${t('block_list.method')}`,
      key: 'MethodName',
      dataIndex: 'MethodName',
      align: 'center',
      render: (MethodName) => {
        return <span>{MethodName}</span>
      }
    },
    {
      title: `${t('block_list.value')}`,
      key: 'Value',
      dataIndex: 'Value',
      align: 'center',
      render: (Value) => {
        let text = Number(Value) / 10 ** 18
        return <span>{DecimalProcessing(text) > 1 ? text.toFixed(4) + " FIL" : text + " FIL"}</span>
      }
    },
    // {
    //   title: `${t('block_list.status')}`,
    //   key: 'Status',
    //   dataIndex: 'Status',
    //   align: 'center',
    //   render: (item, record) => {
    //     if (record.ErrorMessage != "") {
    //       return record.ErrorMessage
    //     }
    //     let i = item.replace('(0)', '')
    //     return i
    //   }
    // },

  ] as ColumnsType<any>
}

const BlockMsgConf = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  return [
    {
      title: `${t('navbar.message_id')}`,
      key: 'MessageId',
      dataIndex: 'MessageId',
      align: 'center',
      render: (MessageId) => {
        return (
          <Link to={`/messages/${MessageId}`}  >
            <EllipsisMiddle suffixCount={7}>{MessageId}</EllipsisMiddle>
          </Link>
        );
      },
    },
    {
      title: `${t('block_list.from')}`,
      key: 'From',
      dataIndex: 'From',
      align: 'center',
      render: (from: string) => {
        return <Link to={`/address/${from}`}>
          {from.length > 10 ? <EllipsisMiddle suffixCount={7}>{from}</EllipsisMiddle> : from}
        </Link>
      }
    },
    {
      title: `${t('block_list.to')}`,
      key: 'To',
      dataIndex: 'To',
      align: 'center',
      render: (to: string) => {
        return <Link to={`/address/${to}`}>
          {to.length > 10 ? <EllipsisMiddle suffixCount={7}>{to}</EllipsisMiddle> : to}
        </Link>
      }
    },
    {
      title: `${t('block_list.method')}`,
      key: 'MethodName',
      dataIndex: 'MethodName',
      align: 'center',
      render: (MethodName) => {
        return <span>{MethodName}</span>
      }
    },
    {
      title: `${t('block_list.value')}`,
      key: 'Value',
      dataIndex: 'Value',
      align: 'center',
      render: (Value) => {
        let text = Number(Value) / 10 ** 18
        return <span>{DecimalProcessing(text) > 1 ? text.toFixed(4) + " FIL" : text + " FIL"}</span>
      }
    },
    // {
    //   title: `${t('block_list.status')}`,
    //   key: 'Status',
    //   dataIndex: 'Status',
    //   align: 'center',
    //   render: (item, record) => {
    //     if (record.ErrorMessage != "") {
    //       return record.ErrorMessage
    //     }
    //     let i = item.replace('(0)', '')
    //     return i
    //   }
    // },
  ] as ColumnsType<any>
}

interface MessageProps {
  isMsg?: boolean
  cid?: string
  style?: CSSProperties | undefined;
}

// 一级菜单下的消息列表

export const MessageList = (props: MessageProps) => {
  const { t } = useTranslation();
  const { isMsg, cid, style } = props
  const [MsgMethodMapList, setMsgMethodMapList] = useState([{}]);
  const [msgData, setData] = useState({
    count: 0 as number,
    page: 1,
    pageSize: 20,
    data: !isMsg && cid ? [] as BlockMessage[] : [] as MessageData[]
  })



  const [isLoading, setLoading] = useState(false)
  const [type, setType] = useState('ALL')

  const FetchMessages = (page: number, pageSize: number, method?: string) => {
    setLoading(true)
    if (isMsg && !cid) {
      getMessages({ page: page - 1, pageSize, method: method }).then((res) => {
        const methodsType = [{ 'value': "ALL", label: "ALL" }]
        res.method_name && res.method_name.map((item: string) => {
          if (item && item != "") {
            methodsType.push({ value: item, label: item })
          }
        })
        setMsgMethodMapList(methodsType)

        const data = res.data as MessageData[]
        if (data && data.length > 0) {
          setData({
            page: page,
            count: res.count as number,
            pageSize: pageSize,
            data: res.data as MessageData[]
          })
        } else {
          setData({
            ...msgData,
            data: [] as MessageData[],
          })
        }
        setLoading(false)
      })
    } else {
      getBlocksMsg(cid || "", { page: page - 1, pageSize, method: method }).then((res) => {
        const d = res.data as BlockMessage[]
        setData({
          page: page,
          count: res.count,
          pageSize: pageSize,
          data: d
        })
        const methodsType = [{ 'value': "ALL", label: "ALL" }]

        res.method_name && res.method_name.map((item: string) => {
          if (item && item != "") {
            methodsType.push({ value: item, label: item })
          }
        })
        setMsgMethodMapList(methodsType)
        setLoading(false)
      })
    }
  }


  useEffect(() => {
    FetchMessages(msgData.page, msgData.pageSize)
  }, [cid])



  const handleChange = (val: string) => {
    if (val == 'ALL') {
      setType('ALL')
      FetchMessages(msgData.page, msgData.pageSize)
    } else {
      setType(val)
      FetchMessages(msgData.page, msgData.pageSize, val)
    }
  };


  return (
    <div style={{ ...style }}>
      <TitleCard className="box msg-pc" title={
        <div className="msg-top-card msg-count-top">
          <div className="title-card" style={{ borderBottom: "none" }}>{isMsg ? '' : t('list.Message_List')}</div>
          <div style={{ fontSize: "14px", display: "flex", justifyContent: "space-between" }}>
            <div>{t('address_details.Total')} <span style={{ color: " #f3921b" }}> {msgData.count} </span>{t('last_block.message')} </div>
            <Select
              defaultValue={"ALL"}
              style={{ width: 220, color: "#000" }}
              onChange={handleChange}
              options={MsgMethodMapList} />
          </div>
        </div>
      }>


        <Tabulation
          tableDataTypeConfig={!cid && isMsg ? MsgConf : BlockMsgConf}
          loading={isLoading}
          onChange={(pageSize, page) => {
            if (page && pageSize) {
              type == 'ALL' ? FetchMessages(page, pageSize) : FetchMessages(page, pageSize, type)
            }
          }}
          dataConf={msgData} rowKey={"MessageId"} />
      </TitleCard>



      <TitleCard className="msg-mobile" title={isMsg ? '' : t('list.Message_List')}>
        <Col className="msg-top-card msg-count-top" > Total <span>{msgData.count}</span> Messages </Col>
        {!cid && isMsg ? <MsgMobile data={msgData.data as MessageData[]} /> : <></>}
      </TitleCard>

    </div>
  );
};

