/** @format */

import { ColumnsType } from "rc-table/lib/interface";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import EllipsisMiddle from "../../../../../components/EllipsisMiddle";
import { TextLengthElls } from "../../../../../utils/adaptiveTextLength";
import { DecimalProcessing } from "../../../../../utils/convertUtils";
import DateUtil from "../../../../../utils/formatData";
/*
*消息列表 内部交易 代币转换(ERC-20) 事件 数据
*
*/
export const MissageList = () => { // 消息列表
  const { t } = useTranslation();
  const navigate = useNavigate();


  return [
    {
      title: t("navbar.message_id"),
      dataIndex: "messageId",
      key: "messageId",
      width:178,
      align: "center",
      render: (messageId) => {
        return (
          <Link to={`/messages/${messageId}`}  >
           <TextLengthElls content={messageId} isElls/>
          </Link>
        );
      },
    },
    {
      title: t("home_target.block_height"),
      dataIndex: "Height",
      width: 168,
      align: "center",
      key: "Height",
      render: (item) => {
        return <Link to={`/blocks/${item}`}>{item}</Link>;
      },
    },
    {
      title: t("block_list.time"),
      dataIndex: "TimeStamp",
      key: "sda",
      width: 256,
      align: "center",
      render: (item) => {
        return <div>{DateUtil.formatDate(item, "yyyy-MM-dd HH:mm:ss")}</div>;
      },
    },
    {
      title: t("block_list.from"),
      key: "From",
      width:178,
      dataIndex: "From",
      align: "center",
      render: (from: string) => {
        return <Link to={`/address/${from}`}>
         <TextLengthElls content={from} isElls/>
        </Link>
      }
    },
    {
      title: t("block_list.to"),
      key: "To",
      align: "center",
      width:178,
      dataIndex: "To",
      render: (to: string) => {
        return <Link to={`/address/${to}`}>
           <TextLengthElls content={to} isElls/>
        </Link>
      }
    },
    {
      title: t("block_list.method"),
      key: "MethodName",
      align: "center",
      width: 168,
      dataIndex: "MethodName",
      render: (MethodName) => {
        return <span>{MethodName}</span>
      }
    },
    {
      title: t("block_list.value"),
      align: "center",
      key: "Value",
      dataIndex: "Value",
      render: (Value) => {
        let text = Number(Value) / 10 ** 18
        return <span>{DecimalProcessing(text) > 1 ? text.toFixed(4) + " FIL" : text + " FIL"}</span>
      }
    },
    {
      title: t("block_list.status"),
      align: "center",
      key: "Status",
      width: 150,
      dataIndex: "Status",
      render: (item) => {
        return item!=""? item : "N / A"
      },
    },
  ] as ColumnsType;


};


export const InsiderTrading = () => { // 内部交易
  const { t } = useTranslation();
  const navigate = useNavigate();


  return [
    {
      title: t("block_list.height"),
      dataIndex: "height",
      key: "height",
      width: 128,
      align: "center",
      render: (height: number) => <div>1</div>,
    },
  ] as any;


}