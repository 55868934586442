import { useTranslation } from "react-i18next";
import {MessageList} from "./components/MessageList";


export const Message=()=>{
  const { t } = useTranslation();
  return(
    <>
      <div style={{fontWeight:500, marginBottom:'1rem', fontSize:'18px'}}>{t('list.Message_List')}</div>
      <MessageList isMsg/>
    </>
  )
}