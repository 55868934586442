import { Col, Row } from 'antd';
import React from 'react'
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ConnectWeb3Button } from '../../../../components/Navbar/Menu/ConnectWeb3Button';
import TitleCard from '../../../../components/TitleCard';
import { ContractsOverviewData } from '../../../../model';
import DateUtil from '../../../../utils/formatData';

interface Props {
    ContractsList1: ContractsOverviewData
    loading: boolean
}

const ContractsOverview = (props: Props) => {
    const { ContractsList1, loading } = props
    const { t } = useTranslation();
    return (
        <TitleCard className='cont-title' title={
            <Row justify='space-between'>
                <Col>{t("Contract.overview")}</Col>
                <ConnectWeb3Button />
            </Row>
        }>
            <div className='contractDiv'><p>{t("RichPage.address")}</p><span>{ContractsList1.Address}</span></div>
            <div className='contractDiv'><p>{t("RichPage.eth_addr")}</p>{ContractsList1.EthAddress}</div>
            <div className='contractDiv'><p>ID</p>f0{ContractsList1.ActorID}</div>
            <div className='contractDiv'><p>{t("RichPage.Actor")}</p><span>{ContractsList1.Actor}</span></div>
            <div className='contractDiv'><p>{t("RichPage.balance")}</p><span>{Number(ContractsList1.Balance) / 10 ** 18 + " FIL"}</span></div>
            {/* <div className='contractDiv'><p>{t("余额")}</p><span>{transformDecimal(Number(ContractsList1.Balance),4) + " FIL"}</span></div> */}
            <div className='contractDiv'><p>{t("Contract.message_count")}</p><span>{String(ContractsList1.MessageCount)}</span></div>
            <div className='contractDiv'><p>{t("RichPage.creation_time")}</p><span>{DateUtil.formatDate(ContractsList1.CreationTime, "yyyy-MM-dd HH:mm:ss")}</span></div>
            <div className='contractDiv'><p>{t("Contract.latest_txn")}</p><span>{DateUtil.formatDate(ContractsList1.LatestTime, "yyyy-MM-dd HH:mm:ss")}</span></div>
            <div className='contractDiv'><p>{t("Contract.creator")}</p><Link to={`/address/${ContractsList1.Creator}`}>{ContractsList1.Creator}</Link></div>
        </TitleCard>

    );

}

export default ContractsOverview