import {Col, Row, Tooltip} from "antd";
import {QuestionCircleTwoTone} from "@ant-design/icons";
import * as React from "react";
import "./componets.less"

interface TipsProps {
  title: string
  tips: string
}
export const TitleTips = (props: TipsProps) => {
  const {tips, title} = props
  return (
    <div className={'tip_icon'} >
      <span style={{width:'auto'}}>{title}</span>
        <Tooltip
          title={tips}
          color={"cyan"}
          overlayInnerStyle={{borderRadius: "8px", padding: "8px 16px"}}
          style={{width:'auto'}}
        >
          <QuestionCircleTwoTone twoToneColor={"#28b2a2"} style={{width:'auto', marginLeft:'4px'}}/>
        </Tooltip>
    </div>
  )
}

