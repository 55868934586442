import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { getDealList } from "../../../servers/api";
import type { ColumnsType } from "antd/es/table";
import TitleCard from "../../../components/TitleCard";
import Tabulation from "../../../components/Tabulation";
import { Row, Col, Input, Tooltip, Grid } from "antd";
import { useNavigate } from "react-router-dom";
import formatData from "../../../utils/formatData";
import { changeSizeUnit } from "../../../utils/convertUtils";
import EllipsisMiddle from "../../../components/EllipsisMiddle";
import { DetailsCard } from "../../../components/DetailsCard";
import "./index.less";
import Item from "antd/lib/list/Item";

const { useBreakpoint } = Grid;
const { Search } = Input;
const {formatDate} = formatData;

/**
 * @周雨芹  9/21
 * {页面}
 * {接口}
 * */

interface DataType {
  key: string;
  id: number;
  timestamp: number;
  client: string;
  provider: string;
  pieceSize: number;
  verifiedDeal: boolean;
  fee: string;
  status: string;
}

const TableConfig = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return [
    {
      title: `${t("dealList.dealID")}`,
      key: "deal_id",
      dataIndex: "deal_id",
      align: "center",
      render: (text) => {
        return (
          <>
            <span
              className="idName"
              onClick={() => navigate(`/deal/${text}`)}
            >
              {text}
            </span>
            <Tooltip
              title={t("dealList.IdTitle")}
              color="#fff"
              overlayInnerStyle={{ color: "#000" }}
              overlayStyle={{ width: "170px" }}
            >
              <i
                style={{ color: "gold", cursor: "pointer" }}
                className="iconfont icon-verified verified"
              ></i>
            </Tooltip>
          </>
        );
      },
    },
    {
      title: `${t("dealList.create_time")}`,
      key: "create_time",
      dataIndex: "create_time",
      align: "center",
      render: (time) => {
        return (
          <span>{formatDate(time, "yyyy-MM-dd HH:mm:ss")}</span>
        );
      },
    },
    {
      title: `${t("dealList.custom")}`,
      key: "client_id",
      dataIndex: "client_id",
      align: "center",
      render: (text) => {
        return (
          <span className="idName" onClick={()=>{navigate(`/address/${text}`)}}>
            <EllipsisMiddle suffixCount={8} children={text} />
          </span>
        );
      },
    },
    {
      title: `${t("dealList.custodian")}`,
      key: "provider_id",
      dataIndex: "provider_id",
      render: (text) => {
        return (
          <span
            className="idName"
            onClick={() => {
              navigate(`/address/${text}`);
            }}
          >
            {text}
          </span>
        );
      },
    },
    {
      title: `${t("dealList.file_size")}`,
      key: "padded_piece_size",
      dataIndex: "padded_piece_size",
      align: "center",
      render: (text) => {
        return <span>{changeSizeUnit(text, "GiB")}</span>;
      },
    },
    {
      title: `${t("dealList.Verified")}`,
      key: "verifiedDeal",
      dataIndex: "verifiedDeal",
      align: "center",
      render: (text) => {
        return <>{text ? "true" : "false"}</>;
      },
    },
    {
      title: `${t("dealList.storage_fee")}`,
      key: "storage_price_per_epoch",
      dataIndex: "storage_price_per_epoch",
      align: "center",
      render: (text) => {
        return <span>{text}</span>;
      },
    },
    {
      title: `${t("dealList.status")}`,
      key: "status",
      dataIndex: "status",
      align: "center",
      render: (text) => {
        return <span>
          {text ? text : 'N/A'}
        </span>;
      },
    },
  ] as ColumnsType<any>;
};

const DealList = () => {
  const { lg } = useBreakpoint();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [table, setTable] = useState({
    count: 0,
    page: 1,
    pageSize: 5,
    data: [],
  });
  const [loading, setLoading] = useState(false);
  const [isSearch, setIsSearch] = useState("");
  // 数据请求
  const fetchData = (pageSize: number, page: number, address?: string) => {
    // 查询参数
    address = isSearch || address;
    setLoading(true);
    try {
      getDealList({ pageSize, page: page - 1, address }).then((resp) => {
        setTable({
          page: page,
          pageSize: pageSize,
          count: resp.count,
          data: resp.data,
        });
        setLoading(false);
      });
    } catch (error) {
      setLoading(false);
      console.log("DealList Request Error", error);
    }
    setTimeout(() => {
      setLoading(false);
    },15000)
  };
  // 跳转到详情页
  useEffect(() => {
    fetchData(table.pageSize, table.page);
  }, []);

  return (
    <div>
      {/* pc端 */}
      <div className="dealList-container-lg" style={{display:lg?'block':'none'}}>
        {/* pc端 */}
        <div className="pc">
          <h3>{t("dealList.title")}</h3>
          <TitleCard
            title={
              <Row justify="space-between" style={{ width: "100%" }}>
                <Col>{`${t("dealList.total")} ${table.count} ${t(
                  "dealList.deal"
                )}`}</Col>
                <Col>
                  <Search
                    placeholder={t("dealList.search")}
                    style={{ width: 304 }}
                    onSearch={(value) => {
                      setIsSearch(value);
                      fetchData(table.pageSize, table.page, value);
                    }}
                  />
                </Col>
              </Row>
            }
          >
            <Tabulation
              dataConf={table}
              onChange={(pageSize, page) => {
                if (page && pageSize) {
                  fetchData(pageSize, page);
                }
              }}
              rowKey={function (record, index): string {
                return record.height + index;
              }}
              loading={loading}
              tableDataTypeConfig={TableConfig}
            />
          </TitleCard>
        </div>
      </div>
      {/* 移动端 */}
      <div className="dealList-container-md" style={{display:lg?'none':'block'}}>
        {/* 标题 */}
        <div className="border-b">
          <p style={{ fontSize: "14px" }}>订单列表</p>
          <p style={{ fontSize: "12px" }}>共{table.count}个订单</p>
        </div>
        {/* 内容 */}
        <div className="main">
          {/* <DetailsCard /> */}
          {table.data && table.data.map((item: DataType, index) => {
            return (
              <DetailsCard
                bodyStyle={{
                  justifyContent: "space-between",
                  fontSize: "12px",
                  padding: "8px 0 0 0",
                  margin: "0 4px",
                  textAlign:'right'
                }}
                boxStyle={{
                  borderBottom: "1px solid #e2e8f0",
                  margin: "0 12px 12px 12px",
                  padding: "0 4px",
                }}
                key={index}
                data={[
                  [
                    t("dealList.dealID"),
                    <div>
                      <span
                        onClick={() => {
                          navigate(`/deal/${item.id}`);
                        }}
                        style={{ display: "inline", color: "#1a4fc9" }}
                      >
                        {item.id}
                      </span>
                      <Tooltip
                        title={t("dealList.IdTitle")}
                        color="#fff"
                        overlayInnerStyle={{ color: "#000" }}
                        overlayStyle={{ width: "170px" }}
                        trigger="click"
                        placement="topLeft"
                      >
                        <i
                          style={{ color: "gold", cursor: "pointer" }}
                          className="iconfont icon-verified verified" 
                        ></i>
                      </Tooltip>
                    </div>,
                  ],
                  [t("dealList.create_time"), formatDate(item.timestamp,'yyyy-MM-dd HH:mm:ss')],
                  [t("dealList.custom"), <span className="color">{EllipsisMiddle({children:item.client,suffixCount:5})}</span>],
                  [t("dealList.custodian"), <span className="color">{item.provider}</span>],
                  [t("dealList.file_size"), changeSizeUnit(item.pieceSize)],
                  [
                    t("dealList.Verified"),
                    item.verifiedDeal ? "true" : "false",
                  ],
                  [t("dealList.storage_fee"), "N/A"],
                  [t("dealList.status"), "N/A"],
                ]}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};
export default DealList;
