import TitleCard from "../../../components/TitleCard";
import { Button, Radio, Row, Space } from "antd";
import Ranks from "./Ranks";
import * as React from "react";
import { useEffect, useState } from "react";
import { LeaderBoard } from "../../../model";
import { getTopMiners } from "../../../servers/api";
import { TopMinerBlock, TopMinerDataBlocks, TopMinerDataGrowth, TopMinerDataPower } from "../../../servers/respType";
import { changeSizeUnit, proportion, transFil } from "../../../utils/convertUtils";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { Mobile, PC } from "../../../shared/pc";
import { ToggleButton } from "./ToggleButton";


interface RanksProps {
  showMore?: boolean | undefined
}

const StorageServiceRanks = (props: RanksProps) => {
  const { showMore } = props
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const [loading, setLoading] = useState(false)

  // 存储服务排行榜当前选择排名方式； 有效算力 ｜ 出块数 ｜ 算力增量；

  const [rinkType, setRinkType] = useState<'power' | 'block' | 'growth'>(
    location.pathname.split('/')[2] ?
      location.pathname.split('/')[2] as 'power' | 'block' | 'growth' : 'power'
  )

  // 当存储服务排行榜排名方式为出块数或者算增量时的求取数据的间隔
  // const [duration, setDuration] = useState("24h")
  // const [rinkData, setRinkData] = useState([] as LeaderBoard[])
  const [rinkData, setRinkData] = useState({
    page: 1,
    pageSize: 50,
    count: 0,
    data: [] as LeaderBoard[]
  })

  // 获取存储服务排行榜的请求入口函数
  function fetchTopMinerPower() {
    setLoading(true)
    let params:any = { count: 20}
    if (!showMore) {
      params = { page: rinkData.page - 1, pageSize: rinkData.pageSize}
    }

    getTopMiners(params, rinkType).then((res) => {
      if (res.data) {
        const data: LeaderBoard[] = []
        if (rinkType == "power") {
          const d = res.data as TopMinerDataPower
          d.MinerInfo.forEach((item, index) => {
            data.push({
              ranking: item.Ranking,
              miner: item.MinerId,
              tag: item.Stateheight,
              col4: `${changeSizeUnit(parseInt(item.QualityAdjPower))} / ${proportion(parseInt(item.QualityAdjPower), parseInt(d.QualityAdjPower)).toFixed(2)}%`,
              col5: item.MinerSectors,
              col6: changeSizeUnit(Number(item.SectorSize)),
              // col7: item.Stateheight,
              // col5: "N / A", // `${item.blocksMined} FIL`,
              // col6: "N / A", // `${(item.blocksMined / (1024 ** 3)).toFixed(2)}FIL/TiB`,
              // col7: "N / A", // changeSizeUnit(parseInt(item.qualityAdjPower))
            } as LeaderBoard)
          })
        }
        // } else if (rinkType == "block") {
        //   const d = res.data as TopMinerDataBlocks
        //   (d.miners as TopMinerBlock[]).forEach((item, index) => {
        //     data.push({
        //       ranking: index + 1,
        //       miner: item.address,
        //       tag: "",
        //       col4: item.blocksMined,
        //       col5: `${(item.luckyValue * 100).toFixed(2)}%`,
        //       col6: `${(parseInt(item.totalRewards) / (10 ** 18)).toFixed(2)}FIL / ${(item.blocksMined / d.blockCount * 100).toFixed(2)} %`,
        //       col7: changeSizeUnit(parseInt(item.rawBytePower))
        //     } as LeaderBoard)
        //   })
        // } else {
        //   const d = res.data as TopMinerDataGrowth
        //   d.Miners.forEach((item, index) => {
        //     data.push({
        //       ranking: index + 1,
        //       miner: item.address,
        //       tag: "",
        //       col4: `${changeSizeUnit(parseInt(item.qualityAdjPowerDelta))} / ${t("unit.day")}`,
        //       col5: `${item.equivalentMiners.toFixed(2)}`,
        //       col6: changeSizeUnit(parseInt(item.rawBytePowerGrowth)),
        //       col7: changeSizeUnit(parseInt(item.qualityAdjPower))
        //     } as LeaderBoard)
        //   })
        // }
        setRinkData({
          ...rinkData,
          data: data,
          // @ts-ignore
          count: res.data.totalCount,  
        })
        // @ts-ignore
      }
      setLoading(false)
    })
    setTimeout(() => {
      setLoading(false)
    }, 15000)
  }

  useEffect(() => {
    setRinkData({
      ...rinkData,
      data: [] as LeaderBoard[]
    })
    fetchTopMinerPower()
  }, [rinkType, rinkData.page, rinkData.pageSize])
  return (
    <>
      <TitleCard style={{ marginBottom: '16px', }} title={
        <div style={{ width: '100%' }}>
          <Row>
            {t("title.storage_ranking")}
          </Row>
          {/* TODO */}
          {/* <ToggleButton rinkType={rinkType} setRinkType={setRinkType} showMore={showMore} setDuration={setDuration} /> */}
        </div>
      }>
        {/*TODO */}
        <Ranks loading={loading} rowKey={"miner"} headerType={rinkType} dataConf={rinkData} setData={setRinkData}/>
        {showMore &&
          <Row justify={"center"} style={{ padding: '12px 18px' }}>
            <Button shape={"round"} href={`/ranks/${rinkType}`}>{t("title.see_more")}</Button>
          </Row>
        }
      </TitleCard>
    </>
  )
}

export default StorageServiceRanks;

