import { Select } from "antd"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import i18n from "../../../i18n/config"

export const NetChange = () => {
    const [currentNet, setNet] = useState(sessionStorage.getItem("net"))
    const {t} = useTranslation()
    return (
        <div className="change" id="network">
            <img
                src={"/assets/net-change.svg"}
                width={18}
                height={18}
                alt={"language"}
            />
            &nbsp;
            {/* <span>{t("navbar.current_network")}:  &nbsp;</span> */}
            <Select
                className="lang"
                defaultValue={currentNet}
                style={{ color: "#fff" }}
                defaultActiveFirstOption
                 // @ts-ignore
                 getPopupContainer={() => document.getElementById("network")}
                popupClassName="popup"
                placement="bottomLeft"
                suffixIcon={<img
                    src={"/assets/down-arrow.svg"}
                    width={18}
                    height={18}
                    alt={"language"}
                />}
                onChange={(value) => {
                    if (value == "mainnet") {
                        sessionStorage.setItem("net", "mainnet")
                    } else {
                        sessionStorage.setItem("net", "hyperspace")
                    }
                    window.location.replace("/");   
                }}
                bordered={false}
                options={[
                    {
                        value: "mainnet",
                        label: "Mainnet",
                    },
                    {
                        value: "hyperspace",
                        label: "Hyperspace"
                    }
                ]}
            />
        </div>
    )
}