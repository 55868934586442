import { Button, Col, Row } from "antd"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import TitleCard from "../../../components/TitleCard"
import ZoomChart from "../../../components/ZoomChart"
import { OneDayTS } from "../../../model"
import { getAllMinersOneDaySector, getAllMinersSector } from "../../../servers/api"
import { MinerSectors } from "../../../servers/respType"
import { changeSizeUnit } from "../../../utils/convertUtils"
import DateUtil from "../../../utils/formatData"

const TotalMinerSectorsRelease = () => {
    const { t } = useTranslation()
    const [data, setData] = useState({
        data: [] as string[][],
        startIndex: 0,
        endIndex: 0,
        loading: false,
        isOneDay: false
    })
    const [clickTime, setClickTime] = useState('')
    const navigate = useNavigate()

    const getAllMinersPowerFreed = () => {
        setData({
            ...data,
            loading: true
        })
        try {
            getAllMinersSector().then((resp) => {
                if (resp.ok) {
                    let prodData: string[][] = []
                    let i = 0
                    const timestamp = Date.parse(new Date().toString()) / 1000
                    let d = resp.data as MinerSectors[]
                    let st = d[0].TimeStamp
                    d && d.forEach((item, index) => {
                        if (st == item.TimeStamp) {
                            prodData.push([DateUtil.formatDate(item.TimeStamp, "yyyy-MM-dd"), item.ExpirationSize])

                            st = item.TimeStamp + OneDayTS
                        } else {
                            // 如果下一天没有数据，那么那一天的算力释放的值为0
                            while (st <= item.TimeStamp) {
                                if (st == item.TimeStamp) {
                                    // while执行到这里将结束循环到下一个符合 st == item.TimeStamp
                                    prodData.push([
                                        DateUtil.formatDate(item.TimeStamp, "yyyy-MM-dd"),
                                        item.ExpirationSize
                                    ])
                                } else {
                                    prodData.push([
                                        DateUtil.formatDate(st, "yyyy-MM-dd"),
                                        "0",
                                    ])
                                }
                                st += OneDayTS
                            }
                        }

                        if (item.TimeStamp >= timestamp && i == 0) {
                            i = index
                        }
                    })
 
                    
                    setData({
                        ...data,
                        data: prodData,
                        loading: false,
                        startIndex: i,
                        isOneDay: false,
                        endIndex: 0
                    })
                } else {
                    setData({
                        ...data,
                        data: [],
                        startIndex: 0,
                        loading: false,
                    })
                }
            })
        } catch (e) {
            setData({
                ...data,
                data: [],
                startIndex: 0,
                loading: false,
            })
        }
    }
    const getOneDayMinersPowerFreed = (ts: number) => {
        setData({
            ...data,
            loading: true
        })
        try {
            getAllMinersOneDaySector({ date: ts }).then((resp) => {
                if (resp.ok) {
                    const d = resp.data;
                    const prodData: string[][] = []
                    d && d.forEach((item, index) => {
                        prodData.push([
                            item.MinerId || "",
                            item.ExpirationSize
                        ])
                    })
                    if (prodData.length > 0) {
                        setData({
                            ...data,
                            data: prodData,
                            startIndex: 0,
                            loading: false,
                            endIndex: prodData.length > 15 ? 15 : 0,
                            isOneDay: true
                        })
                    } else {
                        setData({
                            ...data,
                            loading: false
                        })
                    }

                }
            })
        } catch (error) {
            setData({
                ...data,
                loading: false
            })
        }


    }
    const onEventByAll = {
        "dblclick": function (params: any) {
            getOneDayMinersPowerFreed(DateUtil.dateStringToTimesTamp(params.value[0]) / 1000 + 28800)
            setData({
                ...data,
                loading: true,
            })
            setClickTime(params.value[0])
        }
    }

    const onEventByMiner = {
        "dblclick": function (params: any) {
            navigate('/address/' + params.value[0])
        }
    }
    const Tag = [
        // t("address_details.left_power"),
        t("address_details.all_miners_release_power")
    ]

    useEffect(() => {
        getAllMinersPowerFreed()

    }, [])
    return (
        <TitleCard style={{ marginBottom: '16px', }} title={<div style={{ width: '100%' }}>
            {data.isOneDay ? <Row justify="space-between"  className="prt">
                <Col>
                    <div>{t("address_details.all_miners_release_power")}</div>
                    <p>{t("top_miner.mrpt_miner")}</p>
                </Col>
                <Col className="prb">
                    <Button shape="round" type="primary" onClick={() => {
                        getAllMinersPowerFreed()
                    }}>{t('navbar.go_back')}</Button>
                </Col>
            </Row>
                : <Row className="prt" justify="space-between">
                    <Col>
                        <div>
                            {t("address_details.all_miners_release_power")}
                        </div>
                        <p>{t("top_miner.mrpt")}</p>
                    </Col>
                </Row>
            }
        </div>}>
            <ZoomChart
                data={data.data}
                seriesName={t("address_details.all_miners_release_power")}
                // atteData={echarts.atteData}
                zoomStart={data.startIndex}
                zoomEnd={data.endIndex}
                events={data.isOneDay ? onEventByMiner : onEventByAll}
                loading={data.loading}
                tooltipFormatData={(params: any) => {
                    if (data.isOneDay) {
                        let startHtml = clickTime + '<br/>'
                        let id = `MinerID: ${params[0].axisValue} `
                        let str = t("address_details.release_power") + ": " + changeSizeUnit(Number(params[0].value[1]) * (1024 ** 3))
                        return startHtml + id + str
                    } else {
                        let startHtml = params[0].axisValue + '<br/>'
                        let listArr = []
                        for (let i = 0; i < params.length; i++) {
                            const item = params[i]
                            let str = item.marker
                            str += (Tag[i] + ': ' + changeSizeUnit(Number(item.value[1]) * (1024 ** 3)))
                            listArr.push(str)
                        }
                        return startHtml + listArr.join('<br/>')
                    }
                }}
                yAxisAxisLabel={{
                    formatter: function (value: number) {
                        return changeSizeUnit(value * (1024 ** 3));
                    }
                }} />
        </TitleCard>
    )
}

export default TotalMinerSectorsRelease