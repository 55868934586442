import { Link } from "react-router-dom"
import { DetailsCard } from "../../../../components/DetailsCard"
import EllipsisMiddle from "../../../../components/EllipsisMiddle"
import { MessageData } from "../../../../servers/respType"
import { transFil } from "../../../../utils/convertUtils"
import DateUtil from "../../../../utils/formatData"

interface props {
  data: MessageData[]
}

export const MsgMobile = (props: props) => {
  const { data } = props
  return (
    <>
      {
        data && data.map((item, index) => {
          return (
            <div key={index} className='item-mb incard-item'>
              <DetailsCard className="mobile-lic ells-disblock " data={[
                ["消息ID:", <Link to={`/messages/${item.messageId}`}>
                  <EllipsisMiddle suffixCount={9}>
                    {item.messageId}
                  </EllipsisMiddle>
                </Link>],
                ["区块高度:", <Link to={`/blocks/${item.Height}`}>{item.Height}</Link>],
                ["时间", DateUtil.formatDate(item.TimeStamp, "yyyy-MM-dd HH:mm:ss")],
                ["发送方", <Link to={`/address/${item.From}`}>
                  {item.From.length > 10 && <EllipsisMiddle suffixCount={9}>{item.From}</EllipsisMiddle>}
                </Link>],
                ["接收方", <Link to={`/address/${item.To}`}>
                  {item.To.length > 10 ?
                    <EllipsisMiddle suffixCount={9}>{item.To}</EllipsisMiddle> : item.To}
                </Link>],
                ["方法", item.MethodName || "N / A"],
                ["金额", `${transFil(item.Value, 3)}`],
              ]} bodyStyle={{ display: 'flex', flexDirection: 'row', justifyContent: "space-between" }} />
            </div>
          )
        })
      }
    </>

  )
}