import { Card, Skeleton } from "antd"
import { Link, useParams } from "react-router-dom"
import { useTranslation } from "react-i18next";
import { ColumnsType } from "antd/lib/table";
import { useEffect, useState } from "react";
import { getMessagesInfo } from "../../../servers/api";
import { DetailsCard } from "../../../components/DetailsCard";
import { transFil } from "../../../utils/convertUtils";
import { TitleTips } from "../../../components/TitleTips";
import DateUtil from "../../../utils/formatData";
import { Events_Type, MessageData } from "../../../servers/respType";
import TitleCard from "../../../components/TitleCard";
import "./index.less";
import { Transaction } from "./components/Transaction";
import Events from "./components/Events";


export const MessageDetails = () => {
  const { cid } = useParams()
  const [datalocal, setData] = useState({} as MessageData)
  const [loading, setLoading] = useState(false)
  const { t } = useTranslation()
  const fetchMessage = () => {
    try {
      setLoading(true)
      getMessagesInfo(typeof cid === "string" ? cid : '').then(res => {
        if (res && res.ok) {
          setData(res.data as MessageData);
        }
        setLoading(false)
      })
    } catch (error) {
      setLoading(false)
    }
  }
  useEffect(() => {
    fetchMessage()
  }, [cid])


  return (

    <>
      <div className="page-title">{t("message_details.msg_title")}</div>
      <TitleCard title={t("message_details.msg_overview")} style={{ width: "auto", margin: "12px 0 12px 0" }}>
        {loading ?
          <Card style={{ borderRadius: "0" }} >
            <Skeleton active paragraph={{ rows: 11, width: ['100%', '100%'] }} />
          </Card> :
          <DetailsCard
            className="mobile-lic msg-det-span"
            data={[
              [t("message_details.msg_id"), <p>{cid}</p>],
              [t("block.height"), <Link to={`/blocks/${datalocal.Height}`}>{datalocal.Height}</Link>],
              [t("block.time"), DateUtil.formatDate(datalocal.TimeStamp, "yyyy-MM-dd HH:mm:ss")],
              [t("message_details.in_block"), datalocal.BlockCids && datalocal.BlockCids?.map((item) => {
                return (
                  <Link to={`/block/${item}`} key={item} >
                    <p>
                      {item}
                    </p>
                  </Link>
                )
              })],
              [t("message_details.from"), <Link to={`/address/${datalocal.From}`}><p>{datalocal.From}</p></Link>],
              [t("message_details.to"), <Link to={`/address/${datalocal.To}`}><p>{datalocal.To}</p></Link>],
              [t("block_list.method"), datalocal.MethodName || "N / A"],
              [t("block_list.value"), `${transFil(Number(datalocal.Value))}`],
              datalocal.Status != "" ? 
              [t("block_list.status"), datalocal.Status =="Ok(0)" ? "OK" : datalocal.Status] 
              : [<></>, <></>]
            ]} />
        }
      </TitleCard>


      {/* 交易事件 */}
      {datalocal.Events &&
        <Events EventsList={datalocal.Events} ToAddr={datalocal.To} />
      }

      {/* 转账信息 */}

      {datalocal.Transactions ?
        <Transaction data={datalocal.Transactions} loading={loading} /> :
        <Skeleton style={{ padding: "20px", background: "#fff", borderRadius: "5px" }} loading={loading} />
      }


      <TitleCard title={t("message_details.other_info")} style={{ width: "auto", margin: "12px 0 12px 0" }}>
        {!datalocal.ParamJson && datalocal.ParamJson != "" ?
          <Skeleton style={{ padding: "20px", background: "#fff", borderRadius: "5px" }} paragraph={{ rows: 10 }} /> :
          <DetailsCard
            className="msg-dt-other-li msg-det-span"
            data={[
              [`${t("message_details.version")} (API)`, datalocal.Version.toString() || "N / A"],
              ["Nonce", datalocal.Nonce],
              [<TitleTips title={"Gas Fee Cap"}
                tips={t("message_details.gas_fee_cap_tip")} />, datalocal.GasFeeCap],
              [<TitleTips title={"Gas Premium"}
                tips={t("message_details.gas_premium_tip")} />, datalocal.GasPremium],
              [<TitleTips title={`Gas ${t("message_details.limit")}`}
                tips={t("message_details.gas_limit")} />, datalocal.GasLimit],
              [<TitleTips title={`Gas ${t("message_details.used")}`}
                tips={t("message_details.gas_used")} />, datalocal.GasUsed],
              [<TitleTips title={"Base Fee"}
                tips={t("message_details.gas_fee")} />, datalocal.BaseFee],
              // [<TitleTips title={"Gas 使用量"}
              //             tips={"完成这笔交易真实消耗的Gas量"}/>, datalocal],
              // [<TitleTips title={"Gas Fee"}
              //             tips={"根据区块链网络拥堵状况实时更新的基础手续费率"}/>, datalocal.Message.GasFeeCap],
              //  ["参数", <pre style={{whiteSpace:"pre-wrap", wordWrap:"break-word"}}>{JSON.stringify(JSON.parse(datalocal.Params||""), null, '\t')}</pre>],
              [t("message_details.params"), datalocal.ParamJson != "" && datalocal.ParamJson.startsWith("{") ? <pre >{JSON.stringify(JSON.parse(datalocal.ParamJson), null, '\t')}</pre> : datalocal.ParamJson != "" ? datalocal.ParamJson : "(Null)"],
              [t("message_details.return"), datalocal.ReturnJson != "" ? datalocal.ReturnJson : "(Null)"],
            ]} />
        }

      </TitleCard>
    </>

  )
}

const TableConf = () => {
  const { t } = useTranslation()
  return [
    {
      title: `${t('block_list.from')}`,
      key: 'Method',
      dataIndex: 'Method',
      align: 'center',
    },
    {
      title: ``,
      key: 'Method2',
      dataIndex: 'Method2',
      align: 'center',
      render: () => {
        return <img src="/assets/to.svg" alt="" />
      }
    },
    {
      title: `${t('block_list.to')}`,
      key: 'Value',
      dataIndex: 'Value',
      align: 'center',
    },
    {
      title: `${t('block_list.value')}`,
      key: 'Value2',
      dataIndex: 'Value2',
      align: 'center',
    },
    {
      title: `类型`,
      key: 'Value2',
      dataIndex: 'Value2',
      align: 'center',
    },
  ] as ColumnsType<any>
}