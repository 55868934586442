import {MenuItem} from "./MenuItem";
import {MenuBean} from "../../../model";
import * as React from "react";
import {useNavigate} from "react-router-dom";

interface MenuProps {
    menus?: MenuBean[]
}

export interface ItemProps {
    menuItem: MenuBean
}

const MenuBar = (props: MenuProps) => {
    const {menus} = props
    const navigate = useNavigate()
    return (
        <div className="pc-menu">
            {
                menus && menus.map((item) => {
                    return <MenuItem key={item.id} menuItem={item}/>
                })
            }
        </div>)
}
export default MenuBar