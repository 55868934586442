/** @format */

import Tabulation from "../../../components/Tabulation";
import { ColumnsType } from "antd/es/table";
import { RichPageType, RichList } from "../../../model";
import { useTranslation } from "react-i18next";
import { getFilrich } from "../../../servers/api";
import React, { useEffect, useState } from "react";
import DateUtil from "../../../utils/formatData";
import { Link } from "react-router-dom";
import { Button, Row, Select } from "antd";
import EllipsisMiddle from "../../../components/EllipsisMiddle";
import MobileRichPag from "./mobile/MobileRichPag";
import "./richPage.less";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";

interface RichProps {
  isHome?: boolean;
}

// 部标显示

const iocnFun = (index: any) => {
  if (index == 1) {
    return <img src="	https://filfox.info/dist/img/first.f20f1e6.png" alt="1" />;
  } else if (index == 2) {
    return <img src="	https://filfox.info/dist/img/second.ab3c1e7.png" alt="2" />;
  } else if (index == 3) {
    return <img src="	https://filfox.info/dist/img/third.93fe8ab.png" alt="3" />;
  } else {
    return <div>{index}</div>;
  }
};

const RichPage = (props: RichProps) => {
  const { t } = useTranslation();
  const { isHome } = props;
  const { lg } = useBreakpoint();
  const [loading, setLoading] = useState(false);
  const [category, setCategory] = useState([] as any);
  const [table, setTable] = useState({
    count: 0,
    page: 1,
    pageSize: 20,
    data: [] as RichList[],
  });

  const [Supply, setSupply] = useState("");

  useEffect(() => {
    Filrich(table.pageSize, table.page);
  }, []);

  function Filrich(pageSize: number, page: number, actor?: string) {
    getFilrich({ pageSize, page: page - 1, actor }).then((res: any) => {
      if (res.data) {
        const list = res.data as RichList[];

        const newSecond = [] as RichList[];

        list.forEach((item: RichList, index: number) => {
          newSecond.push({
            ...item,
            index: index + 1,
          });
        });

        setTable({
          pageSize: pageSize,
          page: page,
          data: newSecond as RichList[],
          count: res.count,
        });

        setSupply(res.total_supply);

        // 用于循环下拉菜单
        let typeList = [t("address_details.All")] as any;
        list.forEach((element) => {
          typeList.push(element.actor);
        });

        typeList = new Set(typeList);
        const typelis = [...typeList];

        // 如果下拉菜单的数组为空就添加
        if (!category.length) {
          setCategory(typelis);
        }
      }
    });
  }

  function ab(x: any, y: any) {
    const filA = (Number(x) / 10 ** 18).toFixed(2);
    const filB = (Number(y) / 10 ** 18).toFixed(2);
    const num = (Number(filA) / Number(filB)).toFixed(2);
    return num;
  }

  const RichTableConfig = function () {
    const renderFormat = [
      {
        title: `${t("address_details.rank")}`,
        key: "index",
        dataIndex: "index",
        width: lg ? 128 : 78,
        align: lg ? "center" : "start",
      },
      {
        title: `${t("RichPage.address")}`,
        key: "address",
        dataIndex: "address",
        align: lg ? "center" : "start",
        width: "192",
        render: (address) => (
          <Link to={`address/${address}`} className={"table-clickable-text"}>
            {address.length > 12 ? (
              <EllipsisMiddle className={"table-clickable-text"} suffixCount={lg ? 12 : 4}>
                {address}
              </EllipsisMiddle>
            ) : (
              address
            )}
          </Link>
        ),
      },
      {
        title: `${t("block_list.tags")}`,
        key: "tag",
        dataIndex: "tag",
        align: "center",
        width: "108",
        responsive: ["lg"],
        render: (tag) => <span>{tag ? tag : "--"}</span>,
      },
      {
        title: `${t("RichPage.balance")}`,
        key: "balance",
        dataIndex: "balance",
        align: lg ? "center" : "end",
        width: "156",
        render: (balance) => (
          <span>{`${(balance / 10 ** 18).toFixed(0)} Fil / ${lg ? "" : ab(balance, Supply)} %`}</span>
        ),
      },
      {
        title: `${t("RichPage.balance_ratio")}`,
        key: "proportion",
        dataIndex: "balance",
        align: "center",
        width: "auto",
        responsive: ["lg"],
        render: (balance) => <span>{ab(balance, Supply) + "%"}</span>,
      },
      {
        title: `${t("RichPage.type")}`,
        key: "type",
        dataIndex: "actor",
        align: "center",
        width: "auto",
        render: (type) => <span>{type}</span>,
      },
      {
        title: `${t("RichPage.creation_time")}`,
        key: "create_time",
        dataIndex: "createTimestamp",
        align: "center",
        width: "auto",
        render: (createTimestamp) => <span>{DateUtil.formatDate(createTimestamp, "yyyy-MM-dd HH:mm:ss")}</span>,
      },
      {
        title: `${t("RichPage.latest_transaction_time")}`,
        key: "lastSeenHeight",
        dataIndex: "lastSeenHeight",
        align: "center",
        width: "auto",
        render: (lastSeenHeight) => <span>{DateUtil.formatDate(lastSeenHeight, "yyyy-MM-dd HH:mm:ss")}</span>,
      },
    ] as ColumnsType;
    if (isHome) {
      return renderFormat.slice(0, 5);
    }
    return renderFormat;
  };

  const { Option } = Select;

  const handleChange = (value: string) => {
    if (value == "全部类型") {
      Filrich(table.pageSize, table.page);
    }
    Filrich(table.pageSize, table.page, value);
  };

  const style = {
    height: "50px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0 20px",
    background: "#fff",
  };

  return (
    <>
      <div className={isHome ? "" : "PC"}>
        <div
          style={{
            ...style,
            borderBottom: "solid 1px #e2e8f0",
            borderRadius: "5px 5px 0 0",
            display: isHome ? "none" : "flex",
          }}>
          <div>
            {t("address_details.Total")} <span> {table.count} </span>
            {t("address_details.Acccounts")}
          </div>
          <div>
            <Select
              defaultValue={t("address_details.All")}
              style={{ width: 220, height: "28px", borderRadius: "8px" }}
              onChange={handleChange}>
              {category.map((province: any) => (
                <Option key={province}>{province}</Option>
              ))}
            </Select>
          </div>
        </div>
        <Tabulation
          dataConf={table}
          onChange={(page, pageSize) => {
            if (page && pageSize) {
              Filrich(pageSize, page);
            }
          }}
          rowKey={"address"}
          loading={loading}
          tableDataTypeConfig={RichTableConfig}
        />
        {isHome && !lg ? (
          <Row justify={"center"} style={{ padding: "12px 18px" }}>
            <Button shape={"round"} href={"/rich-list"}>
              {t("title.see_more")}
            </Button>
          </Row>
        ) : null}
      </div>
      {isHome ? null : (
        <div className="Mobile">
          <MobileRichPag />
        </div>
      )}
    </>
  );
};

export default RichPage;
