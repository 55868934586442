/** @format */

import { type } from "os";
import { ReactNode } from "react";
import { MessageData, SectorCountResult } from "../servers/respType";

// 一天的时间戳的值
export const OneDayTS = 86400

/**
 * 公用属性
 * */
export interface MenuBean {
  id: number;
  element: ReactNode;
  pathList: string[];
}

export interface SearchType {
  Filecoin: string;
  Eth: string;
  ID: string;
  Type: string;
}

export interface SmallCardProps {
  title?: string;
  content: number | string | ReactNode;
  tip?: string;
}

export interface LeaderBoard {
  ranking: number | string;
  miner: string;
  tag: string;
  col4: string | number;
  col5: SectorCountResult;
  col6: string;
  col7: string;
}

export interface Blocks {
  height: number;
  time_stamp: number;
  block_id: string[];
  miner: string[];
  tag: string[];
  messages: string[];
  reward: string[];
}

export interface RichData {
  ranking: number;
  address: string;
  tag: string;
  balance: string;
  proportion: string;
  type?: string;
  create_time?: string;
  latest_time?: string;
}

export interface DatumType {
  Blocks: BlockType[];
  height: number;
  messageCount: number;
  timestamp: number;
}

export interface BlockType {
  cid: string;
  messageCount: number;
  miner: string;
  penalty: string;
  reward: string;
  winCount: number;
}

export interface detailsType {
  BlockHash: string;
  Height: number;
  MessageCount: number;
  Miner: string;
  ParentBaseFee: string;
  Parents: string[];
  parentStateRoot: string;
  ParentWeight: string;
  penalty: string;
  reward: string;
  size: number;
  Timestamp: number;
  winCount: number;
}

export interface addressType {
  actor: string;
  address: string;
  balance: string;
  createHeight: number;
  createTimestamp: number;
  id: string;
  lastSeenHeight: number;
  lastSeenTimestamp: number;
  messageCount: number;
  miner: Miner;
  ownedMiners: string[];
  robust: string;
  timestamp: number;
  workerMiners: string[];
}

export interface Miner {
  availableBalance: string;
  blocksMined: number;
  controlAddresses: string[];
  initialPledgeRequirement: string;
  multiAddresses: string[];
  networkQualityAdjPower: string;
  networkRawBytePower: string;
  owner: Owner;
  peerId: string;
  pledgeBalance: string;
  preCommitDeposits: string;
  qualityAdjPower: string;
  qualityAdjPowerRank: number;
  rawBytePower: string;
  rawBytePowerRank: number;
  sectorPledgeBalance: string;
  sectors: Sectors;
  sectorSize: number;
  totalRewards: string;
  vestingFunds: string;
  weightedBlocksMined: number;
  worker: Worker;
}

export interface Owner {
  address: string;
  balance: string;
}

export interface Sectors {
  active: number;
  faulty: number;
  live: number;
  recovering: number;
}

export interface Worker {
  address: string;
  balance: string;
}

export interface detailsType {
  cid: string;
  height: number;
  messageCount: number;
  miner: string;
  parentBaseFee: string;
  parents: string[];
  parentStateRoot: string;
  parentWeight: string;
  penalty: string;
  reward: string;
  size: number;
  timestamp: number;
  winCount: number;
}

export interface rawBytePowerGrowthType {
  blocksMined: number;
  durationPercentage: number;
  equivalentMiners: number;
  luckyValue: number;
  networkTotalRewards: string;
  qualityAdjPowerDelta: string;
  qualityAdjPowerGrowth: string;
  rawBytePowerDelta: string;
  rawBytePowerGrowth: string;
  rewardPerByte: number;
  totalRewards: string;
  weightedBlocksMined: number;
}

export interface RichPageType {
  richList: RichList[];
  totalCount: number;
  totalSupply: string;
}

export interface RichList {
  index?: number;
  actor: string;
  address: string;
  availableBalance: string;
  balance: any;
  createHeight: number;
  createTimestamp: number;
  lastSeenHeight: number;
  lastSeenTimestamp: number;
}

// export interface MessageData {
// 	messages:   Message[];
// 	methods:    string[];
// 	totalCount: number;
// }

// export interface Message {
// 	cid:       string;
// 	from:      string;
// 	height:    number;
// 	method?:    string | number
// 	receipt:   Receipt;
// 	timestamp: number;
// 	to:        string;
// 	value:     string;
//
//
// 	Params?:   string;
// 	Nonce?:    number;
// 	gasPremium?: string;
// 	gasLimit?:   number;
// 	gasFeeCap?:  string;
// 	CID:       {"/":string};
// 	From:      string;
// 	To:        string;
// 	Value:     string;
// 	Method?:    string | number
//
// }

export interface Receipt {
  exitCode: number;
}

export interface Datum {
  count: number;
  fee: number;
  gasFeeCap: number;
  gasLimit: number;
  gasPremium: number;
  gasUsed: number;
  method: string;
  totalFee: string;
}

export interface LatestBlocks {
  heightInfo: HeightInfo;
  messageCount: number;
  blocks: BlockList[];
}

export interface HeightInfo {
  height: number;
  timestamp: number;
}

export interface BlockList {
  cid: string;
  miner: string;
  messageCount: number;
  winCount: number;
  reward: string;
  penalty: string;
}

/**
 * 区块详情数据对象
 */
export interface BlockDetail {
  Miner: string;
  Ticket: Ticket;
  ElectionProof: ElectionProof;
  BeaconEntries: BeaconEntry[];
  WinPoStProof: WinPoStProof[];
  Parents: Messages[];
  ParentWeight: string;
  Height: number;
  ParentStateRoot: Messages;
  ParentMessageReceipts: Messages;
  Messages: Messages;
  BLSAggregate: BlsAggregate;
  Timestamp: number;
  BlockSig: BlsAggregate;
  ForkSignaling: number;
  ParentBaseFee: string;
  BlockHash: string;
  BlsMessages: string[];
  SecpkMessages: string[];
  ParentReceipts: ParentReceipt[];
}

export interface BlsAggregate {
  Type: number;
  Data: string;
}

export interface BeaconEntry {
  Round: number;
  Data: string;
}

export interface ElectionProof {
  WinCount: number;
  VRFProof: string;
}

export interface Messages {
  "/": string;
}

export interface ParentReceipt {
  ExitCode: number;
  Return: null | string;
  GasUsed: number;
}

export interface Ticket {
  VRFProof: string;
}

export interface WinPoStProof {
  PoStProof: number;
  ProofBytes: string;
}

export interface UiData {
  id: string;
  height: string;
  Storage_Providers: string;
  times: string;
  Size: string; // TODO 没有数据
  message: string;
  Rewards: string; // TODO 没有数据
  Storage_Providerss: number; // TODO 没有数据
  Parent_Block: string[]; // TODO 没有数据
  Parent_block_weights: string;
  Fines: string; // TODO 没有数据
  Parent_Base_Fee: string;
  //
}

export interface BlockDetailsData {
  Blocks: BlockOverview[];
  height: number;
  id: string;
  messageCount: number;
  timestamp: number;
}

export interface BlockOverview {
  cid: string;
  messageCount: number;
  miner: string;
  parentParentBaseFee: string;
  parentWeight: string;
  parents: ListCID[];
  penalty: string;
  reward: string;
  winCount: number;
}
export interface addressList {
  Version: number;
  To: string;
  From: string;
  Nonce: number;
  Value: string;
  GasLimit: number;
  GasFeeCap: string;
  GasPremium: string;
  Method: number;
  Params: string;
  CID: ListCID;
  MethodName: string;
  Status?: string;
  ErrorMessage?: string;
}

export interface ListCID {
  "/": string;
}

/**
 * 消息列表
 */
export interface MessageBean {
  TotalCount: number;
  Message: Message[];
}

export interface Message {
  Cid: Cid;
  From: string;
  To: string;
  Value: string;
  Method: number;
  messageId: string;
}

export interface Cid {
  "/": string;
}

//TODO 可实现  平均每高度区块数量   24小时区块数  平均区块间隔 24小时消息数
// tip
/**
 * 首页websocket返回的数据
 */
export interface WebSocketData {
  height: number; // 区块高度
  timestamp: number; // 出块时间
  quality_adjPower: string; //有效算力
  blocks: number; // 当前高度区块数
  messages: number; // 当前高度消息数
  height_blocks: number; //平均每高度区块数
  hours_blocks: number; //24小时区块数
  average_blocks: number; //平均区块间隔
  hours_messages: number; //24小时消息数
}

export interface ContractsOverviewData {
  Address: string;
  ActorID: number;
  RobustAddress: string;
  MessageId: string;
  Actor: string;
  Creator: string;
  EthAddress: string;
  Balance: string;
  Height: number;
  MessageCount: number;
  CreationTime: number;
  LatestTime: number;

  Ipfs: string;
}

/**
 * 合约代码编译完成之后返回的数据
 */
export interface CompileDataOutput {
  abi: ABI[];
  bytecode: string;
}

export interface ABI {
  inputs: any[];
  name: string;
  outputs: OutputElement[];
  stateMutability: string;
  type: string;
}

export interface OutputElement {
  internalType: string;
  name: string;
  type: string;
}

// 合约信息对象
export interface ContractData {
  name: string; // 合约名称
  address: string; // 合约地址
  deployer: string; // 消息发布人
  abi: []; // 合约abi
  bytecode: string; // 合约bytecode
  code: string; // 合约代码
}

/**
 *
 */
export interface MinerSectorsData {
  _id:             ID;
  miner_id:        string;
  time_stamp:      number;
  expiration_size: string;
}

export interface ID {
  $oid: string;
}