import { Dropdown, Space } from "antd";
import { SearchBar } from "../../SearchBar";
import { LogoPic } from "../LogoPic";
import MenuBar from "../Menu/MenuBar";
import { MenuList } from "../Menu/MenuList";
import { LanguageBarMenu } from "../Menu/SubMenu/LanguagebarMenu";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import { useAccount } from "wagmi";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import { ConnectWeb3Button } from "../Menu/ConnectWeb3Button";
import { NetworkbarMenu } from "../Menu/SubMenu/NetworkbarMenu";
import { Language } from "../Menu/Language";
import { NetChange } from "../Menu/NetChange";

export const PcNavbar = () => {
    const { isConnected } = useAccount()
    const { lg } = useBreakpoint()
    return (
        <div className="pc-content">
            <div className="pc-content-left">
                <LogoPic />
                {/*标签栏*/}
                <MenuBar menus={MenuList().slice(0, 3)} />
            </div>

            <div className="pc-content-right">
                <SearchBar />
                {/* <Dropdown overlay={<LanguageBarMenu />} arrow autoFocus={true}>
                    <div onClick={e => {
                        e.preventDefault()
                        console.log(e);
                        
                    }}>
                        <Space>
                            <img
                                style={{ margin: "0 8px" }}
                                src={"/assets/translation.jpg"}
                                width={20}
                                height={20}
                                alt={"language"}
                            />
                        </Space>
                    </div>
                </Dropdown> */}
                <NetChange/>
                <Language />
                {/* <Dropdown overlay={<NetworkbarMenu />} arrow autoFocus={true}>
                    <div onClick={e => e.preventDefault()}>
                        <Space>
                            <img
                                style={{ margin: "0 8px" }}
                                src={"/assets/net.svg"}
                                width={20}
                                height={20}
                                alt={"language"}
                            />
                        </Space>
                    </div>
                </Dropdown> */}
                {/* <div className="flex items-center sm:mx-4 ">
                    <CustomButton />
                </div> */}

            </div>
        </div>
    );
};
