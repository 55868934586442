/** @format */
import { RouteObject } from "react-router-dom";
import HomePage from "../pages/Home";
import NoMatch from "../pages/NoMatch";
import BlockDetails from "../pages/Details/BlockDetails";
import RichPage from "../pages/BlockChain/Rich/RichPage";
import DealList from "../pages/BlockChain/Deal/DealList";
import LeaderBoard from "../pages/LeaderBoard/LeaderBoard";
import MinerCharts from "../pages/Stats/MinerCharts/MinerCharts";
import GasInfo from "../pages/Stats/GasInfo/GasInfo";
import ToolsPage from "../pages/Resource/Tools/ToolsPage";
import Documentation from "../pages/Resource/Docs/Documentation";
import BlockList from "../pages/BlockChain/Blocks/BlockList";
import { Second } from "../pages/Resource/Docs/Docs/Second";
import { First } from "../pages/Resource/Docs/Docs/First";
import { Three } from "../pages/Resource/Docs/Docs/Three";
import DealDetail from '../pages/BlockChain/Deal/DealDetail';
import HeightDetails from "../pages/Details/HeightDetails";
import { Mix } from "../shared/Mix";
import { MessageDetails } from "../pages/Details/MessageDetails/MessageDetails";
import { Message } from "../pages/BlockChain/Message/MessagesList";
import Contract from "../pages/Details/Contract";
import ContractVerification from '../pages/Details/Contract/ContractVerification';
import Contract_list from "../pages/BlockChain/Contract_list/Contract_list";

const routes: RouteObject[] = [
	{
		path: "/",
		element: <HomePage />,
	},
	{
		path: "home",
		element: <HomePage />,
	},

	/**
	 * 区块链功能子路由地址
	 * */
	{
		path: "blocks",
		element: <BlockList />,
	},
	{
		// 区块详情
		path: "block/:id/",
		element: <BlockDetails />,
	},
	{
		// 高度详情  Height
		path: "blocks/:height",
		element: <HeightDetails />,
	},
	{
		// Address
		path: "address/:minerID",
		element: <Mix />,
	},
	//合约概览
	{
		path: "contract/:address",
		element: <Contract />
	},
	// 合约验证
	{
		path: "contractVerify/:EthAddress",
		element: <ContractVerification />
	},
	{
		path: "messages",
		element: <Message />,
	},
	// 消息详情
	{
		path: "messages/:cid",
		element: <MessageDetails />,
	},
	{
		path: "rich-list",
		element: <RichPage />,
	},

	{
		path: "deal",
		element: <DealList />,
	},
	{
		path: 'deal/:id',
		element: <DealDetail />
	},
	{
		path: "contract-list",
		element: <Contract_list />,
	},

	{
		path: "ranks",
		element: <LeaderBoard />,
		children: [
			{
				path: 'power',
				index: true
			},
			{
				path: 'block',
			},
			{
				path: 'growth',
			},
		]
	},
	/**
	 * 统计功能子路由地址
	 * */
	{
		path: 'stats',
		children: [
			{
				index: true,
				path: "miner",
				element: <MinerCharts />,
			},
			{
				path: "gas",
				element: <GasInfo />,
			},
		]
	},


	/**
	 * 资源功能子路由地址
	 * */

	{
		path: "tools",
		element: <ToolsPage />,
	},
	{
		path: "docs",
		element: <Documentation />,
		children: [
			{ index: true, element: <First /> },
			{ path: "1", element: <First /> },
			{ path: "2", element: <Second /> },
			{ path: "3", element: <Three /> },
		]
	},


	{
		path: "block",
		element: <BlockDetails />,
	},
	{
		path: "*",
		element: <NoMatch />,
	},
];

export default routes;
