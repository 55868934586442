import React from 'react'
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom'
import { DetailsCard } from '../../../../components/DetailsCard'
import TitleCard from '../../../../components/TitleCard'
import { Events_Type, MessageData } from '../../../../servers/respType';
interface EventsType {
    EventsList?: Events_Type[];
    ToAddr: string
}
const Events = (props: EventsType) => {

    const { EventsList, ToAddr } = props
    const { t } = useTranslation();

    return (

        <TitleCard className='event-ui' title={t('RichPage.Trading_Events')} style={{ width: "auto", margin: "12px 0 12px 0" }}>

            {
               EventsList && EventsList?.map((item, index) => {

                    return <DetailsCard className="mobile-lic msg-det-span "
                        data={[
                            [t('RichPage.address'), <Link to={`/address/${ToAddr}`}>{ToAddr}</Link>],
                            [t("address_details.address"), <Link to={`/address/${item.Address}`}>{item.Address}</Link>],
                            ["Topics", item.Topics && item.Topics.map((item: any) => {
                                if (item.Key != 'd') return <div style={{ display: "flex", marginBottom: ".5rem" }}><div style={{ textAlign: "center", width: "1.5rem", borderRadius: ".375rem", backgroundColor: "#f7fafc", marginRight: "0.5rem", color: "#a8aec0" }}>{index}</div><p> {item.Value} </p></div>
                            })],
                            [t('RichPage.Parameters'), item.Topics && item.Topics.map((item: any) => {
                                if (item.Key == 'd') return <dd> {item.Value} </dd> 
                            })],
                            ["Log Index", <p>{index}</p>],
                            ["Removed", <p>{'N / A'}</p>],
                        ]} />
                })
            }

        </TitleCard>

    )

}

export default Events
