/** @format */

import * as React from "react";
import {useRoutes} from "react-router-dom";
import routes from "./router";
import "./i18n/config";
import {Layout, message} from "antd";
import "@rainbow-me/rainbowkit/styles.css";

import {getDefaultWallets, midnightTheme, RainbowKitProvider} from "@rainbow-me/rainbowkit";

import "antd/dist/antd.css";
import "./App.less";
import {Loader, Navbar} from "./components";
import FooterPage from "./components/Footer";

import {Chain, configureChains, createClient, WagmiConfig} from "wagmi";

import {publicProvider} from "wagmi/providers/public";
import i18n from "./i18n/config";

const App: React.FC = () => {
    // message全局配置
    message.config({
        top: 100,
        duration: 50,
        maxCount: 3,
        rtl: true,
    });
    const MainnetChain: Chain = {
        id: 314,
        name: "Filecoin - Mainnet",
        network: "Filecoin - Mainnet",
        nativeCurrency: {
            decimals: 18,
            name: "Filecoin",
            symbol: "FIL",
        },
        rpcUrls: {
            default: {
                http: ["https://api.node.glif.io/rpc/v1"],
            },
            chainstack: {
                http: ["https://filecoin-mainnet.chainstacklabs.com/rpc/v1"],
            },
        },
        blockExplorers: {
            default: {
                name: "Filfox Explorer",
                url: "https://hyperspace.filfox.info/en",
            },
        },
        testnet: true,
    };

    const hyperSpaceChain: Chain = {
        id: 3141,
        name: "Filecoin — HyperSpace testnet",
        network: "Filecoin — HyperSpace testnet",
        nativeCurrency: {
            decimals: 18,
            name: "Test Filecoin",
            symbol: "tFIL",
        },
        rpcUrls: {
            default: {
                http: ["https://api.hyperspace.node.glif.io/rpc/v1"],
            },
            chainstack: {
                http: ["https://filecoin-hyperspace.chainstacklabs.com/rpc/v1"],
            },
        },
        blockExplorers: {
            default: {
                name: "Filfox Explorer",
                url: "https://hyperspace.filfox.info/en",
            },
        },
        testnet: true,
    };
    // 设置网页首次加载是网络状态
    if (!sessionStorage.getItem("net")) {
        sessionStorage.setItem("net", "mainnet")
        i18n.changeLanguage("en")
    }


    const {chains, provider} = configureChains(
        [sessionStorage.getItem("net") === "mainnet" ? MainnetChain : hyperSpaceChain],
        [
            // alchemyProvider({ apiKey: process.env.ALCHEMY_ID }),
            publicProvider(),
        ]
    );

    const {connectors} = getDefaultWallets({
        appName: "My RainbowKit App",
        chains,
    });

    const wagmiClient = createClient({
        autoConnect: true,
        connectors,
        provider,
    });
    let element = useRoutes(routes);
    return (
        <WagmiConfig client={wagmiClient}>
            <RainbowKitProvider
                chains={chains}
                modalSize="compact"
                // coolMode
            >
                <Layout style={{minHeight: "100vh"}}>
                    <Navbar/>
                    <Loader element={element}/>
                    <FooterPage/>
                </Layout>
            </RainbowKitProvider>
        </WagmiConfig>
    );
};
export default App;
