import {Link} from "react-router-dom";
import TitleCard from "../../../../components/TitleCard";
import styled from "styled-components";
import React, {useState} from "react";
const Ul = styled.div` 
  height: 100vh;
  > .selected {
    color: #40a9ff
  }
  @media(max-width: 800px){
      height: auto;
  }
`
interface Props{
    article: Info[]
}
interface Info {
    path:string
    title:string
}
export const Article:React.FC<Props> = (props) => {
    const {article} = props
    const [current, setCurrent] = useState(0)

    return (
        <TitleCard title={""} style={{padding: "8px 16px"}}>
            {/**@文章列表*/}
            <aside>
                <Ul>
                    <h3>文章列表</h3>
                    {article.map((item, index) => <p className={current === index ? "selected" : ""}
                                                     onClick={() => setCurrent(index)}>
                        <Link to={item.path}>{item.title}</Link>
                    </p>)}
                </Ul>
            </aside>
        </TitleCard>
    )
}