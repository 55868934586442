import styled from "styled-components";
import React from "react";

const W = styled.div` display: grid;
  grid-template-columns: 1fr 5fr;
  gap: 10px; 
  @media(max-width: 800px){
    grid-template-columns: 1fr ;
  }
`
interface AppProps {
    children: React.ReactNode
}
export const Wrapper:React.FC<AppProps>=({children})=>{
    return <W>{children}</W>
}