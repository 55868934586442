/** @format */

import * as React from "react";

import InfoCard from "./components/InfoCard";
import {useTranslation} from "react-i18next";
import {LastBlockAndRich} from "./components/LastBlockAndRich";
import "./index.less";
import StorageServiceRanks from "../LeaderBoard/components/StorageServiceRanks";
import { log } from "console";
import IncomeChart from "./components/IncomeChart";

export default function HomePage() {
  const {t} = useTranslation();

    return (
    <div style={{width: "100%"}}>
      {/*全网概览*/}
      <InfoCard />
      <IncomeChart />
      {/*首页图表*/}
      {/*<TrendChart style={{marginBottom: '16px'}}/>*/}
      {/*存储服务排行榜*/}
      <StorageServiceRanks showMore/>
      {/*最新区块和富豪榜*/}
      <LastBlockAndRich/>
    </div>
  );
}
