
import { Button, Card, Row, Spin } from 'antd'

import React, { ReactNode } from 'react'
import { TFunction, useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'
import { DetailsCard } from '../../components/DetailsCard'
import EllipsisMiddle from '../../components/EllipsisMiddle'
import TitleCard from '../../components/TitleCard'
import { transFil } from '../../utils/convertUtils'
import DateUtil from '../../utils/formatData'
import './Mobile.less'

// 首页最新区块列表,移动端卡片
const Mob_LastBlockAndRich = (props: any) => {
    const { loadding, blockData, showMore } = props
    const navigator = useNavigate()
    const { t } = useTranslation()
    return (
        <TitleCard className="ant-card-p" title={t("title.latst_block")}>
            {
                loadding ? <div style={{ height: "100vh", width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}><Spin /></div> : blockData.map((item: any, index: any) => {
                    return (
                        <div className='incard-item' key={index}>
                            <DetailsCard key={index} title={
                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                    <div>
                                        <span style={{ marginRight: "0.3rem" }}>{t('block_list.height')}</span>
                                        <Link to={`/blocks/${item.heightInfo.height}`} style={{ color: "#f3921b" }}>{item.heightInfo.height}</Link>
                                    </div>
                                    <div>
                                        <span>{DateUtil.formatDate(item.heightInfo.timestamp, "yyyy/MM/dd HH:mm:ss")}</span>
                                    </div>
                                </div>
                            }
                                data={Data(item, t)}
                            />

                        </div>
                    )
                })
            }
            {showMore &&
                <Row justify={"center"} style={{ padding: '12px 18px' }}>
                    <Button shape={"round"} href={`/blocks`}>{t("title.see_more")}</Button>
                </Row>
            }
        </TitleCard>
    )
}

export default Mob_LastBlockAndRich


const Data = (item: any, t: TFunction) => {
    // const { t } = useTranslation()
    const res: ReactNode[][] = []
    item.blocks && item.blocks.map((d: any, index: number) => {
        res.push(
            [<Link to={`/address/${d.miner}`} state={{type:"storageminer"}}>{d.miner}</Link>,
            // TODO 奖励字段没有
            // `${t('block_list.rewards')}: ${d.reward !="" ? transFil(parseInt(d.reward), 2) : "N / A"} `
            `${t("last_block.message")}: ${d.messageCount}`
            ]
        )
    })
    return res
}