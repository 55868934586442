import {ItemProps} from "./MenuBar";
import {useLocation, useNavigate} from "react-router-dom";

export const MenuItem = (props: ItemProps) => {
  const {menuItem,} = props
  const navigate = useNavigate()
  const location = useLocation()
  return (
    <div className={menuItem.pathList.indexOf(location.pathname) > -1 ? 'css-navbar-select' : 'css-navbar'}  onClick={() => {
     if (menuItem.pathList.length == 1) {
       navigate(menuItem.pathList[0])
     }
    }}>
                {menuItem.element}
        </div>
  )
}