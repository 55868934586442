import { List, Skeleton } from "antd"
import { ColumnsType } from "antd/es/table"
import { useTranslation } from "react-i18next"
import { Link, useNavigate } from "react-router-dom"
import { DetailsCard } from "../../../../components/DetailsCard"
import Tabulation from "../../../../components/Tabulation"
import TitleCard from "../../../../components/TitleCard"
import { Transactions } from "../../../../servers/respType"
import { TextLengthElls } from "../../../../utils/adaptiveTextLength"
import { transFil } from "../../../../utils/convertUtils"
import DateUtil from "../../../../utils/formatData"


const TableConf = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    return [
        {
            title: t("message_details.from"),
            dataIndex: "From",
            key: "From",
            align: "center",
            render: (from: string) => (
                <Link to={`/address/${from}`}>
                    <TextLengthElls content={from} />
                </Link>
            ),
        },
        {

            align: "center",
            render: () => {
                return <div className="block-time">
                    <img src="/assets/right-arrow.svg" width={24} />
                </div>;
            },
        },
        {
            title: t("message_details.to"),
            dataIndex: "To",
            key: "To",
            align: "center",
            render: (to) => <Link to={`/address/${to}`}>
                <TextLengthElls content={to} />
            </Link>
        },
        {
            title: t("block_list.value"),
            key: "Value",
            dataIndex: "Value",
            align: "center",
            render: (amount) => <div>
                {`${transFil(amount)}`}
            </div>
        },
        {
            title: t("block_list.method"),
            key: "Type",
            dataIndex: "Type",
            align: "center",
            render: (type) => <div>
                {type}
            </div>
        },

    ] as ColumnsType;
}
interface TransProps {
    data: Transactions[]
    loading: boolean
}
export const Transaction = (props: TransProps) => {
    const { data, loading } = props;
    const {t} = useTranslation()
    const viewData = [] as Transactions[];
    data.forEach((item) => {
        if (item.From !== "" && item.To !== "") {
            viewData.push(item)
        }
    })

    return (

        <TitleCard title={t("message_details.txn_info")}>
            <Tabulation className="ck" rowKey={"type"} dataConf={{
                page: 1,
                pageSize: 10000,
                count: 0,
                data: viewData
            }}
                loading={loading}
                tableDataTypeConfig={TableConf}
                onChange={(page, pageSize) => { }} />
            <div className="hi">
                
                {loading ? <Skeleton /> :
                    viewData.map((item, index) => {
                        return (
                            <div key={item.Type} className="incard-item">
                                <DetailsCard data={[
                                    [t("message_details.from"), <Link to={item.From}>
                                        <TextLengthElls content={item.From} />
                                    </Link>],
                                    [t("message_details.to"), <Link to={item.To}>
                                        <TextLengthElls content={item.To} />
                                    </Link>],
                                    [t("block_list.value"), item.Value],
                                    [t("block_list.method"), item.Type],
                                ]} />
                            </div>
                        )
                    })
                }
            </div>
        </TitleCard>
    )
}


