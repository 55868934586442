/**
 * @format
 * @万诚
 * @2022/09/05
 */

/**
 * @description
 * 用于展示详情信息的通用组件，类似于key：value形式的两列信息描述，例如：
 * 高度       2135901
 * */
import { Divider, List } from "antd";
import React, { CSSProperties } from "react";
import "../pages/Details/BlockDetails/index.less";

/**
 * @interface
 *  title: 卡片标题
 *  data: 数据源，以二维数组的方式传入数据，根据数组索引渲染对应的数据
 *  [
 *  ["区块ID", "bafy2bzaceaflhlhesarwzcgyjidnriqfl6gefgkhj6iiezq24qgdav4vyeboc"],
 *  ["高度", "2125982"],
 *  ]
 *  bodyStyle: 数据显示区域样式，不传入次参数将使用默认样式
 *  hearderStyle: 头部样式,不传参显示默认样式
 *
 * 	bodyStyle的样式不传 默认 padding: "8px 16px"
 * */

interface PartProps {
  title?: React.ReactNode;
  className?: string;
  data?: React.ReactNode[][];
  boxStyle?: CSSProperties | undefined;
  bodyStyle?: CSSProperties | undefined;
}

export const DetailsCard = (props: PartProps) => {
  const { title, className, data, bodyStyle, boxStyle } = props;

  return (
    <div
      style={{
        borderRadius: "10px",
        // backgroundColor: "#fff",
        width:"100%",
        ...boxStyle,
      }}>
      <List
        size="large"
        split={false}
        dataSource={data}
        header={title}
        renderItem={(item) => {
          return (
            <li className={`${className || ""} details-card`} style={{ ...bodyStyle }}>
              <span className="block-lit">{item[0]}</span>
              <span className="block-lic">{item[1]|| ""}</span>
            </li>
          );
        }}
      />
    </div>
  );
};
