/** @format */

import { Pagination, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { DetailsCard } from "../../../../components/DetailsCard";
import { TimeClock } from "../../../../components/TimeClock";
import { RichList } from "../../../../model";
import { getFilrich } from "../../../../servers/api";
import DateUtil from "../../../../utils/formatData";

const { Option } = Select;

const MobileRichPag = (props: any) => {
  console.log(props.iocnFun, "props");

  const { t } = useTranslation();
  const [category, setCategory] = useState([] as any);
  const [table, setTable] = useState({
    count: 0,
    page: 1,
    pageSize: 20,
    data: [] as RichList[],
  });

  const [Supply, setSupply] = useState("");

  function Filrich(pageSize: number, page: number, actor?: string) {
    getFilrich({ pageSize, page: page - 1, actor }).then((res: any) => {
      const list = res.data as RichList[];

      const newSecond = [] as RichList[];

      list.forEach((item: RichList, index: number) => {
        newSecond.push({
          ...item,
          index: index + 1,
        });
      });

      setTable({
        pageSize: pageSize,
        page: page,
        data: newSecond as RichList[],
        count: res.count,
      });

      setSupply(res.total_supply);

      // 用于循环下拉菜单
      let typeList = [t("address_details.All")] as any;

      res.data &&
        res.data.map((element: any) => {
          typeList.push(element.actor);
        });

      typeList = new Set(typeList);
      const typelis = [...typeList];

      // 如果下拉菜单的数组为空就添加
      if (!category.length) {
        setCategory(typelis);
      }
    });
  }

  useEffect(() => {
    Filrich(table.pageSize, table.page);
  }, []);

  const handleChange = (value: string) => {
    console.log(`selected ${value}`);
    if (value == "全部类型") {
      Filrich(table.pageSize, table.page);
    } else {
      Filrich(table.pageSize, table.page, value);
    }
  };

  const style = {
    height: "50px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0 20px",
  };

  function ab(x: any, y: any) {
    const filA = (Number(x) / 10 ** 18).toFixed(2);
    const filB = (Number(y) / 10 ** 18).toFixed(2);
    const num = (Number(filA) / Number(filB)).toFixed(2);
    return num;
  }

  const mobileData = (item: any, t: any) => {
    return [
      ["余额 / 占比", `${`${(item.balance / 10 ** 18).toLocaleString()} Fil  ${ab(item.balance, Supply) + "%"}`}`],
      ["类型", `${item.actor}`],
      ["创建时间", `${DateUtil.formatDate(item.createTimestamp, "yyyy-MM-dd HH:mm:ss")}`],
      ["最新交易", `${DateUtil.formatDate(item.lastSeenHeight, "yyyy-MM-dd HH:mm:ss")}`],
    ];
  };

  const title = (index: any, t: any) => {
    return (
      <div style={{ padding: " 0 16px" }}>
        <div
        className="mobile-img"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            paddingBottom: "5px",
          }}>
          <div>
            <span style={{ marginRight: "16px",display:"flex",alignItems:"center" }}>
              {props.iocnFun(index)}
              {t("block_list.height")}
            </span>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      <div>
        <div style={{ height: "40px", lineHeight: "40px", paddingLeft: "20px" }}>
          <img
            src="	https://filfox.info/dist/img/rich.702e57e.svg
"
            style={{ height: "12px", marginRight: "8px" }}
          />
          <span style={{ fontSize: "14px" }}>{t("mobile_nav.rich-list")}</span>
        </div>
        <div
          style={{
            ...style,
            borderBottom: "solid 1px #e2e8f0",
            borderRadius: "5px 5px 0 0",
          }}>
          <div>
            {t("address_details.Total")} <span> {table.count} </span>
            {t("address_details.Acccounts")}
          </div>
          <div>
            <Select
              defaultValue={t("address_details.All")}
              style={{
                width: 180,
                borderRadius: "8px",
                fontSize: "12px",
              }}
              onChange={handleChange}>
              {category && category.map((province: any) => <Option key={province}>{province}</Option>)}
            </Select>
          </div>
        </div>
      </div>
      <div>
        <div
          className="BlockList_mobile"
          style={{
            padding: " 0 10px",
          }}>
          {table.data &&
            table.data.map((item, index) => {
              // console.log(item, "talbe");

              return (
                <DetailsCard
                  title={title(index + 1, t)}
                  data={mobileData(item, t)}
                  boxStyle={{
                    borderRadius: "1px",
                    boxShadow: "0 1px 3px 0 rgb(0 0 0 / 10%)",
                  }}
                  bodyStyle={{
                    fontSize: "12px",
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: "4px",
                    padding: "8px 16px",
                  }}
                />
              );
            })}
          <div
            style={{
              marginTop: "10px",
              display: "flex",
              justifyContent: "center",
            }}>
            <Pagination
              size="small"
              total={table.count}
              current={table.page}
              pageSize={table.pageSize}
              showSizeChanger
              showQuickJumper
              onChange={(page, pageSize) => {
                if (page && pageSize) {
                  Filrich(pageSize, page);
                }
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileRichPag;
