/** @format */

import { log } from "console";
import { number } from "echarts";

export function changeSizeUnit(limit: number, unit?: string) {
  limit = Number(limit)
  var size = "";
  if (limit < 0.1 * 1024) {
    //小于0.1KB，则转化成B
    size = limit.toFixed(2) + " B";
  } else if (limit < 1024 * 1024) {
    //小于0.1MB，则转化成KB
    size = (limit / 1024).toFixed(2) + " KB";
  } else if (limit < 1024 ** 3) {
    //小于0.1GB，则转化成MB
    size = (limit / (1024 * 1024)).toFixed(2) + " MiB";
  } else if (limit < 1024 ** 4 || unit == "GiB") {
    size = (limit / 1024 ** 3).toFixed(2) + " GiB";
  } else if (limit < 1024 ** 5 || unit == "TiB") {
    size = (limit / 1024 ** 4).toFixed(2) + " TiB";
  } else if (limit < 1024 ** 6 || unit == "PiB") {
    size = (limit / 1024 ** 5).toFixed(2) + " PiB";
  } else {
    size = (limit / 1024 ** 6).toFixed(2) + " EiB";
  }

  var sizeStr = size + ""; //转成字符串
  var index = sizeStr.indexOf("."); //获取小数点处的索引
  var dou = sizeStr.substring(index + 1, 2); //获取小数点后两位的值
  if (dou == "00") {
    //判断后两位是否为00，如果是则删除00
    return sizeStr.substring(0, index) + sizeStr.substring(index + 3, 3);
  }
  return size;
}

export function proportion(own: number, total: number) {
  return (own / total) * 100;
}

// 保留指定小数位数(四舍五入)
export function transformDecimal(number: number, i: number) {
  let decimalNum = null;
  // 先转换为数值型
  let num = Number(number);
  // 判断是否为数值型
  if (!isNaN(num)) {
    // 切分整数与小数
    let arr = num.toString().split(".");
    // 是小数且小数位大于保留个数
    if (arr.length > 1 && arr[1].length > i) {
      // 小数部分字符串
      let decimal = arr[1].slice(i, i + 1);
      // toFixed 有 bug，四舍六入五随机
      // 当四舍五入的数为 5，给其 + 1
      if (decimal === "5") {
        // 这里可能会存在 0.1 ** 5 = 0.000010000000000000003 但不影响四舍五入
        num += Math.pow(0.1, i + 1);
      }
      decimalNum = num.toFixed(i);
    } else {
      decimalNum = num;
    }
    decimalNum = Number(decimalNum);
  }
  return decimalNum;
}

//数值转换
export function transFil(initial: any, fixed?: number) {
  let fil = 10 ** 18;
  let nonaFil = 10 ** 9;
  // attoFil = 10 ** 

 
  // eslint-disable-next-line eqeqeq
  if (String(initial).length == 0) {
    return "0 FIL"
  }
  if (String(initial).length < 15) {
    return `${fixed ? Number(toFixedReg((Number(initial) / nonaFil), fixed)).toLocaleString(undefined, {minimumFractionDigits: fixed}) : (Number(initial) / nonaFil).toLocaleString(undefined, {minimumFractionDigits: 6})} nanoFIL`
  } else {
    return `${fixed ? Number(toFixedReg((Number(initial) / fil),fixed)).toLocaleString(undefined, {minimumFractionDigits: fixed}) : (Number(initial) / fil).toLocaleString(undefined, {minimumFractionDigits: 6})} FIL`
  }


}
// 判断小数点后面数值,是否需要做toFixed处理
export function DecimalProcessing(num: number) {
  let x = String(num).indexOf(".") + 1;
  let y = String(num).length - x;
  return Number(y);
}

const toFixedReg = function(value: number, count: number): string {
  const reg = new RegExp(`(\\d+\\.?\\d{0,${count}})[\\.\\d]*`);
  return (value + '.' + '0'.repeat(count)).replace(reg, '$1' + '0'.repeat(count - 1)).replace(reg, '$1');
};
