/**
 * @刘泽明
 * 2022-09-22
 * */

import { Card, Col, Row, Skeleton } from "antd";
import TitleCard from "../../../components/TitleCard";
import { DetailsCard } from "../../../components/DetailsCard";
import { Link, useParams } from "react-router-dom";
import { TextLengthElls } from "../../../utils/adaptiveTextLength";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getHeightDetail } from "../../../servers/api";
import { HeightDetailData } from "../../../servers/respType";
import DateUtil from "../../../utils/formatData";

import "./index.less"

/**
 * @description
 * 区块详情页面
 * @router  /blocks/:height
 * */
const HeightDetails = () => {
  const { t } = useTranslation()
  const [data, setData] = useState({} as HeightDetailData)
  const [loading, setLoading] = useState(false)

  //获取路由参数height
  const { height } = useParams()


  // 请求区块详情入口函数
  function fetchHeightData() {
    setLoading(true)
    getHeightDetail({ height: height }).then((value) => {
      setLoading(false)
      const data = value.data as HeightDetailData
      if (data) {
        setData(data)
      }
    }).catch((err) => {
      setLoading(false)
    })
  }

  useEffect(() => {
    fetchHeightData()
  }, [height])
  return (
    <>
      <Col className="page-title">
        {`${t("block.block_height")}#${height}`}
      </Col>
      <Row gutter={[0, 8]} className='msg-top-card'>
        <DetailsCard className="block-time mobile-lic" data={[
          [t("block.block_time"), data.timestamp ? DateUtil.formatDate(data.timestamp, "yyyy-MM-dd HH:mm:ss") : "--"],
          [t("block.count_message"), data.messageCount != undefined ? data.messageCount.toString() : "--"]
        ]} />
      </Row>

      <TitleCard title={t("block.all_block")} className="block-detail-body">

        {
          loading ? <Card >
            <Skeleton active paragraph={{ rows: 11, width: ['100%', '100%'] }} />
          </Card> : data.Blocks && data.Blocks.map((item, index) => {
            return (
              <div key={index} className='incard-item item-mb'>
                <DetailsCard className="block-body mobile-lic" data={[
                  [t("block.block_ID"), <Link to={`/block/${item.cid}`}>
                    <TextLengthElls content={item.cid} />
                  </Link>],
                  [t("block.storage_Provider"), <Link to={`/address/${item.miner}`}>{item.miner}</Link>],
                  // TODO 
                  // [t("block.rewards"), item.reward ? `${item.reward} FIL` : "N / A"],
                  [t("block.message_num"), item.messageCount.toString()]
                ]} />
              </div>
            )
          })
        }
      </TitleCard>

    </>
  )
}

export default HeightDetails