/** @format */

import { Card, Skeleton } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Notify } from "../../../../components/Notify";
import { getAddressMessage } from "../../../../servers/api";

import DateUtil from "../../../../utils/formatData";
import AccountA from "./AccountA/AccountA";
import { transFil } from "../../../../utils/convertUtils";
import { SearchType } from "../../../../model";

interface Props {
  propsType?: string
  addrData?: SearchType
}

const dataeA = (data: any, t: any) => {
  // const { id, actor, balance, messages, address, creation_time, latest_time } = props;
  // 普通
  console.log(data);

  const acountA = [
    // TODO 地址暂无
    [`${t("RichPage.address")}`, data.address ? data.address : data.Filecoin],
    ["ID", data.id ? data.id : "--"],

    [`${t("RichPage.Actor")}`, data.actor ? data.actor : "To be created"],
    [`${t("RichPage.balance")}`, data.balance ? transFil(data.balance, 6) : "0 FIL"],
    [`${t("RichPage.message_count")}`, data.messages ? data.messages.toString() : "0"],
    // TODO 创建时间暂无
    [`${t("RichPage.creation_time")}`, data.creation_time ? DateUtil.formatDate(data.creation_time, "yyyy-MM-dd HH:mm:ss") : "--"],
    // TODO 最新交易暂无
    [`${t("RichPage.latest_transaction_time")}`, data.latest_time ? DateUtil.formatDate(data.latest_time, "yyyy-MM-dd HH:mm:ss") : "--"],
  ];
  if (data.eth_address || data.Eth) {
    acountA.splice(2, 0, [t("RichPage.eth_addr"), data.eth_address ? data.eth_address : data.Eth])
  }
  return acountA;
};


const dataeB = (data: any, t: any) => {
  // const { address, robust, actor, balance, multisig, messageCount, createTimestamp, lastSeenTimestamp } = props;
  // 多签
  return [
    [t(t("address.account_addr")), data.robust],
    [`${t("RichPage.address")}`, data.address],
    [`${t("RichPage.Actor")}`, data.actor],
    [`${t("RichPage.balance")}`, transFil(data.balance, 6)],
    [
      t("address.signers"),
      <div>
        {data.multisig &&
          data.multisig.signers.map((item: any) => {
            return (
              <Link to={`/address/${item}`}>
                <p>{item}</p>
              </Link>
            );
          })}
      </div>,
    ],
    [t("address.approval_threshold"), data.multisig && data.multisig.approvalsThreshold],
    [`${t("RichPage.message_count")}`, data.messageCount && data.messageCount.toString()],
    [`${t("RichPage.creation_time")}`, DateUtil.formatDate(data.createTimestamp * 1000, "yyyy-MM-dd HH:mm:ss")],
    [`${t("RichPage.latest_transaction_time")}`, DateUtil.formatDate(data.lastSeenTimestamp * 1000, "yyyy-MM-dd HH:mm:ss")],
  ];
};



const Froma = (props: Props) => {
  const { propsType, addrData } = props
  const { t } = useTranslation();
  const { minerID } = useParams();
  const [accountType, setaccountType] = useState({ Type: null, data: [], });
  const navigate = useNavigate()


  useEffect(() => {
    if (!addrData?.Filecoin) {
      minerID && propsType && getApiFun(minerID, propsType);
    }
  }, [minerID]);

  const returnData = () => {
    if (accountType.Type && accountType.Type === "multisig") {
      return dataeB(accountType.data, t)
    }
    if (propsType === "unknown") {
      return dataeA(addrData, t)
    }
    return dataeA(accountType.data, t)

  }

  const getApiFun = (minerID: string, propsType: string) => {

    getAddressMessage(minerID, propsType).then((resp) => {

      if (resp.ok) {

        const data = resp.data as any
        setaccountType({ Type: data.actor, data: data, });

      } else {

        Notify.error(t("tip.not_found"), t("tip.not_found_message"));
        setTimeout(() => { navigate('/'); }, 1000);

      }

    });

  };

  return (
    <div>
      {accountType.Type != null || addrData ? (
        <AccountA datae={returnData()} />
      ) : (
        <>
          <Card title={t("RichPage.Account_Overview")} style={{ marginBottom: '16px' }}>
            <Skeleton paragraph={{ rows: 8 }} />
          </Card>
        </>
      )}
    </div>
  );
};

export default Froma;
