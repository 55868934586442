import Tabulation from "../../../components/Tabulation";
import * as React from "react";
import { TFunction, useTranslation } from "react-i18next";
import { Link, NavigateFunction, useNavigate } from "react-router-dom";
import { ColumnsType } from "antd/es/table";
import { BlockHeight } from "../../../servers/respType";
import { useEffect, useState } from "react";
import { transFil } from "../../../utils/convertUtils";
import { LatestBlocks } from "../../../model";
import { Button, Col, Row, Tag } from "antd";
import DateUtil from "../../../utils/formatData";
import EllipsisMiddle from "../../../components/EllipsisMiddle";
import { TextLengthElls } from "../../../utils/adaptiveTextLength";


const latestBlockTableConf = function (navigate: NavigateFunction, t: TFunction) {
  return function () {
    return [
      {
        title: t("last_block.height"),
        key: "heightInfo.height",
        dataIndex: 'heightInfo',
        width: 128,
        align: 'center',
        render: (heightInfo) => <div>
          <Link to={`/blocks/${heightInfo.height}`}>
            <div className="block-height">
              {heightInfo.height}
            </div>
          </Link>
          {/* <TimeClock timeStamp={heightInfo.timestamp} style={{ fontSize: '10px' }} /> */}

        </div>
      },
      {
        title: t("block.time"),
        key: "heightInfo.timestamp",
        dataIndex: 'heightInfo',
        width: 208,
        align: 'center',
        render: (heightInfo) => {
          return <div className='block-time' >
            {DateUtil.formatDate(heightInfo.timestamp, "yyyy/MM/dd HH:mm:ss")}
          </div>
        }
      },
      {
        title: t("last_block.block_id"),
        key: "cid",
        dataIndex: 'blocks',
        align: 'center',
        render: (blocks) => blocks && blocks.map((item: BlockHeight, index: number) => {
          return (
            <div onClick={() => {
              window.scrollTo({
                top: 0,
                behavior: 'smooth'
              })
            }} key={index.toString() + item.cid} className='block-hash'>
              <Link to={`/block/${item.cid}`} >
                {item.cid}
                {/* <EllipsisMiddle suffixCount={9}>{item.cid}</EllipsisMiddle> */}
              </Link>
            </div>
          )
        })
      },
      {
        title: t("last_block.miner"),
        key: "miner",
        dataIndex: 'blocks',
        width: 158,
        align: 'center',
        render: (blocks) => blocks && blocks.map((item: BlockHeight, index: number) => {
          return (
            <div key={index.toString() + item.miner}>
              <Link to={`address/${item.miner}?name=LatestBlocks`}>{item.miner}</Link>
            </div>

          )
        })
      },
      // TODO 标签暂时无法获取
      // {
      //   title: t("last_block.tag"),
      //   key: "tag",
      //   dataIndex: 'blocks',
      //   width: 'auto',
      //   align: 'center',
      //   render: (blocks) => blocks && blocks.map((item: BlockHeight, index: number) => {
      //     return (
      //       <div key={index.toString() + "-"}>
      //         {"--"}
      //       </div>
      //     )
      //   })
      // },
      {
        title: t("last_block.message"),
        key: "message",
        dataIndex: 'blocks',
        width: 68,
        align: 'center',
        render: (blocks) => blocks && blocks.map((item: BlockHeight, index: number) => {
          return (
            <div key={index.toString() + item.messageCount}>
              {item.messageCount}
            </div>
          )
        })
      },
      // TODO
      // {
      //   title: t("last_block.reward"),
      //   key: "reward",
      //   dataIndex: 'blocks',
      //   width: 98,
      //   align: 'center',
      //   render: (blocks) => blocks && blocks.map((item: BlockHeight, index: number) => {
      //     return (
      //       <div key={index.toString() + item.reward}>
      //         {`${item.reward != "" ? transFil(parseInt(item.reward), 2) : "N / A"} `}
      //       </div>
      //     )
      //   })
      // },
    ] as ColumnsType<any>
  }
}

interface BlockProps {
  data: LatestBlocks[]
  showMore?: boolean
  loading?: boolean
}

const LatestBlockList = (props: BlockProps) => {
  const { data, showMore, loading } = props
  const { t } = useTranslation()
  const [table, setTable] = useState({
    count: 0,
    page: 1,
    pageSize: 10,
    data: data
  });

  const navigate = useNavigate()
  useEffect(() => {
    setTable({
      ...table,
      data: data
    })
  }, [data, table])
  return (
    <>
      <Tabulation
        className="block-list"
        dataConf={table}
        onChange={(page, pageSize) => {

        }}
        rowKey={function (record): string {
          return record.heightInfo.timestamp.toString()
        }}
        loading={loading}
        // scroll={{y: 330}}
        tableDataTypeConfig={latestBlockTableConf(navigate, t)} />

      {showMore &&
        <Row justify={"center"} style={{ padding: '12px 18px' }}>
          <Button shape={"round"} href={`/blocks`}>{t("title.see_more")}</Button>
        </Row>
      }
    </>
  )
}

export default LatestBlockList