export const Second=()=>{
    return(
        <p>
            <h1>二、挖矿篇</h1>
            <h2>如何挖矿</h2>
            <h3>矿机硬件</h3>
            <p>随着计算机技术的不断发展，越来越多的数据被生产出来，据统计，每年新生成的数据正以几何级数增长。且随着未来物联网技术和5G技术的不断普及，数据的生产速度还将加快。因此，数据的存储和传输很可能成为制约技术发展的瓶颈，Filecoin技术方案就是为了解决数据的存储和传输问题。</p>
            <p>随着计算机技术的不断发展，越来越多的数据被生产出来，据统计，每年新生成的数据正以几何级数增长。且随着未来物联网技术和5G技术的不断普及，数据的生产速度还将加快。因此，数据的存储和传输很可能成为制约技术发展的瓶颈，Filecoin技术方案就是为了解决数据的存储和传输问题。</p>
            <p>随着计算机技术的不断发展，越来越多的数据被生产出来，据统计，每年新生成的数据正以几何级数增长。且随着未来物联网技术和5G技术的不断普及，数据的生产速度还将加快。因此，数据的存储和传输很可能成为制约技术发展的瓶颈，Filecoin技术方案就是为了解决数据的存储和传输问题。</p>
            <p>随着计算机技术的不断发展，越来越多的数据被生产出来，据统计，每年新生成的数据正以几何级数增长。且随着未来物联网技术和5G技术的不断普及，数据的生产速度还将加快。因此，数据的存储和传输很可能成为制约技术发展的瓶颈，Filecoin技术方案就是为了解决数据的存储和传输问题。</p>
        </p>
    )
}