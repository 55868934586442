import TitleCard from "../../../components/TitleCard";
import Echarts from "../../../components/Echarts";
import {CustomSpace} from "../../../shared/customSpace";
import * as React from "react";
import {useTranslation} from "react-i18next";
import {useFakeData} from "../../../hooks/useFakeData";
import {useEffect, useState} from "react";
import {getAllWorldComputedTrend, getFILT, getStoragePledge, getStorageProvider} from "../../../servers/api";

/**
 * @宋恩改动 9/8
 * */
interface NetworkStoragePower {
    data: Datum1[];
    msg:  string;
    ok:   boolean;
}

export interface Datum1 {
    height:               number;
    qualityAdjPower:      string;
    qualityAdjPowerDelta: string;
    rawBytePower:         string;
    rawBytePowerDelta:    string;
    timestamp:            number;
}
const MinerCharts = () => {
    const {t} = useTranslation()
    const {data} = useFakeData()
    const [networkStoragePower,setNetworkStoragePower] = useState<NetworkStoragePower>()
    const [storageProvider,setStorageProvider] = useState<any>()
    const [pledge,setPledge] = useState<any>()
    const [filt,setFilt] = useState<any>()

    useEffect(()=>{
        getAllWorldComputedTrend().then(r=>{
            if (r&&r.data.length>0){
                setNetworkStoragePower(r)
            }
        })
        getStorageProvider().then(r=>{
            if (r&&r.data.length>0){
                setStorageProvider(r.data)
            }
        })
        getStoragePledge().then(r=>{
            if (r&&r.data.length>0){
                setPledge(r.data)
            }
        })
        getFILT().then(r=>{
            if (r&&r.data.length>0){
                setFilt(r.data)
            }
        })
    },[])
    console.log(storageProvider);
    return (
        <div>
            <h3>存储服务图表</h3>

            {/**@全网有效算力*/}
            <TitleCard title={t("home_target.network_storage_power")}>
                <Echarts
                    legend={{data:[`${networkStoragePower?.msg}`]}}
                    series={[
                        {
                            data: networkStoragePower?.data.map(t=>t.height),
                            name: networkStoragePower?.msg,
                            type: 'line',
                            smooth: true,
                        },
                    ]}
                    xAxisData={networkStoragePower?.data.map(t=>t.rawBytePowerDelta)}
                    cavStyle={{height: '300px', width: '100%', padding: "12px"}}/>
            </TitleCard>
            <CustomSpace/>

            {/**@存储提供者算力增量走势*/}
            <TitleCard title={t("home_target.Incremental_trend")}>
                <Echarts
                    legend={{data: ['Email', 'Union Ads', 'Video Ads', 'Direct', 'Search Engine']}}
                    series={storageProvider?.map((t:any)=>{
                        return {
                            data: t.map((t:any)=>t.height),
                            name: "Email",
                            type: 'line',
                            smooth: true,
                        }
                    })}
                    xAxisData={['Mon', 'Tue', 'Wed', 'Thu']}
                    cavStyle={{height: '300px', width: '100%', padding: "12px"}}/>
            </TitleCard>
            <CustomSpace/>

            {/**@提供存储服务收益变化 (FIL/T)*/}
            <TitleCard title={t("home_target.Change_in_revenue")}>
                <Echarts
                    legend={{data: [`${filt?.msg}`]}}
                    series={[
                        {
                            data: filt?.map((t:any)=>t.height),
                            name: filt?.rewardPerByte,
                            type: 'line',
                            smooth: true,
                        },
                    ]}
                    xAxisData={filt?.map((t:any)=>t.rewardPerByte
                    )}
                    cavStyle={{height: '300px', width: '100%', padding: "12px"}}/>
            </TitleCard>
            <CustomSpace/>

            {/**@扇区质押量变化 (FIL/32GiB)*/}
            <TitleCard title={t("home_target.Sector_pledge_change")}>
                <Echarts
                    legend={{data: [`${pledge?.initialPledge}`]}}
                    series={[
                        {
                            data: pledge?.map((t:any)=>t.height),
                            name: pledge?.initialPledge,
                            type: 'line',
                            smooth: true,
                        },

                    ]}
                    xAxisData={pledge?.map((t:any)=>t.initialPledge)}
                    cavStyle={{height: '300px', width: '100%', padding: "12px"}}/>
            </TitleCard>
        </div>
    )
}

export default MinerCharts