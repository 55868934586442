import StorageServiceRanks from "./components/StorageServiceRanks";
import TotalMinerSectorsRelease from "./components/TotalMinerSectorsRelease";
import "./index.less"

const LeaderBoard = () => {
  return (
    <div>
      <TotalMinerSectorsRelease/>
      <StorageServiceRanks />
    </div>
  )
}

export default LeaderBoard