/** @format */

import { message } from "antd";

let MINER_TYPE = ["storageminer"]; // 存储提供者账户类型 使用存储提供者详情页面

let OTHER_TYPE = [
  "account",
  "cron",
  "datacap",
  "eam",
  "init",
  "multisig", //多签地址，使用多签地址详情页面
  "paymentchannel",
  "placeholder",
  "reward",
  "storagemarket",
  "storagepower",
  "system",
  "verifiedregistry",
  "ethaccount", //是f4开头，这个是ETH那边转过来的，他不是合约地址。使用普通地址详情
];

let CONTRACTS_TYPE = ["evm"]; //合约地址类型 是f4开头，是一个合约地址

/**
 * 是否渲染为普通详情view
 * @param type  多签账户和普通账户都是用普通详情页
 */
export function isNormalDetail(type: string) {

  if (type == "unknown") return 1;
  if (OTHER_TYPE.indexOf(type) >= 0) {
    return 1;
  } else if (MINER_TYPE.indexOf(type) >= 0) {
    return 2;
  } else if (CONTRACTS_TYPE.indexOf(type) >= 0) {
    return 3;
  } else if (type != "") {
    return 4;
  }
}

// /**
//  * 是否渲染为存储提供者详情view
//  * @param type  MinerType
//  */
// export function isMinerDetail(type: string) {
//   //   return type === MINER_TYPE;
// }
