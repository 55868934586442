import TitleCard from "../../../components/TitleCard";
import {useTranslation} from "react-i18next";
import Echarts from "../../../components/Echarts";
import * as React from "react";
import {CustomSpace} from "../../../shared/customSpace";
import {useEffect, useState} from "react";
import {getEchartSatsFee, getMessageFee} from "../../../servers/api";
import Tabulation from "../../../components/Tabulation";
import {ColumnsType} from "antd/es/table";

/**
 * @宋恩改动 9/8 -> 9/21
 * */
const TableConf = () => {
  const {t} = useTranslation()
  return [
    {
      title: `${t('gas_stats.message_type')}`,
      key: 'method',
      dataIndex: 'method',
      align: 'center',
    },
    {
      title: `${t('gas_stats.gas_premium')}`,
      key: 'gasPremium',
      dataIndex: 'gasPremium',
      align: 'center',
    },
    {
      title: `${t('gas_stats.avg_gas_limit')}`,
      key: 'gasLimit',
      dataIndex: 'gasLimit',
      align: 'center',
    },    {
      title: `${t('gas_stats.avg_gas_used')}`,
      key: 'gasUsed',
      dataIndex: 'gasUsed',
      align: 'center',
    },    {
      title: `${t('gas_stats.avg_gas_fee')}`,
      key: 'gasFeeCap',
      dataIndex: 'gasFeeCap',
      align: 'center',
    },
    {
      title: `${t('gas_stats.total_fees')}/${t('gas_stats.tate')}`,
      key: 'totalFee',
      dataIndex: 'totalFee',
      align: 'center',
    },
    {
      title: `${t('gas_stats.total_messages')}/${t('gas_stats.tate')}`,
      key: 'count',
      dataIndex: 'count',
      align: 'center',
    },
  ] as ColumnsType
}

interface Datum {
  height: number;
  baseFee: string
  timestamp: number;
}

const GasInfo = () => {
  const {t} = useTranslation()

  const [data, setData] = useState<Datum[]>()
  const [gasData, setGasData] = useState({
    page: 1,
    pageSize: 50,
    data: [] as Datum[],
    count: 20
  })

  useEffect(() => {
    getEchartSatsFee().then((r) => {
      if (r.data) {
        setData(r.data)
      }
    })
    getMessageFee().then(r => {
      if (r.data && r.data.length > 0) {
        setGasData({
          page: 1,
          pageSize: 20,
          data: r.data,
          count: r.count
        })
      }
    })
  }, [])


  return (
    <div>
      <h2>Gas 统计</h2>
      {/** @Gas统计 */}
      <TitleCard title={"基础费率走势"}>
        <Echarts
          legend={{data: ["Gas"]}}
          series={[
            {
              name: "Gas",
              data: data?.map(t => t.height),
              type: 'line',
              smooth: true,
            },
          ]}
          xAxisData={data?.map(t => t.baseFee)}
          cavStyle={{height: '300px', width: '100%', padding: "12px"}}/>
      </TitleCard>
      <CustomSpace/>

      {/** @列表24hGas数据 */}
      <TitleCard title={t("home_target.24h_Gas")}>
        <Tabulation
          rowKey={"miner"}
          tableDataTypeConfig={TableConf}
          onChange={(page, pageSize) => {

          }}
          dataConf={gasData}/>
      </TitleCard>
    </div>
  )
}

export default GasInfo