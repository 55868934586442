/** @format */

import * as React from "react";
import {Footer} from "antd/lib/layout/layout";


/**
 * @description
 * 页面底部页脚信息
 * 显示版本相关内容或相关合作伙伴、相关联网页跳转标签等
 * */
const FooterPage = () => {
  const currentYear = new Date().getFullYear();
  const host = window.location.host
  return (
  <Footer style={{textAlign: "center"}}>
      {`FileCoin Browser ©${currentYear} Created by ${host}`}
  </Footer>
)
};
export default FooterPage;
