/** @format */

import * as React from "react";
import { Content } from "antd/es/layout/layout";
import { Alert } from "antd";
import { t } from "i18next";
import { useTranslation } from "react-i18next";

/**
 * @description
 * 页面主要内容显示区域
 * Layout布局风格组件
 * */
interface PropsData {
  element: any;
}

const Loader = (props: PropsData) => {
  const {t} = useTranslation()
  return (
    <Content
      className="site-layout"
      style={{ padding: "20px 0px", marginTop: 64 }}
    >
      <div className="alert-top">
        <Alert banner message={`${t("navbar.data_source")}: 2690000`} type="info" closable />
      </div>

      <div
        className="site-layout-background"
        style={{ minHeight: 380, maxWidth: "1280px", margin: "0 auto" }}
      >
        {props.element}
      </div>
    </Content>
  );
};
export default Loader;
