import { Select } from "antd"
import i18n from "../../../i18n/config"

export const Language = () => {
    const ChooseLang = (tag: string) => {
        switch (tag) {
            case "zh":
                return "中文"
            case "en":
                return "English"
            case "zh-CN":
                return "中文"
            case "en-US":
                return "English"
        }
    }
    return (
        <div className="change" id="lang">
            <img
                src={"/assets/net.svg"}
                width={18}
                height={18}
                alt={"language"}
            />
            &nbsp;
            <Select
                className="lang"
                defaultValue={ChooseLang(i18n.language)}
                style={{ color: "#fff", width: '72px' }}
                defaultActiveFirstOption
                placement="bottomRight"
                // @ts-ignore
                getPopupContainer={() => document.getElementById("lang")}
                popupClassName="popup"
                suffixIcon={<img
                    src={"/assets/down-arrow.svg"}
                    width={18}
                    height={18}
                    alt={"language"}
                />}
                onChange={(value, option) => {
                    if (value == "zh") {
                        i18n.changeLanguage("zh")
                    } else {
                        i18n.changeLanguage("en")
                    }
                }}
                bordered={false}
                options={[
                    {
                        value: "en",
                        label: "English",
                    },
                    {
                        value: "zh",
                        label: "中文"
                    }
                ]}
            />
        </div>
    )
}