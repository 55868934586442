import {View} from "./components/View";
import {Article} from "./components/Article";
import {Wrapper} from "./components/Wrapper";

/**
 * @宋恩改动 9/9
 * */


const article = [
    {path: "/docs/1", title: "1. 入门篇"},
    {path: "/docs/2", title: "2. 挖矿篇"},
    {path: "/docs/3", title: "3. 开发篇"},
]
const Documentation = () => {
    return (
        <Wrapper>
            <Article article={article}/>
            <View/>
        </Wrapper>
    )
}
export default Documentation