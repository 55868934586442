/** @format */

import { Card, Col, Tooltip } from "antd";
import { QuestionCircleTwoTone } from "@ant-design/icons";
import * as React from "react";
import { CSSProperties, ReactNode } from "react";
import "./componets.less";
import { client1280Width } from "../shared/clientWidth";

/**
 * @description
 * 文本只有下两行显示时可尝试调用次组件，可参考主页全网指标卡片
 * */


/**
 * @interface
 * title: 卡片标题
 * content：标题相关描述信息
 * tip： 可传参数，如果传入将在描述后显示问号，鼠标移到问号后显示提示文本信息
 * bodyClass： 可传参数， 修改文本区域样式
 * cardStyle: 卡片样式，卡片的padding，margin属性可通过次参数进行修改
 * */
interface SmallCardProps {
  title?: string;
  content: string | ReactNode;
  tip?: string;
  bodyCLass?: string | undefined;
  cardStyle?: CSSProperties | undefined;
}

export const SmallCard = (props: SmallCardProps) => {
  const { title, content, tip, cardStyle } = props;
  return (
    <Col style={{ paddingLeft: 0, paddingRight: 0 }}>
      <Card
        style={{ ...cardStyle }}
        className={'small-card'}
        bodyStyle={{
          alignSelf: "center",
          textAlign: "center",
        }}
      >
        <div className={"small-card-title"}>
          {title}
          {tip && (
            <div style={{ marginLeft: "4px", display: "inline-block" }}>
              <Tooltip
                title={tip}
                color={"cyan"}
                overlayInnerStyle={{ borderRadius: "8px", padding: "8px 16px" }}
              >
                <QuestionCircleTwoTone twoToneColor={"#28b2a2"} />
              </Tooltip>
            </div>
          )}
        </div>
        <div className={"small-card-content"}>{content}</div>
      </Card>
    </Col>
  );
};
