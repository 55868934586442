import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { DetailsCard } from "../../../../components/DetailsCard";
import EllipsisMiddle from "../../../../components/EllipsisMiddle";
import { MemPool, MessageData } from "../../../../servers/respType";
import { transFil } from "../../../../utils/convertUtils";
import DateUtil from "../../../../utils/formatData";

interface props {
  data: MemPool[];
}

export const ContractMobile = (props: props) => {
  const { data } = props;
  const { t } = useTranslation();

  return (
    <>
      {data &&
        data.map((item, index) => {
          return (
            <div key={index} className="item-mb incard-item">
              <DetailsCard
                className="mobile-lic ells-disblock "
                data={[
                  [
                    t("Contract.address"),
                    <Link to={`/messages/${item.EthAddress}`}>
                      <EllipsisMiddle suffixCount={9}>
                        {String(item.EthAddress)}
                      </EllipsisMiddle>
                    </Link>,
                  ],
                  [t("Contract.nickname"), "N / A"],
                  [
                    t("Contract.create_height"),
                    <Link to={`/blocks/${item.Height}`}>{item.Height}</Link>,
                  ],
                  [t("Contract.message_count"), "N / A"],
                  [t("Contract.verify"), "N / A"],
                ]}
                bodyStyle={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              />
            </div>
          );
        })}
    </>
  );
};
