
import { marked } from 'marked'
export const First=()=>{

    const html = marked.parse(



        '## 一、入门篇\n' +
        '## 项目介绍\n' +
        'Rendered by **marked**.\n\n'+
        '随着计算机技术的不断发展，越来越多的数据被生产出来，据统计，每年新生成的数据正以几何级数增长。且随着未来物联网技术和5G技术的不断普及，数据的生产速度还将加快。因此，数据的存储和传输很可能成为制约技术发展的瓶颈，Filecoin技术方案就是为了解决数据的存储和传输问题。\n\n'+
        '随着计算机技术的不断发展，越来越多的数据被生产出来，据统计，每年新生成的数据正以几何级数增长。且随着未来物联网技术和5G技术的不断普及，数据的生产速度还将加快。因此，数据的存储和传输很可能成为制约技术发展的瓶颈，Filecoin技术方案就是为了解决数据的存储和传输问题。\n\n'+
        '随着计算机技术的不断发展，越来越多的数据被生产出来，据统计，每年新生成的数据正以几何级数增长。且随着未来物联网技术和5G技术的不断普及，数据的生产速度还将加快。因此，数据的存储和传输很可能成为制约技术发展的瓶颈，Filecoin技术方案就是为了解决数据的存储和传输问题。\n\n'







    );
    return <article dangerouslySetInnerHTML={{__html:html}}></article>
}