/** @format */

import React from "react";
import List from "../../AddressDetails/List/List";

const AccountList = () => {
  return (

    <List isList={true} />

  );
};

export default AccountList;
