/** @format */


import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import {DetailsCard} from "../../../../components/DetailsCard";
import TitleCard from "../../../../components/TitleCard";
import {Data} from "../../../../servers/respType";

const AccountData = (data: Data, t: any) => {
  // TODO: 没有数据，暂时隐藏
  return [
    [`${t("RichPage.address")}:`, data.address],
    // [`${t("last_block.message")}:`, 'N/A'],
    [`${t("RichPage.Actor")}:`, t('navbar.Storage Miner')],
    // [`${t("RichPage.creation_time")}:`, "N / A"],
  ];
};

const OtherData = (data: any, t: any) => {
   // TODO: 没有数据，暂时隐藏
  return [
    // [`${t("RichPage.Peer_ID")}:`, 'N/A'],
    ["Owner:", <Link to={`/address/${data.minerinfo?.owner}`}>{data.minerinfo?.owner}</Link>],
    ["Worker:", <Link to={`/address/${data.minerinfo?.worker}`}>{data.minerinfo?.worker}</Link>],
  ];
};

const MobileData = (data: any, t: any) => {
  return [
    [`${t("RichPage.address")}:`, data.address],
    [`${t("last_block.message")}:`, 'N/A'],
    [`${t("RichPage.Actor")}:`,t('navbar.Storage Miner')],
    [`${t("RichPage.creation_time")}:`, "N / A"],
    [`${t("RichPage.Peer_ID")}:`, 'N/A'],
    ["Owner:", <Link to={`/address/${data.minerinfo?.owner}`}>{data.minerinfo?.owner}</Link>],
    ["Worker:", <Link to={`/address/${data.minerinfo?.worker}`}>{data.minerinfo?.worker}</Link>],
  ];
}
const Overview = (props: any) => {
  const { t } = useTranslation();
  const { data } = props;
  const { lg } = useBreakpoint()
  return (
   
     
        <div  style={{ display: "flex", width: "100%", padding: "0 16px" }}>
          {lg ?
            <>
              <DetailsCard data={AccountData(data, t)} boxStyle={{ width: "50%" }} />
              <DetailsCard data={OtherData(data, t)} boxStyle={{ width: "50%", marginLeft:"20px" }} />
            </>
            :
            <DetailsCard data={MobileData(data, t)} />
          }

        </div>

  
  );
};

export default Overview;
