/**
 * @刘泽明
 * 2022/09/06
 * */

import Tabulation from "../../../components/Tabulation";
import { useTranslation } from "react-i18next";
import { Link, NavigateFunction, useNavigate } from "react-router-dom";
import { ColumnsType } from "antd/es/table";
import { Col, Pagination, Row, Tooltip } from "antd";
import { QuestionCircleTwoTone } from "@ant-design/icons";
import * as React from "react";
import { TFunction } from "i18next";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import { ScreenMap } from "antd/es/_util/responsiveObserve";
import { SectorCountResult } from "../../../servers/respType";
import { LeaderBoard } from "../../../model";

/**
 * @description
 * 首页存储服务排行榜或排行榜页面公用组件
 * 在此配置表头和列的显示方式
 * */


/**
 * @function
 * 返回表头列
 * */
function ranksTableConf(tableHeaderType: "power" | "block" | "growth", t: TFunction, navigate: NavigateFunction, lg: boolean | undefined) {

  return function () {
    function getHeader() {
      // switch (tableHeaderType) {
      //   case "power":
      return [
        { name: t("address_details.rank"), tip: "" },
        { name: t("block_list.storage_Provider"), tip: "" },
        { name: t("block_list.update_height"), tip: "" },
        { name: t("home_target.effective_computing"), tip: "" },
        { name: t("home_target.sector_status"), tip: "" },
        { name: t("address_details.sector_size"), tip: "" },
        // { name: t("RichPage.balance"), tip: "" },
        // { name: t("home_target.24h_hashrate_increment"), tip: t("tip.increment") },
      ];
      // case "block":
      //   return [
      //     { name: t("address_details.rank"), tip: "" },
      //     { name: t("block_list.storage_Provider"), tip: "" },
      //     { name: t("block_list.tags"), tip: "" },
      //     {
      //       name: t("address_details.win_count"),
      //       tip: t("tip.win_count")
      //     },
      //     {
      //       name: t("home_target.lucky_value"),
      //       tip: t("tip.lucky_value")
      //     },
      //     { name: t("home_target.export_incentives_proportion"), tip: t("tip.rewards") },
      //     { name: t("address_details.adjusted_power"), tip: "" },
      //   ]
      // case "growth":
      //   return [
      //     { name: t("address_details.rank"), tip: "" },
      //     { name: t("block_list.storage_Provider"), tip: "" },
      //     { name: t("block_list.tags"), tip: "" },
      //     { name: t("home_target.computing_power_increment"), tip: "选定周期内，平均每天完成封装扇区的有效算力总和" },
      //     {
      //       name: t("home_target.storage_node_equivalent"),
      //       tip: "以官方Benchmark推荐配置（AMD Ryzen Threadripper 3970X、NVidia GTX 2080Ti、128GB 2133mhz）视为1台基准存储节点，存储节点当量为该存储提供者换算成标准存储节点的数量（存储提供者的算力增速与该基准存储节点增速的比值）。"
      //     },
      //     { name: t("home_target.computing_power_equivalent"), tip: "在选定周期内，存储提供者的有效算力增量" },
      //     { name: t("address_details.adjusted_power"), tip: "" },
      //   ]
      // }
    }

    const header = getHeader()


    // @ts-ignore
    return [
      {
        title:
          <Row justify={lg ? "center" : "start"}>
            <span>{header[0].name}</span>
            {header[0].tip != "" &&
              <span style={{ marginLeft: "4px", display: "inline-block" }}>
                <Tooltip
                  title={header[0].tip}
                  color={"cyan"}
                  overlayInnerStyle={{ borderRadius: "8px", padding: "8px 16px" }}
                >
                  <QuestionCircleTwoTone twoToneColor={"#28b2a2"} />
                </Tooltip>
              </span>}
          </Row>,
        dataIndex: 'ranking',
        key: 'ranking',
        width: lg ? 128 : 78,
        align: lg ? "center" : "start",
        render: (ranking: number) => <div>{ranking}</div>,
      },
      {
        title:
          <Row justify={lg ? "center" : "start"}>
            <span>{header[1].name}</span>
            {header[1].tip != "" &&
              <span style={{ marginLeft: "4px", display: "inline-block" }}>
                <Tooltip
                  title={header[1].tip}
                  color={"cyan"}
                  overlayInnerStyle={{ borderRadius: "8px", padding: "8px 16px" }}
                >
                  <QuestionCircleTwoTone twoToneColor={"#28b2a2"} />
                </Tooltip>
              </span>}
          </Row>,
        key: 'miner',
        width: 'auto',
        dataIndex: 'miner',
        align: lg ? "center" : "start",
        render: (miner) =>
          <Link to={`/address/${miner}`} state={{type:"storageminer"}}>{miner}</Link>
      },
      {
        title:
          <Row justify={"center"}>
            <span>{header[2].name}</span>
            {header[2].tip != "" &&
              <span style={{ marginLeft: "4px", display: "inline-block" }}>
                <Tooltip
                  title={header[2].tip}
                  color={"cyan"}
                  overlayInnerStyle={{ borderRadius: "8px", padding: "8px 16px" }}
                >
                  <QuestionCircleTwoTone twoToneColor={"#28b2a2"} />
                </Tooltip>
              </span>}
          </Row>,
        key: 'tag',
        responsive: ['lg'],
        align: 'center',
        width: 168,
        dataIndex: 'tag',
        render: (tag: string) => <Row justify="center">
          {/* {tag != "" ? tag : "--"} */}
          <div className="block-height"  onClick={() => navigate(`/blocks/${tag}`)} >{tag}</div>
        </Row>
      },
      {
        title:
          <Row justify={lg ? "center" : "end"}>
            <span>{header[3].name}</span>
            {header[3].tip != "" &&
              <span style={{ marginLeft: "4px", display: "inline-block" }}>
                <Tooltip
                  title={header[3].tip}
                  color={"cyan"}
                  overlayInnerStyle={{ borderRadius: "8px", padding: "8px 16px" }}
                >
                  <QuestionCircleTwoTone twoToneColor={"#28b2a2"} />
                </Tooltip>
              </span>}
          </Row>,
        key: 'col4',
        align: lg ? "center" : "end",
        width: 'auto',
        dataIndex: 'col4',
        render: (percentage, record) =>
          <div>
            {/* @ts-ignore */}
            <div>{ percentage }</div>
          </div>
          

      },
      {
        title:
          <Row justify={"center"}>
            <span>{header[4].name}</span>
            {header[4].tip != "" &&
              <span style={{ marginLeft: "4px", display: "inline-block" }}>
                <Tooltip
                  title={header[4].tip}
                  color={"cyan"}
                  overlayInnerStyle={{ borderRadius: "8px", padding: "8px 16px" }}
                >
                  <QuestionCircleTwoTone twoToneColor={"#28b2a2"} />
                </Tooltip>
              </span>}
          </Row>,
        responsive: ['lg'],
        align: 'center',
        key: 'col5',
        dataIndex: 'col5',
        render: (col5: SectorCountResult) => {
          return <Row style={{flexFlow:"nowrap", padding:"0 12px"}}>
            <Col span={6}>{`${col5.Live.toLocaleString()} ${t("address_details.total")}`}</Col>&emsp;
            <Col span={6} className={"text-green-600"}>{`${col5.Active.toLocaleString()} ${t("address_details.active")}`}</Col>&emsp;
            <Col span={6} className={" text-red-700"}>{`${col5.Faulty.toLocaleString()} ${t("address_details.faults")}`}</Col>&emsp;
            <Col span={6} className={" text-yellow-500"}>{`${col5.Recoveries.toLocaleString()} ${t("address_details.recoveries")}`}</Col>
          </Row>
        }
      },
      {
        title:
          <Row justify={"center"}>
            <span>{header[5].name}</span>
            {header[5].tip != "" &&
              <span style={{ marginLeft: "4px", display: "inline-block" }}>
                <Tooltip
                  title={header[5].tip}
                  color={"cyan"}
                  overlayInnerStyle={{ borderRadius: "8px", padding: "8px 16px" }}
                >
                  <QuestionCircleTwoTone twoToneColor={"#28b2a2"} />
                </Tooltip>
              </span>}
          </Row>,
        align: 'center',
        responsive: ['lg'],
        key: 'col6',
        width: 192,
        dataIndex: 'col6',
      },
      // {
      //   title:
      //     <Row justify={"center"}>
      //       <span>{header[5].name}</span>
      //       {header[5].tip != "" &&
      //         <span style={{ marginLeft: "4px", display: "inline-block" }}>
      //           <Tooltip
      //             title={header[5].tip}
      //             color={"cyan"}
      //             overlayInnerStyle={{ borderRadius: "8px", padding: "8px 16px" }}
      //           >
      //             <QuestionCircleTwoTone twoToneColor={"#28b2a2"} />
      //           </Tooltip>
      //         </span>}
      //     </Row>,
      //   align: 'center',
      //   responsive: ['lg'],
      //   key: 'col7',
      //   width: 192,
      //   dataIndex: 'col7',
      // },
    ] as ColumnsType
  }
}


/**
 * @interface
 * rowKey
 * headerType: 共三种表头模式[有效算力、出块数、算力增数]
 * dataSource: 排行榜数据
 * */
interface RanksProps {
  rowKey: string
  loading?: boolean
  headerType: "power" | "block" | "growth"
  dataConf: { page: number, pageSize: number, count: number, data: any }
  setData: React.Dispatch<React.SetStateAction<{
    page: number;
    pageSize: number;
    count: number;
    data: LeaderBoard[];
  }>>
}

// PC、移动端响应式

const Ranks = (props: RanksProps) => {
  const { rowKey, loading, headerType, dataConf, setData } = props
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { lg } = useBreakpoint()

  return (
    <>

      <Tabulation
        dataConf={dataConf}
        onChange={(pageSize, page) => {
          if (page && pageSize) {
            setData({
              ...dataConf,
              page: page,
              pageSize: pageSize
            })
          }
        }}
        rowKey={"miner"}
        loading={loading}
        tableDataTypeConfig={ranksTableConf(headerType, t, navigate, lg)} />

      {/* <Mobile>
        <TitleCard title={"存储服务排行榜"}>
          <CusTable data={dataSource} />
        </TitleCard>
      </Mobile> */}
    </>
  )
}

export default Ranks