/** @format */

import React, { useEffect, useState } from "react";
import { Col, Divider, RadioChangeEvent, Row, Select, Tabs, TabsProps } from "antd";
import type { SizeType } from "antd/es/config-provider/SizeContext";
import Tabulation from "../../../../components/Tabulation";
import { addressList } from "../../../../model";
import { getAddressList, TiosetsList } from "../../../../servers/api";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import EllipsisMiddle from "../../../../components/EllipsisMiddle";
import { ColumnsType } from "antd/es/table";
import DateUtil from "../../../../utils/formatData";
import { DecimalProcessing } from "../../../../utils/convertUtils";
import { TextLengthElls } from "../../../../utils/adaptiveTextLength";
import TitleCard from "../../../../components/TitleCard";

const Config = () => {
  const { t } = useTranslation();
  const { minerID } = useParams()
  return [
    {
      title: t("navbar.message_id"),
      dataIndex: "messageId",
      key: "msg-messageId",
      width: 178,
      align: "center",
      render: (messageId) => {
        return (
          <Link to={`/messages/${messageId}`}  >
            <EllipsisMiddle suffixCount={5}>{messageId}</EllipsisMiddle>
          </Link>
        );
      },
    },
    {
      title: t("home_target.block_height"),
      dataIndex: "Height",
      width: 168,
      align: "center",
      key: "msg-Height",
      render: (item) => {
        return <Row justify={"center"}>
          <Link to={`/blocks/${item}`}>
            <Row className="block-height" justify="center">
              {item}
            </Row>
          </Link>
        </Row>
      },
    },
    {
      title: t("block_list.time"),
      dataIndex: "TimeStamp",
      key: "msg-timestamp",
      width: 256,
      align: "center",
      render: (item) => {
        return <div>{DateUtil.formatDate(item, "yyyy-MM-dd HH:mm:ss")}</div>;
      },
    },
    {
      title: t("block_list.from"),
      key: "msg-From",
      width: 178,
      dataIndex: "From",
      align: "center",
      render: (from: string) => {
        return   minerID != from ? <Link to={`/address/${from}`}>
          <TextLengthElls content={from} isElls />
        </Link> : <TextLengthElls content={from} isElls />
      }
    },
    {
      title: t("block_list.to"),
      key: "msg-To",
      align: "center",
      width: 178,
      dataIndex: "To",
      render: (to: string) => {
        return minerID != to ? <Link to={`/address/${to}`}>
          <TextLengthElls content={to} isElls />
        </Link> : <TextLengthElls content={to} isElls />
      }
    },
    {
      title: t("block_list.method"),
      key: "msg-MethodName",
      align: "center",
      width: 150,
      dataIndex: "MethodName",
      render: (MethodName) => {
        return <span>{MethodName}</span>
      }
    },
    {
      title: t("block_list.value"),
      align: "center",
      key: "msg-Value",
      width: 220,
      dataIndex: "Value",
      render: (Value) => {
        let text = Number(Value) / 10 ** 18
        return <span>{DecimalProcessing(text) > 1 ? text.toFixed(4) + " FIL" : text + " FIL"}</span>
      }
    },
    // {
    //   title: t("block_list.status"),
    //   align: "center",
    //   key: "Status",
    //   width: 150,
    //   dataIndex: "Status",
    //   render: (item, record: addressList) => {
    //     if (record.ErrorMessage != "") {
    //       return record.ErrorMessage
    //     }
    //     let i = item.replace('(0)', '')
    //     return i
    //   },
    // },
  ] as ColumnsType;
};

// 地址详情下的消息列表
interface ListProps {
  className?: string;
  isList?: boolean;
}
const List = (props: ListProps) => {
  const { className, isList = false } = props;
  const { t } = useTranslation();

  const { minerID } = useParams();
  const [loading, setLoading] = useState(true);
  const [size, setSize] = useState<SizeType>("middle");
  const [MsgMethodMapList, setMsgMethodMapList] = useState([] as Object[]);
  const [type, setType] = useState<string>("ALL");
  const [table, setTable] = useState({
    count: 0 as number,
    page: 1,
    pageSize: 10,
    data: [] as addressList[],
  });

  const [tableB, setTableB] = useState({
    count: 0 as number,
    page: 1,
    pageSize: 10,
    data: [] as any[],
  });

  const handleSizeChange = (e: RadioChangeEvent) => {
    setSize(e.target.value);
  };


  function getList(minerID: any, pageSize: number, page: number, method?: string) {
    setLoading(true);
    getAddressList(minerID, { pageSize, page: page - 1, method: method }).then((r) => {

      const methodNames =[{ value: "ALL", label: t("address_details.total") }] // 如果r.method_name为undefined或null，则默认为空数组
     
      r.method_name.forEach((item:any )=>{
        if (item != ""){
          methodNames.push({
            value: item,
            label:item
          })
        }
      })
      
      setMsgMethodMapList(methodNames);
      
      setTable({
        page,
        pageSize,
        data: r.data as addressList[],
        count: r.count as number,
      });
      setLoading(false);
    }).catch((err) => setLoading(false));
  }

  const getFunctionApi = (minerID: any, pageSize: number, page: number) => {
    setLoading(true);
    TiosetsList(minerID, { pageSize, page: page - 1 }).then((res) => {
      if (res.ok) {
        setTableB({
          page,
          pageSize,
          data: res.data as any[],
          count: res.count as number,
        });
      }
      setLoading(false);
    }).catch((err) => {

    })
  }

  useEffect(() => {
    getList(minerID, table.pageSize, table.page);
    getFunctionApi(minerID, tableB.pageSize, tableB.page)
  }, [minerID, t]);

  const handleChange = (val: string) => {
    const method = val === "ALL" ? undefined : val;
    setType(val);
    updateTableData(minerID, table, method);
  };

  function updateTableData(minerID: any, table: any, method?: string) {
    const { pageSize, page } = table;
    getList(minerID, pageSize, page, method);
  }


 

  const items: TabsProps['items'] = [
    {
      key: "message_list",
      label: t(`list.Message_List`),
      children: <>
        <Row justify="space-between">
          <Col className="tcount">
            {t("address_details.Total")}
            &nbsp;<p>{table.count} </p>&nbsp;
            {t("last_block.message")}
          </Col>

          <Col>
            <Select
              defaultValue="ALL"
              style={{ width: 220 }}
              onChange={handleChange}
              options={MsgMethodMapList.length> 0 ? MsgMethodMapList : []}
            />
          </Col>
        </Row>
        <Tabulation
          dataConf={table}
          onChange={(pageSize, page) => {
            if (page && pageSize) {
              type === 'ALL' ? getList(minerID, pageSize, page) : getList(minerID, pageSize, page, type);
            }
          }}
          loading={loading}
          rowKey={function (record): string {
            return record.messageId;
          }}
          tableDataTypeConfig={Config}
        />
      </>
    },
    {
      key: 'tipsets_list',
      label: t(`list.blocks_list`),
      children: <>
        <Row className="tcount" >
          {t("address_details.Total")}
          &nbsp;<p>{tableB.count}</p>&nbsp;
          {t("last_block.blocks")}
        </Row>
        <Tabulation
          dataConf={tableB}
          onChange={(pageSize, page) => {
            if (page && pageSize) {
              getFunctionApi(minerID, pageSize, page)
            }
          }}
          loading={loading}
          rowKey={function (record): string {
            return record.height;
          }}
          tableDataTypeConfig={ConfigB}
        />
      </>
    },
  ];

  return (

    <TitleCard >
      <div style={{ padding: '0 1.25rem', }}>
        <Tabs defaultActiveKey="1" items={isList ? [items[0]] : items} />
      </div>
    </TitleCard>

  );
};

export default List;


const ConfigB = () => {
  const { t } = useTranslation();
  return [
    {
      title: t('block.block_height'),
      dataIndex: "height",
      key: "tipsets-height",
      align: "center",
      render: (item) => {
        return <Row justify={"center"}>
          <Link to={`/blocks/${item}`}>
            <Row className="block-height" justify="center">
              {item}
            </Row>
          </Link>
        </Row>
      },
    },
    {
      title: t('block.block_ID'),
      dataIndex: "cid",
      width: 256,
      align: "center",
      key: "tipsets-cid",
      render: (cid: string) => {
        return <Link to={`/block/${cid}`}>
          <TextLengthElls content={cid} />
        </Link>
      }
    },
    {
      title: t("block.rewards"),
      key: "tipsets-winCount",
      dataIndex: "winCount",
      align: "center",
      render: (item: string) => {
        return item
      }
    },
    {
      title: t('block_list.time'),
      dataIndex: "timestamp",
      key: "tipsets-timestamp",
      width: 256,
      align: "center",
      render: (item) => {
        return <div>{DateUtil.formatDate(item, "yyyy-MM-dd HH:mm:ss")}</div>;
      },
    },
    {
      title: t('block.message_num'),
      key: "tipsets-messageCount",
      align: "center",
      dataIndex: "messageCount",
    },
    // {
    //   title: 'Block Size',
    //   key: "MethodName",
    //   align: "center",
    //   width: 150,
    //   dataIndex: "MethodName",
    //   render: (MethodName) => {
    //     return <span>{MethodName}</span>
    //   }
    // },
  ] as ColumnsType;
};