import useBreakpoint from "antd/es/grid/hooks/useBreakpoint"
import EllipsisMiddle from "../components/EllipsisMiddle"

export const TextLengthElls = (props: { content: string, isElls?: boolean}) => {
  const { content,isElls} = props
  const { xxl, xl, lg, md, sm, xs } = useBreakpoint()
  if (content && content.length < 10 )  {
    <span>{content}</span>
  }
  if (isElls) {
    return  <EllipsisMiddle suffixCount={5}>{content}</EllipsisMiddle>
  }
  if (xs && !sm) {
    return <EllipsisMiddle suffixCount={5}>{content}</EllipsisMiddle>
  }
  if (sm && !xs && !md) {
    return <EllipsisMiddle suffixCount={7}>{content}</EllipsisMiddle>
  }
  if (sm && md && !lg ) {
    return <EllipsisMiddle suffixCount={7}>{content}</EllipsisMiddle>
  }
  if (lg && md && !xl) {
    return <EllipsisMiddle suffixCount={9}>{content}</EllipsisMiddle>
  }
  if (xxl) {
    return <>{content}</>
  }
  return <EllipsisMiddle suffixCount={11}>{content}</EllipsisMiddle>
}