/** @format */

import { Button, Card, Col, Row, Skeleton } from "antd";
import * as React from "react";
import { CSSProperties, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { SmallCard } from "../../../components/SmallCard";
import "./infoCard.less";
import TitleCard from "../../../components/TitleCard";
import { TimeClock } from "../../../components/TimeClock";
import { OverViewType } from "../../../servers/respType";
import { InfoCardData } from "../conf/overViewConf";
import { SmallCardProps, WebSocketData } from "../../../model";
import wsClient from "../../../utils/wsClient";
import { log } from "console";


interface InfoProps {
    style?: CSSProperties | undefined;
}

const InfoCard = (props: InfoProps) => {
    const { style } = props;
    const { t } = useTranslation();
    const [isFold, setFold] = useState(false);

    const [infoCard, setInfoCard] = useState([] as SmallCardProps[])

    useEffect(() => {
        wsClient.addEventListener("head", listenerHead);

        return () => {
            wsClient.removeEventListener("head")
            wsClient.closeWebSocket();
            clearInterval(timer);
            timer = 0;
        }
    }, [t])

    let timer: number;
    /**
     * 最新区块返回之后可以进行单独处理
     * @param ev
     */

    function listenerHead(ev: MessageEvent) {
        try {
            if (ev.data != "pong") {
                let jsonData = JSON.parse(ev.data) as WebSocketData;
                if (jsonData.height) {
                    setInfoCard(InfoCardData(jsonData, t))
                }
            }

        } catch (error) {
            // console.log(error);    
        }
    }

    const handleClickFold = () => {
        setFold((prev) => !prev);
    };
    return (
        <>
            <TitleCard
                style={{ marginBottom: "16px" }}
                title={
                    <Col>
                        {t("home_target.network_indicators")}
                    </Col>
                }
            >
                <Card
                    style={{ width: "100%", borderRadius: "8px" }}
                    bodyStyle={{ padding: "8px 16px" }}
                    bordered={false}
                >
                    {infoCard.length > 0 ?
                        <Row className={"css-overview"} style={{ display: "grid" }}>
                            {infoCard.map((item, index) => {
                                if (!isFold && index > 9) {
                                    return;
                                }
                                return (
                                    <SmallCard
                                        title={item.title}
                                        content={item.content}
                                        tip={item.tip}
                                        cardStyle={{
                                            borderRadius: "8px",
                                            backgroundColor: "#f0f2f5",
                                            width: "100%",
                                            height: "100%",
                                            display: "flex",
                                            justifyContent: "center",
                                            whiteSpace: 'nowrap'
                                        }}
                                        key={index}
                                    />
                                );
                            })}
                        </Row>
                        :
                        <Skeleton active paragraph={{ rows: 2, width: ['100%', '100%'] }} />
                    }
                    {/* TODO 移动端卡片扩展 */}
                    {/* <div className={"css-overview-button-bottom"}>
                        <Button
                            style={{ alignSelf: "center", background: "transparent" }}
                            block
                            type={"text"}
                            onClick={handleClickFold}
                        >
                            {isFold ? t("home_target.fold") : t("home_target.unfold")}
                        </Button>
                    </div> */}
                </Card>
            </TitleCard>

        </>
    );
};

export default InfoCard;
