import { Select } from 'antd'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import { Notify } from '../../../../../../components/Notify';
import Tabulation from '../../../../../../components/Tabulation';
import { getAddressList } from '../../../../../../servers/api';
import { MissageList } from '../CollectinData';


// @消息列表

interface Props {
    MessageId: string
}

const ContractNewsList = (props: Props) => {
    const { MessageId } = props
    const navigate = useNavigate();
    const minerID = Object.values(useParams())[0]
    const { t } = useTranslation();
    const [type, setType] = useState<string>("all");
    const [loading, setLoading] = useState(false);
    const [MsgMethodMapList, setMsgMethodMapList] = useState([{}]);
    const [message, setMessage] = useState({ count: 0, page: 1, pageSize: 20, data: [] as any[], });


    const getFunctionApi = async (minerID: string, pageSize: number, page: number, MessageId: string, method?: string) => {
        setLoading(true);
        await getAddressList(minerID, { pageSize, page: page - 1, method: method, messageId: MessageId }).then((res) => {
            console.log(res);
            if (res.ok) {

                setMessage({ ...message, count: res.count, page, pageSize, data: res.data })

                const methodsType = [{ 'value': "all", label: t('address_details.total') }]
                res.method_name && res.method_name.map((item: any) => {
                    methodsType.push({ value: item, label: item })
                })
                setMsgMethodMapList(methodsType)

            }

            setLoading(false);
        }).catch((err) => {
            setLoading(false)
        })
    }

    // console.log(minerID);
    

    useEffect(() => {
        
        MessageId && console.log(1);

        if (MessageId && minerID) {
            getFunctionApi(minerID, message.pageSize, message.page, MessageId);
        }
    }, [minerID,MessageId])


    const handleChange = (val: string) => {
        if (val == 'all') {
            setType('all')
            minerID && getFunctionApi(minerID, message.pageSize, message.page, MessageId);
        } else {
            setType(val)
            minerID && getFunctionApi(minerID, message.pageSize, message.page, MessageId, val ? val : "");
        }
    };


    return (
        <div className='HeightLimit'>
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "0 12px" }}>
                <span style={{ fontSize: "14px" }}>{t("address_details.Total")}<span className={'title-color'}>{message.count}</span>{t("last_block.message")}</span>
                <Select
                    defaultValue="all"
                    style={{ width: 220 }}
                    onChange={handleChange}
                    options={MsgMethodMapList}
                />
            </div>


            <Tabulation
                dataConf={message}
                onChange={(pageSize, page) => {
                    if (page && pageSize && minerID) {
                        console.log(type == 'all' ? 1 : 2);

                        type == 'all' ? minerID && getFunctionApi(minerID, pageSize, page, MessageId) : getFunctionApi(minerID, pageSize, page, MessageId, type);
                    }
                }}
                loading={loading}
                rowKey={function (record, index): string {
                    return record.height
                }}
                tableDataTypeConfig={MissageList}
            />


        </div>
    )
}

export default ContractNewsList