import {getContractCode} from "../servers/api";

const CheckContract = {
    /**
     * 查询链上的byete和本地编译的bytecode比较看是否是同一代码
     * @param address
     * @param byteCode
     */
    async checkByteCode(address?: string, byteCode?: string) {
        try {
            let req = await getContractCode(address ? address : "")
            if (req.ok && req.data && req.data != "0x") {
                // TODO 判断流程待确认看是否合理
                let newReq = req.data.substring(0, req.data.length - 86);
                return (byteCode && byteCode.indexOf(newReq.replace("0x", "")) != -1)
            } else {
                return false
            }
        } catch (e) {
            return false
        }

    }
}
export default CheckContract