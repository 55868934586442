import React, {useEffect, useState} from "react";
import {Tabs} from "antd";
import ContractsListData from "./ContractsListData/ContractsListData";
import ContractNewsList from "./ContractsList/List/ContractNewsList";
import {useTranslation} from "react-i18next";
import {CheckCircleFilled} from "@ant-design/icons";
import {ContractData, ContractsOverviewData} from "../../../../model";
import TitleCard from "../../../../components/TitleCard";

interface Props {
	flag?: boolean | undefined;
	address: string | undefined;
	overviewData: ContractsOverviewData
  loading:boolean;
}

const ContractsCollection = (props: Props) => {
	const { flag, overviewData,address,loading } = props;
	const { t } = useTranslation();
	const [contractData, setContractData] = useState({} as ContractData);

	useEffect(() => {
		getContractData();
	}, [overviewData.EthAddress]);
    const getContractData = async () => {
        // 查询到IPFS的地址
        if (overviewData.Ipfs) {
            // 编译成合约对象
            const contractData = (await (await fetch(overviewData.Ipfs)).json()) as ContractData;
            setContractData(contractData);
        } else {
            setContractData({} as ContractData);
            // Notify.warn(t("Contract.no_verify"), t("Contract.pls_verify"));
        }
    };

	function PaneContract() {
		return (
			<>
				<span style={{ marginRight: '5px' }}>{t("Contract.contract")}</span>
				{contractData && contractData.bytecode ? <CheckCircleFilled /> : null}
			</>
		);
	}

	return (
		// <Skeleton active loading={loading}>

		<TitleCard style={{ background: "#fff", padding: "10px" }}>
			<Tabs defaultActiveKey={flag ? "4" : "1"}>
				<Tabs.TabPane tab={t("list.Message_List")} key="1">
					<ContractNewsList MessageId={overviewData.MessageId} />
				</Tabs.TabPane>
				{/* TODO: 功能未实现，暂时先隐藏 */}
				{/* <Tabs.TabPane tab={t("Contract.inner_txn")} key="2">
					{" "}
					<ContractsTradingList />{" "}
				</Tabs.TabPane>
				<Tabs.TabPane tab={t("Contract.token_transform")} key="3">
					{" "}
					<Erc_20 />{" "}
				</Tabs.TabPane> */}
				<Tabs.TabPane tab={<PaneContract />} key="4">
					{" "}
					<ContractsListData contractData={contractData} address={address} ethAddress={overviewData.EthAddress} />
				</Tabs.TabPane>

				
			</Tabs>
		</TitleCard>
		// </Skeleton>
	);
};
export default ContractsCollection;
