import { Row } from "antd";
import { useNavigate } from "react-router-dom";

export const LogoPic = () => {
    const navigate = useNavigate();
    return (
        <>
            <Row justify={"center"}>
                <img
                    id="large"
                    className="logo-pic"
                    width={92}
                    height={32}
                    src={"/assets/s_imfil_icon.png"}
                    alt={"Logo"}
                    onClick={() => {
                        navigate("/");
                    }}
                />
                <img
                    id="small"
                    className="logo-pic-m"
                    width={36}
                    src={"/assets/white_icon.svg"}
                    alt={"Logo"}
                    onClick={() => {
                        navigate("/");
                    }}
                />
            </Row>
        </>
    )
}