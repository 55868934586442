/**
 * 周雨芹 23.2.20
 */
import {useEffect, useState} from "react";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {Button, Card, Col, Collapse, Form, Input, message, Row, Select, Space, Spin} from "antd";
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-javascript";
import "ace-builds/src-noconflict/theme-github";
import "ace-builds/src-noconflict/theme-nord_dark";
import "ace-builds/src-noconflict/ext-language_tools";
import {fetchCompile, saveContractData} from "../../../../servers/api";
import CheckContract from "../../../../utils/checkContract";
import ContractMethods from "../../../../utils/contract/ methods";
import {useAccount} from "wagmi";
import {ContractData} from "../../../../model";
import {request} from "../../../../utils/request";
import {Notify} from "../../../../components/Notify";
import {useTranslation} from "react-i18next";
import {ConnectWeb3Button} from "../../../../components/Navbar/Menu/ConnectWeb3Button";
import TitleCard from "../../../../components/TitleCard";

const { Option } = Select;
const { Panel } = Collapse;

interface compileParmas {
	address: string;
	agreement: string;
	versions: string;
}

interface VersionList {
	label: string;
	value: string;
}

export default function ContractVerification() {
	const navigate = useNavigate();
	const [form] = Form.useForm();
	const { address } = useAccount(); // 连接的钱包地址
	const { t } = useTranslation();
	// 多合约
	const [codeNum, setCodeNum] = useState<number[]>([0]);
	// 合约代码
	const [contractCode, setContractCode] = useState("");
	// 合约地址
	const { EthAddress } = useParams();
	const { state } = useLocation();
	// 版本列表
	const [versionList, setVersionList] = useState<VersionList[]>([]);
	// 验证中loading
	const [isSpinning, setIsSinning] = useState(false);
	const [iClassName, setIClassName] = useState(true);

	useEffect(() => {
		getversionList();
	}, [codeNum]);

	const submitForm = () => {
		form.submit();
	};

	// 请求版本列表
	const getversionList = async () => {
		const list = await request("https://binaries.soliditylang.org/bin/list.json", "GET");
		if (list.data) {
			const builds = list.data.releases;
			const newBuilds: string[] = Object.values(builds);
			const versionList = newBuilds.map((_value, _index) => {
				const value = _value.slice(8, -3);
				return { label: value, value: value };
			});
			setVersionList(versionList);
		}
	};

	// 编译对象数据
	const onFinish = (_value: compileParmas) => {
		// 验证合约代码
		if (!contractCode) {
			message.error(t("Contract.pls_input_code"), 3);
			return
		}
		startVerify(_value.address, _value.versions);
	};

	// 添加合约
	const addCode = (arg: boolean) => {
		if (arg) {
			let random = Math.random();
			setCodeNum([...codeNum, random]);
			console.log(codeNum);
		} else {
			message.warn(t("Contract.not_supported"), 3);
		}
	};

	// 获取合约代码
	const editorChange = (_newValue: any) => {
		setContractCode(_newValue);
	};

	/**
	 * 开始校验合约
	 * @param contractAddress 合约地址
	 * @param version 合约版本
	 */
	async function startVerify(contractAddress: string, version: string) {
		setIsSinning(true);
		// 编译源码  =》 bytecode
		let req = await fetchCompile(contractCode, version);
    console.log(req);
		if (req && req.output.bytecode && (await CheckContract.checkByteCode(contractAddress, req.output.bytecode))) {
			Notify.success(t("Contract.verify_success"), `${t("Contract.uploading")}IPFS...`);
			// 上传到ipfs
			const data = {
				name: "", // 合约名称
				address: contractAddress, // 合约地址
				deployer: address, // 消息发布人
				abi: req.output.abi, // 合约abi
				bytecode: req.output.bytecode, // 合约bytecode
				code: contractCode, // 合约代码
			} as ContractData;
			let IPFS = await ContractMethods.uploadDataToIpfs(data);
      console.log(IPFS);
			if (IPFS) {
				Notify.success(t("Contract.uploaded"), `IPFS URL ${IPFS}`);
				let req = await saveContractData(contractAddress, IPFS);
        console.log(req);
				if (req.ok) {
					Notify.success(t("Contract.registry"), t("Contract.in_registry"));
					setIsSinning(false);
					// 验证成功跳转到合约概览，查看合约详情
					navigate("/contract/" + state, {state: {flag: true}});
				} else {
					setIsSinning(false);
					Notify.error(t("Contract.added_failed"), t("Contract.record_failed"));
				}
			}
		} else {
			setIsSinning(false);
			Notify.error(t("Contract.added_failed"), t("Contract.verify_failed"));
		}
	}

	// 切换主题
	const toggleTheme = () => {

		setIClassName(!iClassName);
	};

	return (
		<div className="ContractVerification" id="ContractVerification">
			<Spin tip={t("Contract.verifying")} spinning={isSpinning} style={{ position: "fixed", top: 0, left: 0, right: 0, bottom: 0 }}>
				<TitleCard className="cont-title" title={
					<Row justify='space-between'>
						<Col>{t("Contract.overview")}</Col>
                        <ConnectWeb3Button />
					</Row>
				}>
					<Card title={t("Contract.select_compilation_object")} type="inner">
						<Form onFinish={onFinish} name="oneForm" form={form} labelCol={{ span: 4 }} wrapperCol={{ span: 10 }}>
							<Form.Item
								label={t("Contract.address")}
								name="address"
								labelAlign="right"
								rules={[
									{
										required: true,
										message: `${t("Contract.pls_input_code")}`,
									},
								]}
								initialValue={EthAddress}>
								<Input disabled={true} />
							</Form.Item>
							<Form.Item label={t("Contract.agreement")} name="agreement" initialValue="MIT">
								<Select>
									<Option value="MIT">MIT</Option>
									<Option value="无执照">{t("Contract.unlicensed")}</Option>
									<Option value="GNU通用公共许可证">{t("Contract.public_license")}</Option>
									<Option value="Apache 2.0(OSL-2.0)">Apache 2.0(OSL-2.0)</Option>
								</Select>
							</Form.Item>
							<Form.Item label="编译版本" name="versions" initialValue="v0.8.19+commit.7dd6d404">
								<Select options={versionList}></Select>
							</Form.Item>
						</Form>
					</Card>
					<Card
						title={t("Contract.add_source_code")}
						extra={
							<i
								onClick={toggleTheme}
								style={{ cursor: "pointer" }}
								className={iClassName ? "iconfont icon-baitianmoshimingliangmoshi" : "iconfont icon-yejian"}></i>
						}
						type="inner"
						style={{ marginTop: 16 }}>
						<Space direction="vertical" size="large" style={{ display: "flex" }}>
							<Collapse accordion collapsible="header" defaultActiveKey={0}>
								{codeNum.map((value, index) => {
									return (
										<Panel
											header={
												<>
													{t("Contract.file")}
													{index + 1}.sol
													<i className="iconfont icon-bianji" style={{ cursor: "pointer", paddingLeft: 10 }} />
												</>
											}
											key={value}>
											<AceEditor
												mode="javascript"
												theme={iClassName ? "nord_dark" : "github"}
												name="one"
												editorProps={{ $blockScrolling: true }}
												width="100%"
												maxLines={35}
												minLines={20}
												onChange={editorChange}
											/>
										</Panel>
									);
								})}
							</Collapse>
						</Space>
						{/* <Button
							onClick={() => {
								addCode(false);
							}}
							type="primary"
							style={{ marginTop: 20 }}>
							{t("Contract.add_other_files")}
						</Button> */}
					</Card>
					<div style={{ textAlign: "center" }}>
						<Button
							onClick={submitForm}
							type="primary"
							style={{
								background: "#1aaba8",
								textAlign: "center",
								margin: "20px",
							}}>
							{t("Contract.start_verify")}
						</Button>
						<Button
							onClick={() => {
								navigate(-1);
							}}
							type="ghost"
							style={{
								textAlign: "center",
								margin: "20px",
							}}>
							{t("Contract.cancle_verify")}
						</Button>
					</div>
				</TitleCard>
			</Spin>
		</div>
	);
}
