/** @format */

import {notification} from "antd";

type NotifyType = "success" | "warning" | "error" | "info";

interface NotifyCfgType {
    message?: string;
    description?: string;
}

const notifyConfig = (type: NotifyType, tips: NotifyCfgType) => {
    notification[type]({
        message: tips.message,
        description: tips.description,
        duration: 3,
        top: 84,
        maxCount: 1
    });
};


export const Notify = {
    error(title: string, message: string) {
        notifyConfig("error", {message: title, description: message});
    },
    success(title: string, message: string) {
        notifyConfig("success", {message: title, description: message});
    },warn(title: string, message: string) {
        notifyConfig("warning", {message: title, description: message});
    }
};
